// public/assets/flags/index.js

import AD from '../../assets/flags/32/ad_32.png';
import AE from '../../assets/flags/32/ae_32.png';
import AF from '../../assets/flags/32/af_32.png';
import AG from '../../assets/flags/32/ag_32.png';
import AI from '../../assets/flags/32/ai_32.png';
import AL from '../../assets/flags/32/al_32.png';
import AM from '../../assets/flags/32/am_32.png';
import AO from '../../assets/flags/32/ao_32.png';
import AQ from '../../assets/flags/32/aq_32.png';
import AR from '../../assets/flags/32/ar_32.png';
import AS from '../../assets/flags/32/as_32.png';
import AT from '../../assets/flags/32/at_32.png';
import AU from '../../assets/flags/32/au_32.png';
import AW from '../../assets/flags/32/aw_32.png';
import AX from '../../assets/flags/32/ax_32.png';
import AZ from '../../assets/flags/32/az_32.png';
import BA from '../../assets/flags/32/ba_32.png';
import BB from '../../assets/flags/32/bb_32.png';
import BD from '../../assets/flags/32/bd_32.png';
import BE from '../../assets/flags/32/be_32.png';
import BF from '../../assets/flags/32/bf_32.png';
import BG from '../../assets/flags/32/bg_32.png';
import BH from '../../assets/flags/32/bh_32.png';
import BI from '../../assets/flags/32/bi_32.png';
import BJ from '../../assets/flags/32/bj_32.png';
import BL from '../../assets/flags/32/bl_32.png';
import BM from '../../assets/flags/32/bm_32.png';
import BN from '../../assets/flags/32/bn_32.png';
import BO from '../../assets/flags/32/bo_32.png';
import BQ from '../../assets/flags/32/bq_32.png';
import BR from '../../assets/flags/32/br_32.png';
import BS from '../../assets/flags/32/bs_32.png';
import BT from '../../assets/flags/32/bt_32.png';
import BV from '../../assets/flags/32/bv_32.png';
import BW from '../../assets/flags/32/bw_32.png';
import BY from '../../assets/flags/32/by_32.png';
import BZ from '../../assets/flags/32/bz_32.png';
import CA from '../../assets/flags/32/ca_32.png';
import CC from '../../assets/flags/32/cc_32.png';
import CD from '../../assets/flags/32/cd_32.png';
import CF from '../../assets/flags/32/cf_32.png';
import CG from '../../assets/flags/32/cg_32.png';
import CH from '../../assets/flags/32/ch_32.png';
import CI from '../../assets/flags/32/ci_32.png';
import CK from '../../assets/flags/32/ck_32.png';
import CL from '../../assets/flags/32/cl_32.png';
import CM from '../../assets/flags/32/cm_32.png';
import CN from '../../assets/flags/32/cn_32.png';
import CO from '../../assets/flags/32/co_32.png';
import CR from '../../assets/flags/32/cr_32.png';
import CU from '../../assets/flags/32/cu_32.png';
import CV from '../../assets/flags/32/cv_32.png';
import CW from '../../assets/flags/32/cw_32.png';
import CX from '../../assets/flags/32/cx_32.png';
import CY from '../../assets/flags/32/cy_32.png';
import CZ from '../../assets/flags/32/cz_32.png';
import DE from '../../assets/flags/32/de_32.png';
import DJ from '../../assets/flags/32/dj_32.png';
import DK from '../../assets/flags/32/dk_32.png';
import DM from '../../assets/flags/32/dm_32.png';
import DO from '../../assets/flags/32/do_32.png';
import DZ from '../../assets/flags/32/dz_32.png';
import EC from '../../assets/flags/32/ec_32.png';
import EE from '../../assets/flags/32/ee_32.png';
import EG from '../../assets/flags/32/eg_32.png';
import EH from '../../assets/flags/32/eh_32.png';
import ER from '../../assets/flags/32/er_32.png';
import ES from '../../assets/flags/32/es_32.png';
import ET from '../../assets/flags/32/et_32.png';
import FI from '../../assets/flags/32/fi_32.png';
import FJ from '../../assets/flags/32/fj_32.png';
import FK from '../../assets/flags/32/fk_32.png';
import FM from '../../assets/flags/32/fm_32.png';
import FO from '../../assets/flags/32/fo_32.png';
import FR from '../../assets/flags/32/fr_32.png';
import GA from '../../assets/flags/32/ga_32.png';
import GB from '../../assets/flags/32/gb_32.png';
import GD from '../../assets/flags/32/gd_32.png';
import GE from '../../assets/flags/32/ge_32.png';
import GF from '../../assets/flags/32/gf_32.png';
import GG from '../../assets/flags/32/gg_32.png';
import GH from '../../assets/flags/32/gh_32.png';
import GI from '../../assets/flags/32/gi_32.png';
import GL from '../../assets/flags/32/gl_32.png';
import GM from '../../assets/flags/32/gm_32.png';
import GN from '../../assets/flags/32/gn_32.png';
import GP from '../../assets/flags/32/gp_32.png';
import GQ from '../../assets/flags/32/gq_32.png';
import GR from '../../assets/flags/32/gr_32.png';
import GS from '../../assets/flags/32/gs_32.png';
import GT from '../../assets/flags/32/gt_32.png';
import GU from '../../assets/flags/32/gu_32.png';
import GW from '../../assets/flags/32/gw_32.png';
import GY from '../../assets/flags/32/gy_32.png';
import HK from '../../assets/flags/32/hk_32.png';
import HM from '../../assets/flags/32/hm_32.png';
import HN from '../../assets/flags/32/hn_32.png';
import HR from '../../assets/flags/32/hr_32.png';
import HT from '../../assets/flags/32/ht_32.png';
import HU from '../../assets/flags/32/hu_32.png';
import ID from '../../assets/flags/32/id_32.png';
import IE from '../../assets/flags/32/ie_32.png';
import IL from '../../assets/flags/32/il_32.png';
import IM from '../../assets/flags/32/im_32.png';
import IN from '../../assets/flags/32/in_32.png';
import IO from '../../assets/flags/32/io_32.png';
import IQ from '../../assets/flags/32/iq_32.png';
import IR from '../../assets/flags/32/ir_32.png';
import IS from '../../assets/flags/32/is_32.png';
import IT from '../../assets/flags/32/it_32.png';
import JE from '../../assets/flags/32/je_32.png';
import JM from '../../assets/flags/32/jm_32.png';
import JO from '../../assets/flags/32/jo_32.png';
import JP from '../../assets/flags/32/jp_32.png';
import KE from '../../assets/flags/32/ke_32.png';
import KG from '../../assets/flags/32/kg_32.png';
import KH from '../../assets/flags/32/kh_32.png';
import KI from '../../assets/flags/32/ki_32.png';
import KM from '../../assets/flags/32/km_32.png';
import KN from '../../assets/flags/32/kn_32.png';
import KP from '../../assets/flags/32/kp_32.png';
import KR from '../../assets/flags/32/kr_32.png';
import KW from '../../assets/flags/32/kw_32.png';
import KY from '../../assets/flags/32/ky_32.png';
import KZ from '../../assets/flags/32/kz_32.png';
import LA from '../../assets/flags/32/la_32.png';
import LB from '../../assets/flags/32/lb_32.png';
import LC from '../../assets/flags/32/lc_32.png';
import LI from '../../assets/flags/32/li_32.png';
import LK from '../../assets/flags/32/lk_32.png';
import LR from '../../assets/flags/32/lr_32.png';
import LS from '../../assets/flags/32/ls_32.png';
import LT from '../../assets/flags/32/lt_32.png';
import LU from '../../assets/flags/32/lu_32.png';
import LV from '../../assets/flags/32/lv_32.png';
import LY from '../../assets/flags/32/ly_32.png';
import MA from '../../assets/flags/32/ma_32.png';
import MC from '../../assets/flags/32/mc_32.png';
import MD from '../../assets/flags/32/md_32.png';
import ME from '../../assets/flags/32/me_32.png';
import MF from '../../assets/flags/32/mf_32.png';
import MG from '../../assets/flags/32/mg_32.png';
import MH from '../../assets/flags/32/mh_32.png';
import MK from '../../assets/flags/32/mk_32.png';
import ML from '../../assets/flags/32/ml_32.png';
import MM from '../../assets/flags/32/mm_32.png';
import MN from '../../assets/flags/32/mn_32.png';
import MO from '../../assets/flags/32/mo_32.png';
import MP from '../../assets/flags/32/mp_32.png';
import MQ from '../../assets/flags/32/mq_32.png';
import MR from '../../assets/flags/32/mr_32.png';
import MS from '../../assets/flags/32/ms_32.png';
import MT from '../../assets/flags/32/mt_32.png';
import MU from '../../assets/flags/32/mu_32.png';
import MV from '../../assets/flags/32/mv_32.png';
import MW from '../../assets/flags/32/mw_32.png';
import MX from '../../assets/flags/32/mx_32.png';
import MY from '../../assets/flags/32/my_32.png';
import MZ from '../../assets/flags/32/mz_32.png';
import NA from '../../assets/flags/32/na_32.png';
import NC from '../../assets/flags/32/nc_32.png';
import NE from '../../assets/flags/32/ne_32.png';
import NF from '../../assets/flags/32/nf_32.png';
import NG from '../../assets/flags/32/ng_32.png';
import NI from '../../assets/flags/32/ni_32.png';
import NL from '../../assets/flags/32/nl_32.png';
import NO from '../../assets/flags/32/no_32.png';
import NP from '../../assets/flags/32/np_32.png';
import NR from '../../assets/flags/32/nr_32.png';
import NU from '../../assets/flags/32/nu_32.png';
import NZ from '../../assets/flags/32/nz_32.png';
import OM from '../../assets/flags/32/om_32.png';
import PA from '../../assets/flags/32/pa_32.png';
import PE from '../../assets/flags/32/pe_32.png';
import PF from '../../assets/flags/32/pf_32.png';
import PG from '../../assets/flags/32/pg_32.png';
import PH from '../../assets/flags/32/ph_32.png';
import PK from '../../assets/flags/32/pk_32.png';
import PL from '../../assets/flags/32/pl_32.png';
import PM from '../../assets/flags/32/pm_32.png';
import PN from '../../assets/flags/32/pn_32.png';
import PR from '../../assets/flags/32/pr_32.png';
import PS from '../../assets/flags/32/ps_32.png';
import PT from '../../assets/flags/32/pt_32.png';
import PW from '../../assets/flags/32/pw_32.png';
import PY from '../../assets/flags/32/py_32.png';
import QA from '../../assets/flags/32/qa_32.png';
import RE from '../../assets/flags/32/re_32.png';
import RO from '../../assets/flags/32/ro_32.png';
import RS from '../../assets/flags/32/rs_32.png';
import RU from '../../assets/flags/32/ru_32.png';
import RW from '../../assets/flags/32/rw_32.png';
import SA from '../../assets/flags/32/sa_32.png';
import SB from '../../assets/flags/32/sb_32.png';
import SC from '../../assets/flags/32/sc_32.png';
import SD from '../../assets/flags/32/sd_32.png';
import SE from '../../assets/flags/32/se_32.png';
import SG from '../../assets/flags/32/sg_32.png';
import SH from '../../assets/flags/32/sh_32.png';
import SI from '../../assets/flags/32/si_32.png';
import SJ from '../../assets/flags/32/sj_32.png';
import SK from '../../assets/flags/32/sk_32.png';
import SL from '../../assets/flags/32/sl_32.png';
import SM from '../../assets/flags/32/sm_32.png';
import SN from '../../assets/flags/32/sn_32.png';
import SO from '../../assets/flags/32/so_32.png';
import SR from '../../assets/flags/32/sr_32.png';
import SS from '../../assets/flags/32/ss_32.png';
import ST from '../../assets/flags/32/st_32.png';
import SV from '../../assets/flags/32/sv_32.png';
import SX from '../../assets/flags/32/sx_32.png';
import SY from '../../assets/flags/32/sy_32.png';
import SZ from '../../assets/flags/32/sz_32.png';
import TC from '../../assets/flags/32/tc_32.png';
import TD from '../../assets/flags/32/td_32.png';
import TF from '../../assets/flags/32/tf_32.png';
import TG from '../../assets/flags/32/tg_32.png';
import TH from '../../assets/flags/32/th_32.png';
import TJ from '../../assets/flags/32/tj_32.png';
import TK from '../../assets/flags/32/tk_32.png';
import TL from '../../assets/flags/32/tl_32.png';
import TM from '../../assets/flags/32/tm_32.png';
import TN from '../../assets/flags/32/tn_32.png';
import TO from '../../assets/flags/32/to_32.png';
import TR from '../../assets/flags/32/tr_32.png';
import TT from '../../assets/flags/32/tt_32.png';
import TV from '../../assets/flags/32/tv_32.png';
import TW from '../../assets/flags/32/tw_32.png';
import TZ from '../../assets/flags/32/tz_32.png';
import UA from '../../assets/flags/32/ua_32.png';
import UG from '../../assets/flags/32/ug_32.png';
import UM from '../../assets/flags/32/um_32.png';
import US from '../../assets/flags/32/us_32.png';
import UY from '../../assets/flags/32/uy_32.png';
import UZ from '../../assets/flags/32/uz_32.png';
import VA from '../../assets/flags/32/va_32.png';
import VC from '../../assets/flags/32/vc_32.png';
import VE from '../../assets/flags/32/ve_32.png';
import VG from '../../assets/flags/32/vg_32.png';
import VI from '../../assets/flags/32/vi_32.png';
import VN from '../../assets/flags/32/vn_32.png';
import VU from '../../assets/flags/32/vu_32.png';
import WF from '../../assets/flags/32/wf_32.png';
import WS from '../../assets/flags/32/ws_32.png';
import YE from '../../assets/flags/32/ye_32.png';
import YT from '../../assets/flags/32/yt_32.png';
import ZA from '../../assets/flags/32/za_32.png';
import ZM from '../../assets/flags/32/zm_32.png';
import ZW from '../../assets/flags/32/zw_32.png';

export default {
    AD,
    AE,
    AF,
    AG,
    AI,
    AL,
    AM,
    AO,
    AQ,
    AR,
    AS,
    AT,
    AU,
    AW,
    AX,
    AZ,
    BA,
    BB,
    BD,
    BE,
    BF,
    BG,
    BH,
    BI,
    BJ,
    BL,
    BM,
    BN,
    BO,
    BQ,
    BR,
    BS,
    BT,
    BV,
    BW,
    BY,
    BZ,
    CA,
    CC,
    CD,
    CF,
    CG,
    CH,
    CI,
    CK,
    CL,
    CM,
    CN,
    CO,
    CR,
    CU,
    CV,
    CW,
    CX,
    CY,
    CZ,
    DE,
    DJ,
    DK,
    DM,
    DO,
    DZ,
    EC,
    EE,
    EG,
    EH,
    ER,
    ES,
    ET,
    FI,
    FJ,
    FK,
    FM,
    FO,
    FR,
    GA,
    GB,
    GD,
    GE,
    GF,
    GG,
    GH,
    GI,
    GL,
    GM,
    GN,
    GP,
    GQ,
    GR,
    GS,
    GT,
    GU,
    GW,
    GY,
    HK,
    HM,
    HN,
    HR,
    HT,
    HU,
    ID,
    IE,
    IL,
    IM,
    IN,
    IO,
    IQ,
    IR,
    IS,
    IT,
    JE,
    JM,
    JO,
    JP,
    KE,
    KG,
    KH,
    KI,
    KM,
    KN,
    KP,
    KR,
    KW,
    KY,
    KZ,
    LA,
    LB,
    LC,
    LI,
    LK,
    LR,
    LS,
    LT,
    LU,
    LV,
    LY,
    MA,
    MC,
    MD,
    ME,
    MF,
    MG,
    MH,
    MK,
    ML,
    MM,
    MN,
    MO,
    MP,
    MQ,
    MR,
    MS,
    MT,
    MU,
    MV,
    MW,
    MX,
    MY,
    MZ,
    NA,
    NC,
    NE,
    NF,
    NG,
    NI,
    NL,
    NO,
    NP,
    NR,
    NU,
    NZ,
    OM,
    PA,
    PE,
    PF,
    PG,
    PH,
    PK,
    PL,
    PM,
    PN,
    PR,
    PS,
    PT,
    PW,
    PY,
    QA,
    RE,
    RO,
    RS,
    RU,
    RW,
    SA,
    SB,
    SC,
    SD,
    SE,
    SG,
    SH,
    SI,
    SJ,
    SK,
    SL,
    SM,
    SN,
    SO,
    SR,
    SS,
    ST,
    SV,
    SX,
    SY,
    SZ,
    TC,
    TD,
    TF,
    TG,
    TH,
    TJ,
    TK,
    TL,
    TM,
    TN,
    TO,
    TR,
    TT,
    TV,
    TW,
    TZ,
    UA,
    UG,
    UM,
    US,
    UY,
    UZ,
    VA,
    VC,
    VE,
    VG,
    VI,
    VN,
    VU,
    WF,
    WS,
    YE,
    YT,
    ZA,
    ZM,
    ZW,
};
