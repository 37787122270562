/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, Component } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { Row, Col, Button } from "react-bootstrap";
import "./index.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import LinkIcon from "../../../assets/images/icons/link-icon.svg";
import { Link, Navigate } from "react-router-dom";
import FilterIcon from "../../../assets/images/icons/filter-icon.svg";
import PauseIcon from "../../../assets/images/icons/pause.svg";
import ExportIcon from "../../../assets/images/icons/export.svg";
import TagIcon from "../../../assets/images/icons/tag-icon.svg";
import MoreActionDot from "../../../assets/images/icons/more-action-icon.svg";
import GlobIcon from "../../../assets/images/icons/global-icon.svg";
import DownArrow from "../../../assets/images/icons/profile-down-arrow.svg";
import MoveIcon from "../../../assets/images/icons/sort-move-icon.svg";
import NotificationIcon from "../../../assets/images/icons/tag-del-notification.png";
import TrashIcon from "../../../assets/images/icons/trash-icon.svg";
import ColumnFilter from "../../../components/Filters/cloumn-filter";
import ConditionFilter from "../../../components/Filters/condition-filter";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import AdTypeSelectPopup from "../creation/components/AdTypeSelectPopup/AdTypeSelectPopup";
import { id } from "date-fns/locale";
import LogoIcon from "../../../assets/brand/logo-icon.svg";
type YourStateType = {
  asinList: never[]; // Replace with the actual type of your state
};

class AdsCreations extends Component {
  filterData: any = [];
  state = {
    manageTags: [{ tag: "", initialTag: "", count: "" }],
    globalFilterFromData: [],
    isLoading: false,
    searchKeyFilter: "",
    globalFilterDateRange: [],
    filterOptionData: [],
    paginationList: [],
    perPage: 10,
    total: 0,
    currPage: 1,
    pageSize: 10,
    lastPage: 0,
    nextPage: 0,
    prevPage: 0,
    dropdownDatas: [],
    getSelectedRules: [],
    pageReload: false,
    getRulesByStatus: "",
    redirectLocation: "",
    redirect: "",
    redirectId: "",
    searchText: "",
    advancedFilters: [],
    listData: [],
    tagText: "",
    tagasin: "",
    lastUpdatedDate: localStorage.getItem("lastUpdatedDate") || null,
    autoCampaign: false,
    manualCampaign: false,
    keywordTargeting: false,
    productTargeting: false,
    asinList: [],
    SetTagsAdd: "",
    redirectState: {
      product: false,
      keyword: false,
      autoCampaign: false,
      manual: false,
    },
  };

  // abc
  addTagValue = (e) => {
    this.setState({ tagText: e.target.value });
  };

  getTagAsignValue = (e) => {
    this.setState({ tagasin: e.target.value });
    console.log(`getTagAsignValue ${this.state.tagasin}`);
  };

  componentDidMount() {
    this.getAdsCreationListData();
    this.manageHandler();
  }
  handleChange = (event, value) => {
    this.setState({ currPage: value }, () => {
      this.getAdsCreationListData();
    });
  };

  handleNvEnter = (event) => {
    console.log("Nv Enter:", event);
  };
  handleCallback = (childData) => {};
  handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const searchText = event.target.value;
      this.setState({ searchText }, () => {
        this.getAdsCreationListData();
        console.log(searchText);
      });
    }
  };
  updateRulesStatus = (statusType) => {
    if (this.state.getSelectedRules.length > 0) {
      this.setState({ pageReload: false });
      console.log("Enable Rules list: ", this.state.getSelectedRules);
    }
    let ele = document.getElementById("createAdsModelPopup");
    console.log(ele);
    if (ele) {
      ele.classList.add("open");
    }
  };
  addTagsHandler = () => {
    let ele = document.getElementById("addTags");
    console.log(ele);

    if (ele) {
      ele.classList.add("open");
    }
  };
  cancelTag = () => {
    let ele = document.getElementById("addTags");

    console.log(ele);
    if (ele) {
      ele.classList.remove("open");
    }
  };
  deleteRule = async () => {};
  applyDataLength = async (e) => {
    const newPerPage = parseInt(e.target.value);

    this.setState({ pageSize: newPerPage }, async () => {
      console.log("Updated perPage:", this.state.pageSize);
      console.log("Now calling API...");
      await this.getAdsCreationListData();
    });
  };

  campaignEditClick = async (data: any) => {
    console.log(data.asin);

    const url = "/ads/ads-creation/auto-campaign/" + data.id;
    this.setState({ redirect: url });
  };
  redirectPage = () => {
    const selectedPlan: any = document.querySelectorAll(
      ".create-ads-modal-radio"
    );
    // if (selectedPlan.length > 0) {
    //   const clearEle: any = document.querySelectorAll(
    //     ".create-ads-modal-radio.selected"
    //   );
    //   for (var i = 0; i < clearEle.length; i++) {
    //     clearEle[i].classList.remove("selected");
    //   }
    //   for (let i = 0; i < selectedPlan.length; i++) {
    //     if (selectedPlan[i].contains(eve.target)) {
    //       selectedPlan[i].classList.add("selected");
    //     }
    //   }
    // }
    // if (
    //   (0 < this.state.asinList.length && panelName === "auto") ||
    //   (0 < this.state.asinList.length && panelName === "manual")
    // ) {
    //   const url = "/ads/ads-creation/blukOperation";
    //   this.setState({ redirectLocation: url });
    // } else {
    // const asin = this.state.asinList;
    // if (true) {
    //   const url = `/ads/ads-creation/manual-campaign/${
    //     1 < this.state.asinList.length
    //       ? encodeURIComponent(asin.join(","))
    //       : this.state.redirectId
    //   }/keyword&productTargeting`;
    //   this.setState({ redirectLocation: url });
    // } else if (false) {
    //   const url = `/ads/ads-creation/auto-campaign/${
    //     1 < this.state.asinList.length
    //       ? encodeURIComponent(asin.join(","))
    //       : this.state.redirectId
    //   }`;
    //   this.setState({ redirectLocation: url });
    // }
    // }
  };
  postTagHandler = async () => {
    this.setState({ tagDataLoading: true });
    if (this.state.asinList.length > 0 && this.state.SetTagsAdd) {
      let userToken = localStorage.getItem("userToken");
      let AuthToken = "Bearer " + userToken;
      const response = await fetch(
        "https://api.aimosa.io/BookShelf/Bulkoperation",
        //"https://api.aimosa.io/BookShelf/" + clickedId + "/Tag",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify({
            asin: this.state.asinList,
            tags: [this.state.SetTagsAdd],
            targetAcos: null,
            addAdExpenses: null,
          }),
        }
      );
      const responceData = await response;
      console.log("best    =s    pathh ", responceData);
      if (responceData.status) {
        toast("Record Updated Successfully");
        this.getAdsCreationListData();
        this.setState({ SetTagsAdd: "" });
        this.setState({ apiLoading: false });
      } else {
        toast("Unable to Updated");
      }
      this.setState({ DataLoading: false });
    }
  };
  getAdsCreationListData = async () => {
    this.setState({ isLoading: true });
    console.log(
      "Current pageSize in getAdsCreationListData:",
      this.state.pageSize
    );

    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.aimosa.io/AdsManager/All";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
        body: JSON.stringify({
          pageNumber: this.state.currPage,
          pageSize: this.state.pageSize,
          globalFilters: {
            searchText: this.state.searchText,
            advancedFilters: [],
          },
        }),
      });

      const responceData = await response.json();
      console.log(responceData);
      if (responceData.success) {
        const { data, total, lastPage, nextPage } = responceData.result;

        this.setState({
          listData: data,
          total: total,
          lastPage: lastPage,
          nextPage: nextPage,
        });

        console.log(data);
      } else {
        console.error("API request failed:", responceData.error);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    this.setState({ isLoading: false });
  };

  addDataTags = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.aimosa.io/BookShelf/Bulkoperation";
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        asin: [this.state.tagasin],
        tags: [this.state.tagText],
        targetAcos: 0,
        addAdExpenses: 0,
      }),
    });
    try {
      const responseData = await response.json();
      this.getAdsCreationListData();
      this.manageHandler();
      this.setState({ tagText: "" });
      toast("Tag added successfully");
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  //
  deleteDataTags = async (asin, tags) => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.aimosa.io/BookShelf/Tag";
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        asin: asin,
        tag: tags,
      }),
    });
    try {
      const responseData = await response.json();
      this.getAdsCreationListData();
      if (responseData.success) {
        toast("Tag Deleted successfully");
      } else {
        toast(responseData.message);
      }
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };

  //
  openCreateAdsModelPopup = (id, eve) => {
    // this.setState({
    //   AdTypeSelectModel: {
    //     id: id,
    //     autoCampaign: false,
    //     manualCampaign: false,
    //     keywordTargeting: false,
    //     productTargeting: false,
    //     isModalOpen: true,
    //   },
    // });
    console.log("id", id);
    this.setState({ redirectId: id });
    let ele = document.getElementById("createAdsModelPopup");
    if (ele) {
      ele.classList.add("open");
    }
  };

  getRedirectUrl = (model) => {
    if (model.autoCampaign) {
      if (model.productTargeting) {
        return `/ads/ads-creation/auto-manual-campaign/${model.id}/productTargeting`;
      } else if (model.keywordTargeting) {
        return `/ads/ads-creation/auto-manual-campaign/${model.id}/keywordTargeting`;
      } else {
        return `/ads/ads-creation/auto-manual-campaign/${model.id}`;
      }
    } else if (model.manualCampaign) {
      if (model.productTargeting) {
        return `/ads/ads-creation/manual-campaign/${model.id}/productTargeting`;
      } else if (model.keywordTargeting) {
        return `/ads/ads-creation/manual-campaign/${model.id}/keywordTargeting`;
      } else {
        return `/ads/ads-creation/manual-campaign/${model.id}`;
      }
    }
    return `/ads/ads-creation/auto-manual-campaign/${model.id}`;
  };

  createAd = (model) => {
    const url = this.getRedirectUrl(model);
    window.location.href = url;
  };
  handleModelRedirectClick = () => {
    let ele = document.getElementById("createAdsModelPopup");
    if (ele) {
      ele.classList.remove("open");
    }
    console.log(this.state.redirectState);
    const asin = this.state.asinList;
    let url = "";
    switch (true) {
      case this.state.autoCampaign && this.state.manualCampaign:
        url += `/ads/ads-creation/auto-manual-campaign/${
          1 < this.state.asinList.length
            ? encodeURIComponent(asin.join(","))
            : this.state.redirectId
        }/keyword&productTargeting`;
        break;
      case this.state.autoCampaign && this.state.keywordTargeting:
        url += `/ads/ads-creation/auto-manual-campaign/${
          1 < this.state.asinList.length
            ? encodeURIComponent(asin.join(","))
            : this.state.redirectId
        }/keywordTargeting`;
        break;
      case this.state.autoCampaign && this.state.productTargeting:
        url += `/ads/ads-creation/auto-manual-campaign/${
          1 < this.state.asinList.length
            ? encodeURIComponent(asin.join(","))
            : this.state.redirectId
        }/productTargeting`;
        break;

      case this.state.autoCampaign:
        url += `/ads/ads-creation/auto-campaign/${
          1 < this.state.asinList.length
            ? encodeURIComponent(asin.join(","))
            : this.state.redirectId
        }`;
        break;
      case this.state.manualCampaign:
        url = `/ads/ads-creation/manual-campaign/${
          1 < this.state.asinList.length
            ? encodeURIComponent(asin.join(","))
            : this.state.redirectId
        }/keyword&productTargeting`;
        break;
      case this.state.keywordTargeting:
        url += `/ads/ads-creation/manual-campaign/${
          1 < this.state.asinList.length
            ? encodeURIComponent(asin.join(","))
            : this.state.redirectId
        }/keywordTargeting`;
        break;
      case this.state.productTargeting:
        url += `/ads/ads-creation/manual-campaign/${
          1 < this.state.asinList.length
            ? encodeURIComponent(asin.join(","))
            : this.state.redirectId
        }/productTargeting`;
        break;
    }
    this.setState({ redirect: url });
  };

  closeModalPopUp = () => {
    let ele = document.getElementById("createAdsModelPopup");
    if (ele) {
      ele.classList.remove("open");
    }
  };

  removeClick(tag, id) {
    // confirmAlert({
    //   title: "Confirm to Delete",
    //   message: "Are you sure to do this.",
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => this.deleteDataTags(tag, id),
    //     },
    //     {
    //       label: "No",
    //     },
    //   ],
    // });
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className="custom-ui">
          <div className="logo-del-tag">
            <img
              src={NotificationIcon}
              alt="Notification Icon"
              style={{ width: "80px", height: "80px" }}
            />
          </div>
          <h1>Are you sure you want to delete "{id}" tag?</h1>
          <p>If you delete this, you will no longer be able to restore it.</p>
          <div className="buttons-container">
            <button onClick={onClose}>Cancel</button>
            <button
              onClick={() => {
                this.deleteDataTags(tag, id);
                onClose();
              }}
            >
              Delete
            </button>
          </div>
        </div>
      ),
    });
  }
  bulkRemoveClick(tag: any) {
    // confirmAlert({
    //   title: "Confirm to Delete",
    //   message: "Are you sure to do this.",
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => this.deleteDataTags(tag, id),
    //     },
    //     {
    //       label: "No",
    //     },
    //   ],
    // });
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className="custom-ui">
          <div className="logo-del-tag">
            <img
              src={NotificationIcon}
              alt="Notification Icon"
              style={{ width: "80px", height: "80px" }}
            />
          </div>
          <h1>Are you sure you want to delete "{tag}" tag?</h1>
          <p>If you delete this, you will no longer be able to restore it.</p>
          <div className="buttons-container">
            <button onClick={onClose}>Cancel</button>
            <button
              onClick={() => {
                this.remove(tag);
                onClose();
              }}
            >
              Delete
            </button>
          </div>
        </div>
      ),
    });
  }
  campaignAdsRegionCampaings = (props, asin) => {
    return props?.map((campaignDetails, i) => {
      const auto = campaignDetails.campaignStructure.auto;
      const manualKeyword = campaignDetails.campaignStructure.manualKeyword;
      const manualProduct = campaignDetails.campaignStructure.manualProduct;
      let EditUrl = "";
      switch (true) {
        case auto && manualKeyword && manualProduct:
          EditUrl += `/ads/ads-creation/auto-manual-campaign/${asin}/keyword&productTargeting/${campaignDetails.id}`;
          break;
        case auto && manualKeyword:
          EditUrl += `/ads/ads-creation/auto-manual-campaign/${asin}/keywordTargeting/${campaignDetails.id}`;
          break;
        case auto && manualProduct:
          EditUrl += `/ads/ads-creation/auto-manual-campaign/${asin}/productTargeting/${campaignDetails.id}`;
          break;
        case auto:
          EditUrl += `/ads/ads-creation/auto-campaign/${asin}/${campaignDetails.id}`;
          break;
        case manualKeyword && manualProduct:
          EditUrl = `/ads/ads-creation/manual-campaign/${asin}/keyword&productTargeting/${campaignDetails.id}`;
          break;
        case manualKeyword:
          EditUrl += `/ads/ads-creation/manual-campaign/${asin}/keywordTargeting/${campaignDetails.id}`;
          break;
        case manualProduct:
          EditUrl += `/ads/ads-creation/manual-campaign/${asin}/productTargeting/${campaignDetails.id}`;
          break;
      }

      // let EditUrl = "";
      // if (manualKeyword && manualProduct && auto) {
      //   EditUrl = `/ads/ads-creation/manual-campaign/${asin}/keyword&productTargeting/${campaignDetails.id}`;
      // } else if (manualProduct) {
      //   EditUrl = `/ads/ads-creation/manual-campaign/${asin}/productTargeting/${campaignDetails.id}`;
      // } else if (manualKeyword) {
      //   EditUrl = `/ads/ads-creation/manual-campaign/${asin}/keywordTargeting/${campaignDetails.id}`;
      // }
      // if (auto) {
      //   EditUrl = `/ads/ads-creation/auto-campaign/${asin}/${campaignDetails.id}`;
      // }
      return (
        <Col md={6} className="mb-3 create-campaing-form">
          <div className="accordion-item rules-list-item">
            <div className="accordion-header rules-list-item-summary">
              <div>
                <img src={MoveIcon} alt="" />
              </div>
              <div className="rule-title-name">
                {campaignDetails.campaignName.name}
              </div>
              <div
                className="down-arrow collapsed"
                data-bs-toggle="collapse"
                data-bs-target={"#collapse-" + campaignDetails.id}
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <img src={DownArrow} alt="" />
              </div>
            </div>
            <div
              className="rules-list-item-details accordion-collapse collapse"
              id={"collapse-" + campaignDetails.id}
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="edit-link">
                <Link to={EditUrl}>Edit</Link>
                {/* {campaignDetails.id} */}
                {/* <Link to="" onClick={(e)=>this.campaignEditClick(campaignDetails)}>Edit</Link> */}
              </div>
              <div className="collapse-data">
                <Row>
                  {campaignDetails.campaignName && (
                    <Col md={12}>
                      <h6>Campaign Name</h6>
                      <p className="overflow-ellipse">
                        {campaignDetails.campaignName.name}
                      </p>
                    </Col>
                  )}
                  {campaignDetails.adGroups && (
                    <Col md={12}>
                      <h6>Ad Group Name</h6>
                      <div className="added-groups-list">
                        {campaignDetails.adGroups?.map((adgroups, index) => (
                          <div>{adgroups.name}</div>
                        ))}
                      </div>
                    </Col>
                  )}
                  {campaignDetails.dynamicBidding && (
                    <Col md={4}>
                      <h6>Targeting Bid</h6>
                      <p>{campaignDetails.dynamicBidding.targetingBid}</p>
                    </Col>
                  )}
                  {campaignDetails.negativeKeywordTargeting !== null ? (
                    <>
                      <Col md={4}>
                        <h6>Close</h6>
                        <p>{campaignDetails.dynamicBidding.close}</p>
                      </Col>
                      <Col md={4}>
                        <h6>Loose</h6>
                        <p>{campaignDetails.dynamicBidding.loose}</p>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col md={4}>
                        <h6>Broad</h6>
                        <p>{campaignDetails.dynamicBidding.broad}</p>
                      </Col>
                      <Col md={4}>
                        <h6>Phrase</h6>
                        <p>{campaignDetails.dynamicBidding.phrase}</p>
                      </Col>
                    </>
                  )}

                  {campaignDetails.dynamicBidding && (
                    <Col md={4}>
                      <h6>Default Bid</h6>
                      <p>{campaignDetails.dynamicBidding.defaultBid}</p>
                    </Col>
                  )}
                  {campaignDetails.negativeKeywordTargeting !== null ? (
                    <>
                      <Col md={4}>
                        <h6>Substitutes</h6>
                        <p>{campaignDetails.dynamicBidding.substitutes}</p>
                      </Col>
                      <Col md={4}>
                        <h6>Complements</h6>
                        <p>{campaignDetails.dynamicBidding.complements}</p>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col md={4}>
                        <h6>Exact</h6>
                        <p>{campaignDetails.dynamicBidding.exact}</p>
                      </Col>
                      <Col md={4}></Col>
                    </>
                  )}

                  <Col md={4}>
                    <h6>Level</h6>
                    <p>
                      {campaignDetails.negativeKeywordTargeting !== null
                        ? campaignDetails.negativeKeywordTargeting.targetingType
                        : campaignDetails.targets?.map(
                            (item, index) => item.targetType
                          )}
                    </p>
                  </Col>
                  {/* {campaignDetails.negativeKeywordTargeting?.keywords?.length >
                  0 && (
                  <Col md={8}>
                    <h6>Negative Keyword Targeting</h6>
                    <p>
                      {
                        campaignDetails.negativeKeywordTargeting?.keywords[0]
                          .type
                      }
                    </p>
                  </Col>
                )} */}
                  <Col md={12}>
                    {campaignDetails.negativeKeywordTargeting !== null ? (
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Keyword</th>
                              <th>Match Type</th>
                              <th></th>
                            </tr>
                          </thead>
                          {campaignDetails.negativeKeywordTargeting?.keywords
                            .length > 0 && (
                            <tbody>
                              {campaignDetails.negativeKeywordTargeting?.keywords?.map(
                                (adKeyword: any, i) => (
                                  <tr>
                                    <td>{adKeyword.keyword}</td>
                                    <td>{adKeyword.type}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          )}
                        </table>{" "}
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Keyword</th>
                              <th>Match Type</th>
                              <th></th>
                            </tr>
                          </thead>
                          {campaignDetails.targets?.length > 0 && (
                            <tbody>
                              {campaignDetails.targets?.map(
                                (adKeyword: any, i) =>
                                  adKeyword.targetData?.map(
                                    (item: any, index) => (
                                      <tr>
                                        <td>
                                          {item.matchtype.toString() ===
                                          "category"
                                            ? item.name
                                            : item.targetText}
                                        </td>
                                        <td>{item.matchtype?.join()}</td>
                                      </tr>
                                    )
                                  )
                              )}
                            </tbody>
                          )}
                        </table>
                      </div>
                    )}
                  </Col>
                  <Col md={12}>
                    <h6>Campaign Bidding Strategy</h6>
                    <p>{campaignDetails.settings.campaignBiddingStrategy}</p>
                  </Col>
                  {campaignDetails.settings && (
                    <Col md={12}>
                      <h6>Portfolio</h6>
                      <p>{campaignDetails.settings.portfolio}</p>
                    </Col>
                  )}
                  {campaignDetails.settings && (
                    <Col md={12}>
                      <h6>Daily Budget</h6>
                      <p>{campaignDetails.settings.dailyBudget}</p>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </div>
        </Col>
      );
    });
  };
  handleRegionDisplay = (id, region, eve) => {
    const activeTab = ".campaign-region-tab-" + id;
    const activeCont = ".campaign-region-content-" + id;

    const clearTabEle: any = document.querySelectorAll(activeTab + ".active");
    for (var i = 0; i < clearTabEle.length; i++) {
      clearTabEle[i].classList.remove("active");
    }
    const clearContEle: any = document.querySelectorAll(activeCont + ".active");
    for (var j = 0; j < clearContEle.length; j++) {
      clearContEle[j].classList.remove("active");
    }

    const activeTabEle: any = document.querySelectorAll(
      activeTab + "." + region
    );
    for (let k = 0; k < activeTabEle.length; k++) {
      activeTabEle[k].classList.add("active");
    }

    const activeContEle: any = document.querySelectorAll(
      activeCont + "." + region
    );
    for (let l = 0; l < activeContEle.length; l++) {
      activeContEle[l].classList.add("active");
    }
  };

  duplicateRow = () => {};

  deleteRow = () => {};
  manageHandler = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    const response = await fetch("https://api.aimosa.io/Tag", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    });
    const responceData = await response.json();
    const initialTags = responceData.result?.map((tag) => ({
      tag: tag.tag,
      initialTag: tag.tag,
      count: tag.count,
    }));
    this.setState({ manageTags: initialTags });
    this.setState({ DataLoading: false });
  };

  remove = async (item: any) => {
    let ele = document.getElementById("addManageTags");
    if (ele) {
      ele.classList.remove("show");
    }

    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    const response = await fetch("https://api.aimosa.io/Tag", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        tag: item,
      }),
    });

    if (!response.ok) {
      toast("Unable to Delete");
    } else {
      toast("Record Deleted Successfully");
      this.manageHandler();
      this.getAdsCreationListData();
    }

    this.setState({ DataLoading: false });
  };

  handleEditTags = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    const requestBody = {
      item: this.state.manageTags?.map(({ initialTag, tag }) => ({
        oldTag: initialTag,
        tags: tag,
      })),
    };
    const response = await fetch("https://api.aimosa.io/Tag", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        item: requestBody.item,
      }),
    });

    if (!response.ok) {
      toast("Unable to Updated");
    } else {
      toast("Record Updated Successfully");
    }

    this.setState({ DataLoading: false });
  };
  handleCheckboxChange = (type: string, e: ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => {
      let newState: any = {
        ...prevState,
        [type]: !prevState[type],
      };
      return newState;
    });
    this.setState((prev) => {
      if (type === "keywordTargeting") {
        return {
          ...prev,
          keywordTargeting: !this.state.keywordTargeting,
        };
      } else if (type === "productTargeting") {
        return {
          ...prev,
          productTargeting: !this.state.productTargeting,
        };
      } else if (type === "manualCampaign") {
        return {
          ...prev,
          productTargeting: !this.state.productTargeting,
          keywordTargeting: !this.state.productTargeting,
        };
      }
    });
    // if (type === "keywordTargeting" || type === "productTargeting") {
    //   newState.manualCampaign =
    //     newState.keywordTargeting && newState.productTargeting;
    // }
  };
  // handleCheckboxChange = (type, e) => {
  //   this.setState((prevState) => ({
  //     [type]: !prevState[type],
  //   }));
  // this.setState((prevState: any) => ({
  //   ...prevState,
  //   redirectState: { ...prevState.redirectState, [e.target.name]: type },
  // }));
  // };

  handleSubmit = (data) => {};
  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} state={this.state.asinList} />;
    }
    const asinListHandler = (asin: any, id: any) => {
      this.setState((prevState: any) => {
        const updatedList = prevState.listData?.map((item: any) =>
          item.id === id ? { ...item, isChecked: !item.isChecked } : item
        );

        const updatedAsinList = updatedList
          .filter((item: any) => item.isChecked)
          ?.map((item: any) => item.asin);

        return {
          listData: updatedList,
          asinList: updatedAsinList,
        };
      });
    };
    console.log();

    const masterAsinHadler = (e) => {
      if (e.target.checked) {
        this.setState((prevState: any) => {
          const updatedList = prevState.listData?.map((item: any) => ({
            ...item,
            isChecked: true,
          }));
          const updatedAsinList = updatedList
            .filter((item: any) => item.isChecked)
            .map((item: any) => item.asin);

          return {
            listData: updatedList,
            asinList: updatedAsinList,
          };
        });
      } else {
        this.setState((prevState: any) => {
          const updatedList = prevState.listData?.map((item: any) => ({
            ...item,
            isChecked: false,
          }));
          const updatedAsinList = updatedList
            .filter((item: any) => item.isChecked)
            ?.map((item: any) => item.asin);

          return {
            listData: updatedList,
            asinList: updatedAsinList,
          };
        });
      }
      //   const checkAll = this.state.listData.map((item: any) => ({
      //     ...item,
      //     isChecked: true,
      //   }));
      //   this.setState({ asinList: checkAll });
      // } else {
      //   this.setState({ asinList: [] });
      // }
    };
    console.log(this.state.autoCampaign);
    console.log(this.state.manualCampaign);
    console.log(this.state.keywordTargeting);
    console.log(this.state.productTargeting);

    const manageTagsListEdit = this.state.manageTags?.map(
      (item: any, index) => {
        return (
          <div className="row-item-container">
            <div className="d-flex align-items-start">
              <input
                type="text"
                className="form-control mb-1"
                onChange={(e) => handleTagInputChange(e, index)}
                value={item.tag}
              />
              <button
                type="button"
                className="btn btn-box"
                onClick={() => this.bulkRemoveClick(item.tag)}
                // this.removeClick(list.asin, tags)
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>{" "}
            <p className="m-0 mb-3 p-0">this tag is {item.count}x Used</p>
          </div>
        );
      }
    );
    const handleTagInputChange = (e, index) => {
      const updatedTagsList = [...this.state.manageTags];
      const newValue = e.target.value;
      updatedTagsList[index].tag = newValue;
      this.setState({ manageTags: updatedTagsList });
    };
    const updateHideColumn = (hideArr) => {};
    return (
      <DashboardLayout>
        <div className="main-cont-header bookself-container">
          <Row className="page-header">
            <Col>
              <div className="main-con-page-title-container">
                <div className="title">
                  <h3 className="page-title">Ads</h3>
                </div>
              </div>
            </Col>
            {/* <Col className="text-end last-sync justify-content-end">
              <span
                  className="last-sync"
              >
                <div>Last App Sync</div>
                <div className="lastspan">
                    {" "}
                    {this.state.lastUpdatedDate || "Not available"}
                </div>
              </span>
            </Col> */}
          </Row>
        </div>
        <div className="main-content-container">
          <hr />
          <div className="dashboard-container padding-lr-20 sticky-container">
            <Row>
              <Col md={7}>
                <div className="table-global-action-container align-items-center">
                  <div className="mx-2">
                    <input
                      type="checkbox"
                      onChange={masterAsinHadler}
                      checked={this.state.listData.every(
                        (item: any) => item.isChecked
                      )}
                      className="form-check-input"
                    />
                  </div>
                  <div className="search-filter-container width-200 mx-2">
                    <i className="fa fa-search"></i>
                    <input
                      type="text"
                      placeholder="Search"
                      id="globalSearch"
                      name="globalSearch"
                      onKeyDown={this.handleKeyDown}
                    />
                  </div>
                  <div className="bulk-operation-container width-200 mx-2">
                    <div className="dropdownContent width-200">
                      <p
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Bulk Operation
                        <i
                          className="fa fa-angle-down down-arrow-right"
                          aria-hidden="true"
                        ></i>
                        <i
                          className="fa fa-angle-up up-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </p>
                      <ul
                        className="dropdown-menu shadow rounded"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li onClick={(e) => this.updateRulesStatus("Enabled")}>
                          Ads Creation
                        </li>
                        {/* <li onClick={(e) => this.updateRulesStatus("Paused")}>
                          Add Targets
                        </li> */}
                        {/* <button
                          className="changePassword btnBackgoundRemove disableInput"
                          data-bs-toggle="modal"
                          data-bs-target="#addTagModel1"
                        >
                          {" "}
                          Add Tags
                        </button> */}
                        <li onClick={(e) => this.addTagsHandler()}>Add Tags</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="filter-container">
                  <Row>
                    <Col md={12} className="padding-lr-10">
                      <div className="filter-item filter-link-container dropdownContent">
                        {/* <p
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                          data-bs-display="static"
                        >
                          <i>
                            <img src={FilterIcon} alt="filter icon" />
                          </i>
                          <span>Filter</span>
                          <i
                            className="fa fa-angle-down down-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </p> */}
                        <div
                          className="dropdown-menu dropdown-menu-lg-end"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <ConditionFilter
                            parentCallback={this.handleCallback}
                            dropdownData={this.state.dropdownDatas}
                            metaData={""}
                          />
                        </div>
                      </div>

                      <div className="filter-item column-link-container dropdownContent">
                        {/* <p
                          id="dropdownMenuButton2"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                          data-bs-display="static"
                        >
                          <i>
                            <img src={PauseIcon} alt="filter icon" />
                          </i>
                          <span>Columns</span>
                          <i
                            className="fa fa-angle-down down-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </p> */}
                        <div
                          className="dropdown-menu dropdown-menu-lg-start"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <form>
                            <div className="set-max-height-400">
                              <ColumnFilter
                                columnList={this.state.dropdownDatas}
                                hideColumn={updateHideColumn}
                              />
                            </div>
                            <hr />
                            <div className="footer">
                              <button type="button" className="btn btn-default">
                                Cancel
                              </button>
                              <button type="button" className="btn btn-primary">
                                Apply
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div
                        className="filter-item export-link-container"
                        style={{ display: "none" }}
                      >
                        <p>
                          <i>
                            <img src={ExportIcon} alt="filter icon" />
                          </i>
                          <span>Export</span>
                        </p>
                      </div>
                      <div
                        className="filter-item export-link-container last"
                        data-bs-target="#addManageTags"
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                      >
                        <p>
                          <i>
                            <img src={TagIcon} alt="filter icon" />
                          </i>
                          <span>Manage Tags</span>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          <hr className="mt-2" />
          {this.state.isLoading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ minHeight: "350px" }}
            >
              <div>
                <div className="loading-text">
                  <span className="logicon">
                    <img src={LogoIcon} alt="logo-small"></img>
                  </span>
                  <span>L</span>
                  <span>O</span>
                  <span>A</span>
                  <span>D</span>
                  <span>I</span>
                  <span>N</span>
                  <span>G</span>
                </div>
              </div>
            </div>
          ) : (
            <>
              {this.state.listData?.map((list: any, index) => (
                <div className="ads-container-row">
                  <div className="ads-creation-list-container padding-lr-30">
                    <Row>
                      <Col md={10}>
                        <div className="ads-creation-list-container-left">
                          <div className="ads-content-row  align-items-center">
                            <div>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={list.isChecked}
                                id="{list.asin}"
                                onChange={() =>
                                  asinListHandler(list.asin, list.id)
                                }
                              />
                            </div>
                            <div className="product-details width-295">
                              <div className="product-details-img">
                                <img src={list.bookCoverImage} alt="" />
                              </div>
                              <div className="product-details-cont">
                                <div className="product-title">
                                  {list.bookName}
                                </div>
                                <div className="product-subtitle">
                                  {list.asin}{" "}
                                  <div className="bullet-point">
                                    {list.author}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="bookself-product-tags">
                                <button
                                  className="add-tag"
                                  data-bs-target="#addTagModel"
                                  data-bs-toggle="modal"
                                  data-bs-dismiss="modal"
                                  onClick={(e) => this.getTagAsignValue(e)}
                                  value={list.asin}
                                >
                                  Add Tag
                                </button>
                                {list.tags?.map((tags, index) => (
                                  <span className="tags" key={index}>
                                    {tags}
                                    <button
                                      className="delete-tag-button"
                                      onClick={(e) =>
                                        this.removeClick(list.asin, tags)
                                      }
                                    >
                                      <i />
                                    </button>
                                  </span>
                                ))}
                                {/* console.log(`tags ++++ ${list.tags}`); */}
                                {/* <button
                              className="add-tag"
                              data-bs-target="#addTagModel"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                            >
                              Add Tag
                            </button>
                            {tags.map((tag, index) => (
                              <span key={index} className="tags">
                                {tag}
                                <button
                                  className="delete-tag-button"
                                  onClick={() => handleDeleteTag(tag)}
                                >
                                  Delete
                                </button>
                              </span>
                            ))} */}
                              </div>
                            </div>
                            <hr />
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="ads-creation-list-container-right text-end justify-content-end align-items-center">
                          <button
                            className="btn btn-primary add-rules-btn mt-1"
                            onClick={(eve) =>
                              this.openCreateAdsModelPopup(list.asin, eve)
                            }
                          >
                            Create Ads
                          </button>
                          <div className="three-dot">
                            <img src={MoreActionDot} alt="" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <hr className="mt-2 dotted" />
                  <div className="tab">
                    <ul>
                      {list.adsCampaigns
                        .filter(
                          (campaign) =>
                            campaign.marketPlace !== "A2EUQ1WTGCTBG2"
                        )
                        ?.map((campaign: any, i) => (
                          <li
                            key={i}
                            className={
                              "campaign-region-tab-" +
                              list.id +
                              " " +
                              campaign.marketPlace +
                              " " +
                              (i === 0 ? "active " : "")
                            }
                            onClick={(ele) =>
                              this.handleRegionDisplay(
                                list.id,
                                campaign.marketPlace,
                                ele
                              )
                            }
                          >
                            <span
                              className={
                                "rounted-icon flag-" + campaign.marketPlace
                              }
                            ></span>
                            {/* <i className={"flag-" + campaign.marketPlace} /> */}
                            {console.log("Marketplace:", campaign.marketPlace)}
                            {campaign.marketPlace}
                          </li>
                        ))}

                      {/* <li className="advertise-anywhere">
                    <img src={GlobIcon} alt="" /> Advertise Elsewhere
                  </li> */}
                    </ul>
                    <div className="ads-rules-list-container">
                      {list.adsCampaigns?.map((campaignRegions: any, i) => (
                        <div
                          // className={
                          //   "accordion campaign-region-content-" +
                          //   list.id +
                          //   " " +
                          //   campaignRegions.marketPlace +
                          //   " " +
                          //   (i === 0 ? "active" : "")
                          // }
                          className={
                            "accordion campaign-region-content-" +
                            list.id +
                            " " +
                            campaignRegions.marketPlace +
                            " " +
                            (i === 0 ? "active" : "")
                          }
                        >
                          <Row>
                            {this.campaignAdsRegionCampaings(
                              campaignRegions.campaigns,
                              list.asin
                            )}
                          </Row>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          <div className="custom-table-footer mx-3">
            <Row>
              <Col md={5}>
                <form className="table-footer-left">
                  <span>Show </span>
                  <label>
                    <select
                      className="form-select"
                      defaultValue={this.state.perPage}
                      onChange={(event) => this.applyDataLength(event)}
                    >
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                    </select>
                  </label>
                  <span> of {this.state.total} total entries</span>
                </form>
              </Col>
              <Col md={7}>
                <div className="table-footer-right">
                  <Stack spacing={2}>
                    <Pagination
                      count={this.state.lastPage}
                      variant="outlined"
                      shape="rounded"
                      defaultPage={this.state.currPage || 1}
                      onChange={this.handleChange}
                    />
                  </Stack>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div
          className="modal fade"
          id="addTagModel"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel3"
        >
          <div className="modal-dialog ">
            <div className="modal-content addTagModel">
              <Row className="addTagInputContainer">
                <Col className="addTagModelContainers">
                  <h4>Add Tag</h4>
                  <p>
                    You will add this tag to the product that you have selected.
                  </p>

                  {/* <input
                    type="text"
                    className="form-control"
                    placeholder="add tag here"
                    value={tagText}
                    onChange={(e) => (e.target.value)}                  
                    //onClick={ }
                  /> */}
                  <input
                    type="text"
                    className="form-control"
                    placeholder="add tag here"
                    value={this.state.tagText}
                    //value={this.state.tagText}
                    //placeholder="add tag here"
                    //value={tagText}
                    onChange={(e) => this.addTagValue(e)}
                  />

                  <div className="addTagBtnContainer">
                    <button
                      className="addTagCancell"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                    <button
                      className="addTagSave"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={this.addDataTags}
                    >
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {/* <AdTypeSelectPopup
          model={this.state.AdTypeSelectModel}
          onSubmit={this.createAd}
        ></AdTypeSelectPopup> */}
        <div
          className="modal createAdsModel"
          id="createAdsModelPopup"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel3"
        >
          <div className="modal-dialog">
            <div className="modal-content addTagModel">
              <Row className="addTagInputContainer">
                <Col className="addTagModelContainers">
                  <h4>
                    Create Ads
                    <span
                      className="close-modal"
                      aria-label="Close"
                      onClick={this.closeModalPopUp}
                    >
                      x
                    </span>
                  </h4>
                  <p>You can choose auto campaign or manual campaign.</p>
                  <div className="create-ads-modal-radio">
                    <label>
                      <input
                        type="checkbox"
                        name="autoCampaign"
                        className="filter-checkbox checkbox-adjust"
                        checked={this.state.autoCampaign}
                        onChange={(e) =>
                          this.handleCheckboxChange("autoCampaign", e)
                        }
                      />
                      Auto Campaign
                    </label>
                  </div>

                  <div className="create-ads-modal-radio1">
                    <label>
                      <input
                        name="manual"
                        type="checkbox"
                        className="filter-checkbox checkbox-adjust"
                        checked={
                          this.state.keywordTargeting ||
                          this.state.productTargeting
                        }
                        onChange={(e) =>
                          this.handleCheckboxChange("manualCampaign", e)
                        }
                      />
                      Manual Campaign
                    </label>

                    <div className="checkbox-container">
                      <label>
                        <input
                          type="checkbox"
                          name="keyword"
                          className="filter-checkbox checkbox-adjust"
                          checked={this.state.keywordTargeting}
                          onChange={(e) =>
                            this.handleCheckboxChange("keywordTargeting", e)
                          }
                        />
                        Keyword Targeting
                      </label>
                    </div>

                    <div className="checkbox-container">
                      <label>
                        <input
                          type="checkbox"
                          name="product"
                          className="filter-checkbox checkbox-adjust"
                          checked={this.state.productTargeting}
                          onChange={(e) =>
                            this.handleCheckboxChange("productTargeting", e)
                          }
                        />
                        Product Targeting
                      </label>
                    </div>
                  </div>

                  <div className="createCampaindBtnContainer">
                    <button
                      className="btn btn-primary width-100p"
                      onClick={() => {
                        this.handleModelRedirectClick();
                        this.redirectPage();
                      }}
                      style={{
                        borderRadius: "4px",
                        background: "#00D26E",
                        height: "34px",
                        padding: "8px 16px",
                        borderColor: "#00D26E",
                      }}
                    >
                      Create Ads
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div
          className="modal createAdsModel"
          id="addTags"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel3"
        >
          <div className="modal-dialog ">
            <div className="modal-content addTagModels">
              <Row className="addTagInputContainer">
                <Col className="addTagModelContainers">
                  <h4> Add Tag</h4>
                  <p>
                    You will add this tag to the {this.state.asinList.length}
                    &nbsp;product
                    {this.state.asinList.length > 1 ? <>s</> : <></>}
                    &nbsp;that you have selected.
                  </p>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Add tag here"
                    onChange={(e) =>
                      this.setState({ SetTagsAdd: e.target.value })
                    }
                    value={this.state.SetTagsAdd}
                  />

                  <div className="addTagBtnContainer">
                    <button
                      className="addTagCancell"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={this.cancelTag}
                    >
                      Cancel
                    </button>
                    <button
                      className="addTagSave"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        this.postTagHandler();
                        this.cancelTag();
                      }}
                    >
                      Save Changes
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div
          className="modal fade addTagModel4"
          id="addManageTags"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel3"
        >
          <div className="modal-dialog ">
            <div className="modal-content addTagModels">
              <Row className="addTagInputContainer">
                <Col className="addTagModelContainers">
                  <h4> Manage Tags</h4>
                  <p>
                    You will add this tag to the product that you have selected.
                  </p>
                  {manageTagsListEdit}

                  <div className="addTagBtnContainer">
                    <button
                      className="addTagCancell"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                    <button
                      className="addTagSave"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={this.handleEditTags}
                    >
                      Save Changes
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

export default AdsCreations;
