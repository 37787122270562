import React, { Component, useState, useEffect } from "react";
import { Button, Row, Col, Spinner } from "react-bootstrap";
import InfoButton from "../Common/InfoButton";
import MoreActionIcon from "../../assets/images/icons/more-action-icon.svg";
import DashboardService from "../../services/DashboardService";
import MasterDataService from "../../services/MasterDataService";
import Chart from "react-apexcharts";
import DynamicWidthDropdown from "../Common/DynamicWidthDropdown/DynamicWidthDropdown";
import NumberFormatter from "../Common/NumberFormatter";
import "./HeatMapChart.css";
interface HeatMapChartProps {
  defaultCategory: string;
  defaultMetric: string;
  canSwitch: boolean;
  defaultChange: (string, string) => void;
  filters: any; // Replace with the actual type for your filters
}

const HeatMapChart: React.FC<HeatMapChartProps> = ({
  defaultCategory,
  defaultMetric,
  canSwitch,
  filters,
  defaultChange,
}) => {
  const [loading, setLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [treeMapPercentage, setTreeMapPercentage] = useState(5);
  const [treeMapChartData, setTreeMapChartData] = useState([]);
  const [childCategory, setChildCategory] = useState("");

  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [contextMenuItem, setContextMenuItem] = useState({});

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenuPosition({ x: event.pageX, y: event.pageY });
    setContextMenuVisible(true);
  };
  function handleDataPointClick(event, chartContext, config) {
    // Access the clicked category and other information from the 'config' object
    const clickedCategory =
      config.w.config.series[config.seriesIndex].data[config.dataPointIndex].x;
    setChildCategory(clickedCategory);
    // Handle the click event, e.g., log the clicked category to the console
    console.log("Clicked category:", clickedCategory);
  }

  function handleChildDataPointClick(event, chartContext, config) {
    // Access the clicked category and other information from the 'config' object
    //const data = config.w.config.series[config.seriesIndex].data[config.dataPointIndex].data;
    //event.preventDefault();
    //setContextMenuItem(data);
    //setContextMenuPosition({ x: event.pageX, y: event.pageY });
    //setContextMenuVisible(true);
    setChildCategory("");
  }
  function percentageIncreement() {
    setTreeMapPercentage(treeMapPercentage + 5);
  }
  function percentageDecreement() {
    if (treeMapPercentage > 5) {
      setTreeMapPercentage(treeMapPercentage - 5);
    }
  }
  function getListItemByKey(listItem, name) {
    // Check if listItem is an array
    if (Array.isArray(listItem)) {
      // Use find only if listItem is an array
      return listItem.find((item) => item.name === name) ?? null;
    } else {
      // Return null if listItem is not an array
      return null;
    }
  }
  function getChartOptions(categories, chartType) {
    return {
      legend: {
        show: false,
      },
      chart: {
        type: chartType,
        height: 350,
        events: {
          dataPointSelection: childCategory
            ? handleChildDataPointClick
            : handleDataPointClick,
        },
      },
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
        },
        heatmap: {
          distributed: true,
          enableShades: false,
          shadeIntensity: 0.5,
          radius: 0,
          useFillColorAsStroke: true,
          colorScale: {
            ranges: [
              {
                from: -30,
                to: 5,
                name: "low",
                color: "#00A100",
              },
              {
                from: 6,
                to: 20,
                name: "medium",
                color: "#128FD9",
              },
              {
                from: 21,
                to: 45,
                name: "high",
                color: "#FFB200",
              },
              {
                from: 46,
                to: 55,
                name: "extreme",
                color: "#FF0000",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      grid: {
        padding: {
          right: 20,
        },
      },
      xaxis: {
        categories: categories,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const clickedItem = w.config.series[seriesIndex].data[dataPointIndex];
          const data = w.config.series[seriesIndex].data[dataPointIndex].data;
          //return '<div class="arrow_box">' +
          //    '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
          //    '</div>'
          if (childCategory) {
            return `<div class="custom-tooltip">
                                    <strong>Marketplace:</strong> ${data.marketplace}<br>
                                    <strong>Campaign:</strong> ${data.campaign}<br>
                                    <strong>Author:</strong> ${data.author}<br>
                                    <strong>Book Title:</strong> ${data.bookTitle}<br>
                                    <strong>ASIN:</strong> ${data.asin}<br>
                                    <strong>Royalties Total Net:</strong> ${data.royaltiesTotalNet}<br>
                                    <strong>eBook Paid Total:</strong> ${data.eBookPaidTotal}<br>
                                    <strong>Page Read Total:</strong> ${data.pageReadTotal}<br>
                                    <strong>Print Total:</strong> ${data.printTotal}<br>
                                    <strong>Distribution:</strong> ${data.distribution}<br>
                                  </div>
                                `;
          }
          return `<div class="custom-tooltip">
                                    <strong>Category:</strong> ${
                                      data.category
                                    }<br>
                                    <strong>Number of books:</strong> ${
                                      data.data.length
                                    }<br>
                                    <strong>Total ${selectedMetric} Value:</strong> ${getTotalValue(
            data.heatMapData
          )}<br>

                                  </div>
                                `;
        },
      },
    };
  }

  const [selectedCategory, setSelectedCategory] = useState(
    defaultCategory || "Marketplace"
  );
  const [selectedMetric, setSelectedMetric] = useState(defaultMetric);
  const [chartSeries, setChartSeries] = useState([
    { name: defaultMetric, data: [] },
  ]);
  const [selectedCategoryData, setSelectedCategoryData] = useState({});
  const [currentChartType, setCurrentChartType] = useState("treemap");
  const [selectedMetricData, setSelectedMetricData] = useState({
    name: "",
    unit: "",
    description: "",
    value: 0.0,
    average: 0.0,
    sum: 0.0,
    min: 0.0,
    max: 0.0,
    growthRate: 0.0,
    data: [],
  });
  const [chartOptions, setChartOptions] = useState(getChartOptions(["US"]));

  function setDropDowns(catList) {
    setCategoryList(catList);
    let categoryData = getListItemByKey(catList, selectedCategory);
    if (!categoryData) {
      categoryData = catList[0];
      setSelectedCategory(categoryData.name);
    }
    setSelectedCategoryData(categoryData);
    let metricData = getListItemByKey(categoryData.childValues, selectedMetric);
    if (!metricData) {
      metricData = categoryData.childValues[0];
      setSelectedMetric(metricData.name);
    }
    setSelectedMetricData(metricData);
  }

  function getHeatMapValue(heatMapData) {
    let value = 0.0;
    if (selectedMetric === "netRoyalties") {
      value = heatMapData.netRoyalties.growthRate;
    } else if (selectedMetric === "ROI") {
      value = heatMapData.roi.growthRate;
    } else if (selectedMetric === "pageRead") {
      value = heatMapData.pageRead.growthRate;
    } else if (selectedMetric === "unitSold") {
      value = heatMapData.unitSold.growthRate;
    } else {
      value = heatMapData.adSpend.growthRate;
    }
    return parseFloat(Number(value).toFixed(2));
  }
  function getTotalValue(heatMapData) {
    let value = 0.0;
    if (selectedMetric === "netRoyalties") {
      value = heatMapData.netRoyalties.current;
    } else if (selectedMetric === "ROI") {
      value = heatMapData.roi.current;
    } else if (selectedMetric === "pageRead") {
      value = heatMapData.pageRead.current;
    } else if (selectedMetric === "unitSold") {
      value = heatMapData.unitSold.current;
    } else {
      value = heatMapData.adSpend.current;
    }
    return value;
  }
  function loadChart(data, category) {
    if (!data) return;
    if (category) {
      const childData = data.find((item) => item.category === category).data;

      const series = childData.map((item) => ({
        x: item.asin,
        //  y: (1 * 100 / childData.length).toFixed(2),
        y: 1 + getHeatMapValue(item.heatMapData),
        data: item,
      }));
      const labels = childData.map((item) => item.asin);
      setCurrentChartType("treemap");
      setChartSeries([{ name: selectedMetric, data: series }]);
      setChartOptions(getChartOptions(labels, "treemap"));
    } else {
      let totalBookCount = data
        .map((item) => item.data.length)
        .reduce((sum, length) => sum + length, 0);

      const series = data.map((item) => ({
        x: item.category,
        y: ((item.data.length * 100) / totalBookCount).toFixed(2),
        data: item,
      }));
      const labels = data.map((item) => item.category);
      setCurrentChartType("treemap");
      setChartSeries([{ name: selectedMetric, data: series }]);
      setChartOptions(getChartOptions(labels, "heatmap"));
    }
  }

  useEffect(() => {
    // Code to execute after childCategory has been updated
    if (treeMapChartData && treeMapChartData.length > 0) {
      loadChart(treeMapChartData, childCategory);
    }
  }, [childCategory]);

  useEffect(() => {
    const fetchMetaData = async () => {
      setLoading(true);
      try {
        const metaData = await MasterDataService.getMetaData();
        if (metaData.HeatMap) {
          setDropDowns(metaData.HeatMap);
        }
      } catch (error) {
        console.error("Error fetching metrics list:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMetaData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [
    selectedCategory,
    selectedMetric,
    treeMapPercentage,
    categoryList,
    filters,
  ]);

  const fetchData = async () => {
    setLoading(true);
    try {
      var request = {
        globalFilters: filters,
        categories: selectedCategory,
        metrics: selectedMetric,
        percentage: treeMapPercentage,
      };
      // TODO: need to revisit after the api changes
      const treeMapData = await DashboardService.getHeatMapData(request);

      if (treeMapData && treeMapData.length > 0) {
        setTreeMapChartData(treeMapData);
        setChildCategory("");
        loadChart(treeMapData, "");
      }
    } catch (error) {
      console.error("Error fetching metric data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (event) => {
    defaultChange(event.target.value, "HeatMap1");
    setSelectedCategory(event.target.value);
    setDropDowns(categoryList);
  };

  const handleMetricChange = (event) => {
    defaultChange(event.target.value, "HeatMap2");

    let data = event.target.value;
    setSelectedMetric(data);

    let metricData = getListItemByKey(
      selectedCategoryData.childValues,
      selectedMetric
    );
    if (!metricData) {
      metricData = selectedCategoryData.childValues[0];
      setSelectedMetric(metricData.name);
    }
    setSelectedMetricData(metricData);
  };

  return (
    <div className="HeatMapChart widget-container height-410px">
      <div className="widget-header">
        <Row>
          <Col md={7} className="widget-select-container dot-con">
            <div className="input-group filter d-flex align-items-center">
              <div
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  display: "inline-flex",
                }}
              >
                {!canSwitch && (
                  <>
                    {selectedMetricData && (
                      <>
                        <span
                          className="mx-2"
                          style={{
                            color: "#111827",
                            fontSize: "14px",
                            fontFamily: "Plus Jakarta Sans",
                            fontWeight: "600",
                            lineHeight: "21px",
                            wordWrap: "break-word",
                          }}
                        >
                          {selectedMetricData.title}
                        </span>
                        <span
                          style={{
                            color: "#9CA3AF",
                            fontSize: "14px",
                            fontFamily: "Plus Jakarta Sans",
                            fontWeight: "500",
                            lineHeight: "21px",
                            wordWrap: "break-word",
                          }}
                        >
                          {selectedMetricData.subTitle && (
                            <span>[{selectedMetricData.subTitle}]</span>
                          )}
                        </span>
                        <InfoButton infoText={selectedMetricData.description} />
                      </>
                    )}
                  </>
                )}
                {canSwitch &&
                  selectedCategoryData.childValues &&
                  selectedCategoryData.childValues.length > 0 && (
                    <DynamicWidthDropdown
                      options={selectedCategoryData.childValues}
                      selectedItem={selectedMetric}
                      handleSelectChange={handleMetricChange}
                    ></DynamicWidthDropdown>
                  )}
                <span className="mx-3">By</span>

                {!canSwitch && (
                  <>
                    {selectedCategoryData && (
                      <>
                        <span
                          className="mx-2"
                          style={{
                            color: "#111827",
                            fontSize: "14px",
                            fontFamily: "Plus Jakarta Sans",
                            fontWeight: "600",
                            lineHeight: "21px",
                            wordWrap: "break-word",
                          }}
                        >
                          {selectedCategoryData.title}
                        </span>
                        <span
                          style={{
                            color: "#9CA3AF",
                            fontSize: "14px",
                            fontFamily: "Plus Jakarta Sans",
                            fontWeight: "500",
                            lineHeight: "21px",
                            wordWrap: "break-word",
                          }}
                        >
                          {selectedCategoryData.subTitle && (
                            <span>[{selectedCategoryData.subTitle}]</span>
                          )}
                        </span>
                        <InfoButton
                          infoText={selectedCategoryData.description}
                        />
                      </>
                    )}
                  </>
                )}
                {canSwitch && categoryList && categoryList.length > 0 && (
                  <DynamicWidthDropdown
                    options={categoryList}
                    selectedItem={selectedCategory}
                    handleSelectChange={handleCategoryChange}
                  ></DynamicWidthDropdown>
                )}
              </div>
            </div>
          </Col>
          <Col md={5}>
            <Row>
              <Col md={10}></Col>
              <Col md={2}>
                <div className="more-action-icon">
                  <img src={MoreActionIcon} alt="info circle icon" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            {loading ? (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  height: "100%",
                  marginTop: "140px",
                }}
                className="horizontalBarChart"
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <br></br>
                <div className="loading ms-4">Loading...</div>
              </div>
            ) : chartSeries && chartSeries.length > 0 ? (
              <>
                <Chart
                  id="horizontal_bar_chart"
                  options={chartOptions}
                  series={chartSeries}
                  type={currentChartType}
                  width="100%"
                  height={350}
                />
                {contextMenuVisible && (
                  <div
                    className="context-menu"
                    style={{
                      position: "absolute",
                      left: contextMenuPosition.x,
                      top: contextMenuPosition.y,
                    }}
                  >
                    {childCategory ? (
                      <div class="custom-tooltip">
                        <strong>Marketplace:</strong> $
                        {contextMenuItem.marketplace}
                        <br />
                        <strong>Campaign:</strong> ${contextMenuItem.campaign}
                        <br />
                        <strong>Author:</strong> ${contextMenuItem.author}
                        <br />
                        <strong>Book Title:</strong> $
                        {contextMenuItem.bookTitle}
                        <br />
                        <strong>ASIN:</strong> ${contextMenuItem.asin}
                        <br />
                        <strong>Royalties Total Net:</strong> $
                        {contextMenuItem.royaltiesTotalNet}
                        <br />
                        <strong>eBook Paid Total:</strong> $
                        {contextMenuItem.eBookPaidTotal}
                        <br />
                        <strong>Page Read Total:</strong> $
                        {contextMenuItem.pageReadTotal}
                        <br />
                        <strong>Print Total:</strong> $
                        {contextMenuItem.printTotal}
                        <br />
                        <strong>Distribution:</strong> $
                        {contextMenuItem.distribution}
                        <br />
                      </div>
                    ) : (
                      <div class="custom-tooltip">
                        <strong>Category:</strong> ${contextMenuItem.category}
                        <br />
                        <strong>Number of books:</strong> $
                        {contextMenuItem.data?.length}
                        <br />
                        <strong>Total {selectedMetric} Value:</strong> $
                        {getTotalValue(contextMenuItem.heatMapData)}
                        <br />
                      </div>
                    )}
                  </div>
                )}
                <div className="Datafilert">
                  <Row>
                    <Col md={5}>
                      <Button className="Minus" onClick={percentageDecreement}>
                        -
                      </Button>
                      <Button className="Plus" onClick={percentageIncreement}>
                        +
                      </Button>
                      <Button className="Greater">
                        {">"}
                        {treeMapPercentage}%
                      </Button>
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  height: "100%",
                  marginTop: "140px",
                }}
              >
                No Data
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeatMapChart;
