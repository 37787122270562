import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { DateRangePicker } from "rsuite";
import LogoIcon from "../../../../../assets/brand/logo-icon.svg";
import { isAfter, addDays, format } from "date-fns";

function AddRulesFor(props) {
  const [ruleDataLoading, setRuleDataLoading] = useState(true);
  const [rulesMetaData, setRulesMetaData] = useState({});
  const [reportDurationDetails, setReportDurationDetails] = useState([]);
  const [forData, setForData] = useState("");
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [isUnable, setIsUnable] = useState(false);
  const dateRangePickerRef = useRef(null);
  const [showDateRangePicker, setShowDateRangePicker] = useState(true);

  useEffect(() => {
    if (forData.selectedValue === "Custom") {
      setIsPickerOpen(true);
    } else {
      setIsPickerOpen(false);
    }
  }, [forData.selectedValue]);

  useEffect(() => {
    if (props.rulesMetaData) {
      const { reportDurationDetails } = props.rulesMetaData;
      if (reportDurationDetails !== undefined) {
        setRulesMetaData(props.rulesMetaData);
        setReportDurationDetails(reportDurationDetails);
        setRuleDataLoading(false);
      }
    }
  }, [props.rulesMetaData, rulesMetaData]);

  useEffect(() => {
    if (props.wizardData.forWizard) {
      setForData(props.wizardData.forWizard);

      if (props.wizardData.forWizard === "Custom") {
        const today = new Date();
        const initialDateRange = [today, addDays(today, 6)];
        setDateRange(initialDateRange);
        // Do not set isPickerOpen to true here; it will be handled in handleChange
      } else {
        // setIsPickerOpen(false);
      }
    }
  }, [props.wizardData.forWizard]);

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    console.log(selectedValue);
    setForData(selectedValue);

    if (selectedValue === "Custom") {
      setIsPickerOpen(true);
    } else {
      setIsPickerOpen(false);
    }

    let startDate = "";
    let endDate = "";
    if (selectedValue === "Today" || selectedValue === "Start") {
      const today = new Date();
      startDate = format(today, "yyyy-MM-dd");
      endDate = startDate;
    } else if (selectedValue === "Today" || selectedValue === "Start") {
      const today = new Date();
      startDate = format(today, "yyyy-MM-dd");
      endDate = startDate;
    } else if (selectedValue === "Yesterday") {
      const yesterday = addDays(new Date(), -1);
      startDate = format(yesterday, "yyyy-MM-dd");
      endDate = startDate;
    } else if (selectedValue === "This week") {
      const today = new Date();
      const startOfWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - today.getDay()
      );
      const endOfWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + (6 - today.getDay())
      );
      startDate = format(startOfWeek, "yyyy-MM-dd");
      endDate = format(endOfWeek, "yyyy-MM-dd");
    } else if (selectedValue === "Last week") {
      endDate = format(new Date(), "yyyy-MM-dd");
      startDate = format(addDays(new Date(), -6), "yyyy-MM-dd");
    } else if (selectedValue === "This month") {
      const today = new Date();
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      startDate = format(startOfMonth, "yyyy-MM-dd");
      endDate = format(endOfMonth, "yyyy-MM-dd");
    } else if (selectedValue === "Last month") {
      endDate = format(new Date(), "yyyy-MM-dd");
      startDate = format(addDays(new Date(), -30), "yyyy-MM-dd");
    } else if (selectedValue === "Lifetime" || selectedValue === "Custom") {
      // Set your custom date range here
      const customStartDate = new Date(/* Set your custom start date here */);
      const customEndDate = new Date(/* Set your custom end date here */);
      // Update startDate and endDate with custom date range
      startDate = format(customStartDate, "yyyy-MM-dd");
      endDate = format(customEndDate, "yyyy-MM-dd");
    }

    setDateRange([new Date(startDate), new Date(endDate)]);

    let formData = {
      type: "reportDuration",
      reportDuration: {
        dateRange: selectedValue,
        from: "",
        to: "",
      },
    };

    props.parentCallback(formData);
    if (typeof props.wizardDataCallback === "function") {
      props.wizardDataCallback(formData);
    } else {
      console.error("wizardDataCallback is not a function");
    }
    setShowDateRangePicker(selectedValue !== "Lifetime");
  };

  const onChangeDateRangeSubmitGlobal = (value) => {
    if (value && Array.isArray(value)) {
      setDateRange(value);
      setIsPickerOpen(false);

      // Handle the date range change here
      console.log("Selected date range:", value);

      // Send the selected date range to forWizard
      const formattedDateRange = value.map((date) =>
        format(date, "yyyy-MM-dd")
      );
      const formData = {
        type: "reportDuration",
        reportDuration: {
          dateRange: "Custom",
          from: formattedDateRange[0],
          to: formattedDateRange[1],
        },
      };

      props.parentCallback(formData);
      props.wizardDataCallback(formData);
    }
  };

  return (
    <>
      <div className="wizard-step">
        <h5>For</h5>
        {!ruleDataLoading ? (
          <div className="card">
            <Row>
            <Col md={12} lg={4}>
                <form>
                  <div className="form-row">
                    <div className="form-field-group">
                      <div className="form-field width-40p">
                        <select
                          style={{
                            display: "flex",
                            width: "200px",
                            height: "40px",
                            padding: "8px 12px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "72px",
                            borderRadius: "4px",
                            border: "1px solid var(--AiGray-300, #D1D5DB)",
                          }}
                          className="form-select  focus-border-green"
                          name="reportDuration"
                          onChange={handleChange}
                          value={forData.dateRange}
                        >
                          <option value="Today">--Select--</option>
                          {reportDurationDetails &&
                            reportDurationDetails.map((list, i) => (
                              <option key={i} value={list}>
                                {list}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="loading-container">
            <div className="loading-text">
              <span className="logicon">
                <img src={LogoIcon} alt="logo-small"></img>
              </span>
              <span>L</span>
              <span>O</span>
              <span>A</span>
              <span>D</span>
              <span>I</span>
              <span>N</span>
              <span>G</span>
            </div>
          </div>
        )}
        <div className="datesincustom" value="">
        <style>
            {`
              .hide-toggle-value .rs-picker-toggle-value {
                display: none;
              }

              @media (max-width: 767px) {
                .hide-on-mobile {
                  position: absolute;
                  transform: translate(-110%, 150%); /* Adjust x and y as needed */
                  display: block; /* or any other desired display value */
              }
              }
              
            `}
          </style>


          {/* {showDateRangePicker && (
            <DateRangePicker
              ref={dateRangePickerRef}
              placeholder="Select a timeline"
              onChange={onChangeDateRangeSubmitGlobal}
              format="yyyy-MM-dd"
              value={dateRange}
              open={isPickerOpen}
              ranges={[]}
              onBlur={() => setIsPickerOpen(false)}
              className={`${
                forData.selectedValue === "Lifetime" ? "hide-toggle-value" : ""
              } hide-on-mobile`}
            />
          )} */}
        </div>
      </div>
    </>
  );
}

export default AddRulesFor;
