// /* eslint-disable jsx-a11y/anchor-is-valid */
// import React, { Component } from "react";
// import { Routes, Route, useParams } from "react-router-dom";
// import DashboardLayout from "../../../../layouts/DashboardLayout";
// import { Row, Col } from "react-bootstrap";
// import "../index.css";

// import LinkIcon from "../../../../assets/images/icons/link-icon.svg";
// import BackArrow from "../../../../assets/images/icons/back-arrow-icon.svg";
// import ThreeDots from "../../../../assets/images/icons/more-action-icon.svg";
// import DeleteIcon from "../../../../assets/images/icons/trash-icon.svg";

// import Moment from "moment";
// import { toast } from "react-toastify";
// import { Link } from "react-router-dom";

// class CreateManualCampaign extends Component {
//   state = {
//     portfolioData: [],
//     createCampaignFormData: {
//       asin: [],
//       campaignName: "",
//       adGroups: [],
//       targetingStrategy: "Auto Campaign",
//       dynamicBidding: {},
//       negativeKeywordTargeting: { targetingType: "", keywords: [] },
//       campaignBiddingStrategy: "",
//       settings: {},
//       targets: null,
//       postToAmazon: true,
//       marketPlaces: ["US"],
//     },
//     adGroupsTemp: "",
//     adGroups: [],
//     adKeywordType: "",
//     adKeywordsTemp: "",
//     adKeywords: [],
//     negativeKeywordTargeting: {},
//     matchTypeSelect: false,
//     matchTypeCheckedlist: [""],
//   };
//   componentDidMount() {
//     const queryParams = window.location.pathname;
//     let queryParamsArr = queryParams.split("/");
//     const asin = queryParamsArr[4];
//     console.log("createCamapaing Asin ID: ", asin);
//     let newCreateCampaignFormData: any = this.state.createCampaignFormData;
//     if (newCreateCampaignFormData.asin.length < 1) {
//       newCreateCampaignFormData.asin.push(asin);
//       this.setState({ newCreateCampaignFormData: newCreateCampaignFormData });
//     }
//     this.getPortfolioData();
//   }

//   handleChange = (name, e) => {
//     let newFormValues = this.state.createCampaignFormData;
//     let genKey = name.split(".");
//     if (genKey.length === 1) {
//       newFormValues[genKey[0]] = e.target.value;
//     }
//     if (genKey.length === 2) {
//       newFormValues[genKey[0]][genKey[1]] = e.target.value;
//     }
//     console.log("New Form Data: ", newFormValues);
//     this.setState({ createCampaignFormData: newFormValues });
//   };
//   handleChangeDecimal = (name, e) => {
//     let newFormValues = this.state.createCampaignFormData;
//     let genKey = name.split(".");
//     if (genKey.length === 1) {
//       newFormValues[genKey[0]] = parseFloat(e.target.value);
//     }
//     if (genKey.length === 2) {
//       newFormValues[genKey[0]][genKey[1]] = parseFloat(e.target.value);
//     }
//     console.log("New Form Data: ", newFormValues);
//     this.setState({ createCampaignFormData: newFormValues });
//   };
//   addTempAdgroup = (e) => {
//     this.setState({ adGroupsTemp: e.target.value });
//   };
//   adGroupsData = () => {
//     let newAdGroups: any = this.state.adGroups;
//     let val = { name: this.state.adGroupsTemp };
//     if (this.state.adGroupsTemp) {
//       newAdGroups.push(val);
//     }
//     this.setState({ adGroups: newAdGroups });
//     let newCreateCampaignFormData: any = this.state.createCampaignFormData;
//     newCreateCampaignFormData.adGroups = newAdGroups;
//     this.setState({ newCreateCampaignFormData: newCreateCampaignFormData });
//     this.setState({ adGroupsTemp: "" });
//   };
//   addKeyWordType = (e) => {
//     this.setState({ adKeywordType: e.target.value });
//   };
//   addTempAdKeywords = (e) => {
//     this.setState({ adKeywordsTemp: e.target.value });
//   };
//   removeFormFields = (i) => {
//     let newFormValues = this.state.adKeywords;
//     newFormValues.splice(i, 1);
//     this.setState({ adKeywords: newFormValues });
//     let newCreateCampaignFormData: any = this.state.createCampaignFormData;
//     newCreateCampaignFormData.negativeKeywordTargeting.keywords = newFormValues;
//     this.setState({ newCreateCampaignFormData: newCreateCampaignFormData });
//   };
//   adkeyWordSubmit = () => {
//     if (this.state.adKeywordsTemp) {
//       let adKeywordsListStr = this.state.adKeywordsTemp;
//       let newAdKeywords: any = this.state.adKeywords;
//       let adKeywordsListArr = adKeywordsListStr.split(",");
//       for (let i = 0; i < adKeywordsListArr.length; i++) {
//         let val: any = {
//           type: this.state.adKeywordType,
//           keyword: adKeywordsListArr[i],
//         };
//         if (this.state.adKeywordsTemp) {
//           newAdKeywords.push(val);
//         }
//         this.setState({ adGroups: newAdKeywords });
//       }
//       let newCreateCampaignFormData: any = this.state.createCampaignFormData;
//       newCreateCampaignFormData.negativeKeywordTargeting.keywords =
//         newAdKeywords;
//       this.setState({ newCreateCampaignFormData: newCreateCampaignFormData });
//       this.setState({ adKeywordsTemp: "" });
//     }
//   };
//   removeAllAdkeyWords = () => {
//     let newFormValues = this.setState({ adKeywords: [] });
//     let newCreateCampaignFormData: any = this.state.createCampaignFormData;
//     newCreateCampaignFormData.negativeKeywordTargeting.keywords = newFormValues;
//     this.setState({ newCreateCampaignFormData: newCreateCampaignFormData });
//   };
//   getPortfolioData = async () => {
//     let userToken = localStorage.getItem("userToken");
//     let AuthToken = "Bearer " + userToken;
//     let url = "https://api.aimosa.io/Campaign/Portfolios";
//     const response = await fetch(url, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: AuthToken,
//       },
//     });

//     try {
//       const responceData = await response.json();
//       this.setState({ portfolioData: responceData.result });
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   submitCreateCampaing = async () => {
//     let userToken = localStorage.getItem("userToken");
//     let AuthToken = "Bearer " + userToken;
//     let url = "https://api.aimosa.io/Campaign/Manual";
//     const requestOptions = {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: AuthToken,
//       },
//       body: JSON.stringify(this.state.createCampaignFormData),
//     };

//     try {
//       const response = await fetch(url, requestOptions);
//       const responceData = await response.json();
//       if (responceData.success) {
//         let result = responceData.result;
//         console.error(result);
//         toast("Successfully created");
//       } else {
//         toast("Unable to create");
//       }
//       // setRedirect("/onboarding");
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       toast("Error On Creating");
//     }
//   };
//   matchTypeSelectAllClick = (check) => {
//     this.setState({ matchTypeSelect: check });
//     let matchTypeChecklistArr = ["Product", "Broad", "Phrase", "Exact"];
//     if (check) {
//       this.setState({ matchTypeCheckedlist: matchTypeChecklistArr });
//     } else {
//       this.setState({ matchTypeCheckedlist: [] });
//     }
//   };
//   matchTypeCheckBoxClick = (e) => {
//     const ischecked = e.target.checked;
//     const newSelectedList = this.state.matchTypeCheckedlist;
//     if (ischecked === false) {
//       const index = newSelectedList.indexOf(e.target.value);
//       if (index > -1) {
//         newSelectedList.splice(index, 1);
//       }
//       this.setState({ matchTypeCheckedlist: newSelectedList });
//     } else {
//       const newArray = this.state.matchTypeCheckedlist;
//       newArray.push(e.target.value);
//       this.setState({ matchTypeCheckedlist: newArray });
//     }
//   };
//   matchTypeDelete = (name, e) => {
//     e.target.parentElement.firstChild.value = "";
//     let newFormValues = this.state.createCampaignFormData;
//     let genKey = name.split(".");
//     if (genKey.length === 1) {
//       newFormValues[genKey[0]] = "";
//     }
//     if (genKey.length === 2) {
//       newFormValues[genKey[0]][genKey[1]] = "";
//     }
//     console.log("Remove type Form Data: ", newFormValues);
//     this.setState({ createCampaignFormData: newFormValues });
//   };
//   render() {
//     return (
//       <DashboardLayout>
//         <div className="main-cont-header bookself-container">
//           <Row className="page-header">
//             <Col>
//               <div className="main-con-page-title-container">
//                 <div className="back-arrow-container">
//                   <Link to={"/ads/ads-creation"}>
//                     <i>
//                       <img src={BackArrow} alt="refresh icon" />
//                     </i>
//                     Back
//                   </Link>
//                 </div>
//               </div>
//             </Col>
//             <Col className="text-end last-sync">
//               <span>
//                 <i>
//                   <img src={LinkIcon} alt="refresh icon" />
//                 </i>
//                 Last App Sync
//               </span>
//               <span className="time-summery">Wed, Nov 2, 10:57</span>
//             </Col>
//           </Row>
//         </div>
//         <div className="main-content-container">
//           <hr />
//           <div className="create-campaign-container padding-lr-30">
//             <h5>Add Manual Campaign</h5>
//             <p>
//               Set dynamic campaign names. You can check the alternative
//               placeholders{" "}
//               <a>
//                 here.
//                 <div className="popup-custom-box">
//                   <p>Alternative Placeholders:</p>
//                   <ul>
//                     <li>&#10100;ASIN&#10101;</li>
//                     <li>&#10100;Layer&#10101;</li>
//                     <li>&#10100;Match Type&#10101;</li>
//                     <li>&#10100;Tag&#10101;</li>
//                     <li>&#10100;Targeting Type&#10101;</li>
//                   </ul>
//                 </div>
//               </a>
//             </p>
//           </div>
//           <div className="create-campaing-form padding-lr-30">
//             <form>
//               <div className="form-group col-md-8">
//                 <label>Campaign Name</label>
//                 <input
//                   className="form-control"
//                   name="createCampaignFormData.campaignName"
//                   placeholder="{Layer} | {Targeting Type} | {Match Type} | {ASIN} | Romance Book 1"
//                   onChange={(e) => this.handleChange("campaignName", e)}
//                   defaultValue={this.state.createCampaignFormData.campaignName}
//                 />
//               </div>
//               <div className="form-group col-md-8">
//                 <label>Ad Group Name</label>
//                 <input
//                   className="form-control"
//                   name=""
//                   placeholder="insert ad group name here"
//                   onChange={(e) => this.addTempAdgroup(e)}
//                   value={this.state.adGroupsTemp}
//                 />
//                 <div className="added-groups-list">
//                   {this.state.adGroups.map((adGroup: any, i) => (
//                     <div>
//                       {adGroup.name}
//                       <img src={ThreeDots} alt="" />
//                     </div>
//                   ))}
//                 </div>
//                 <div>
//                   <button
//                     type="button"
//                     className="btn btn-outline mt-2"
//                     onClick={(e) => this.adGroupsData()}
//                   >
//                     Add Another Ad Group
//                   </button>
//                 </div>
//               </div>
//               <div>
//                 <Row>
//                   <Col md={3}>
//                     <div className="border-dashed-right">
//                       <div className="form-group">
//                         <label>Targeting Bid</label>
//                         <input
//                           className="form-control"
//                           name=""
//                           placeholder="0.00"
//                           onChange={(e) =>
//                             this.handleChangeDecimal(
//                               "dynamicBidding.targetingBid",
//                               e
//                             )
//                           }
//                         />
//                       </div>
//                       <div className="form-group">
//                         <label>Default Bid</label>
//                         <input
//                           className="form-control"
//                           name=""
//                           placeholder="0.00"
//                           onChange={(e) =>
//                             this.handleChangeDecimal(
//                               "dynamicBidding.defaultBid",
//                               e
//                             )
//                           }
//                         />
//                       </div>
//                     </div>
//                   </Col>
//                   <Col md={5}>
//                     <Row>
//                       <Col md={12} className="mb-2">
//                         <div className="select-deselect-container">
//                           <label>Match Types</label>
//                           <div className="select-deselect-all">
//                             {!this.state.matchTypeSelect ? (
//                               <span
//                                 onClick={() =>
//                                   this.matchTypeSelectAllClick(true)
//                                 }
//                               >
//                                 Select All
//                               </span>
//                             ) : (
//                               <span
//                                 onClick={() =>
//                                   this.matchTypeSelectAllClick(false)
//                                 }
//                               >
//                                 Deselect All
//                               </span>
//                             )}
//                           </div>
//                         </div>
//                       </Col>
//                       <Col md={6}>
//                         <div>
//                           <div className="form-check">
//                             <input
//                               className="form-check-input"
//                               type="checkbox"
//                               value="Product"
//                               id="flexCheckIndeterminate1"
//                               checked={this.state.matchTypeCheckedlist.includes(
//                                 "Product"
//                               )}
//                               onChange={this.matchTypeCheckBoxClick}
//                             />
//                             <label
//                               className="form-check-label"
//                               htmlFor="flexCheckIndeterminate1"
//                             >
//                               Product
//                             </label>
//                           </div>
//                           <div className="input-group mb-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               aria-label="Recipient's username"
//                               aria-describedby="basic-addon2"
//                               placeholder="0.00"
//                               onChange={(e) =>
//                                 this.handleChangeDecimal(
//                                   "dynamicBidding.product",
//                                   e
//                                 )
//                               }
//                             />
//                             <span
//                               className="input-group-text input-btn-close"
//                               id="basic-addon2"
//                               onClick={(e) =>
//                                 this.matchTypeDelete(
//                                   "dynamicBidding.product",
//                                   e
//                                 )
//                               }
//                             >
//                               x
//                             </span>
//                           </div>
//                         </div>
//                       </Col>
//                       <Col md={6}>
//                         <div>
//                           <div className="form-check">
//                             <input
//                               className="form-check-input"
//                               type="checkbox"
//                               value="Broad"
//                               id="flexCheckIndeterminate2"
//                               checked={this.state.matchTypeCheckedlist.includes(
//                                 "Broad"
//                               )}
//                               onChange={this.matchTypeCheckBoxClick}
//                             />
//                             <label
//                               className="form-check-label"
//                               htmlFor="flexCheckIndeterminate2"
//                             >
//                               Broad
//                             </label>
//                           </div>
//                           <div className="input-group mb-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               aria-label="Recipient's username"
//                               aria-describedby="basic-addon2"
//                               placeholder="0.00"
//                               onChange={(e) =>
//                                 this.handleChangeDecimal(
//                                   "dynamicBidding.broad",
//                                   e
//                                 )
//                               }
//                             />
//                             <span
//                               className="input-group-text input-btn-close"
//                               id="basic-addon2"
//                               onClick={(e) =>
//                                 this.matchTypeDelete("dynamicBidding.broad", e)
//                               }
//                             >
//                               x
//                             </span>
//                           </div>
//                         </div>
//                       </Col>
//                       <Col md={6}>
//                         <div>
//                           <div className="form-check">
//                             <input
//                               className="form-check-input"
//                               type="checkbox"
//                               value="Phrase"
//                               id="flexCheckIndeterminate3"
//                               checked={this.state.matchTypeCheckedlist.includes(
//                                 "Phrase"
//                               )}
//                               onChange={this.matchTypeCheckBoxClick}
//                             />
//                             <label
//                               className="form-check-label"
//                               htmlFor="flexCheckIndeterminate3"
//                             >
//                               Phrase
//                             </label>
//                           </div>
//                           <div className="input-group mb-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               aria-label="Recipient's username"
//                               aria-describedby="basic-addon2"
//                               placeholder="0.00"
//                               onChange={(e) =>
//                                 this.handleChangeDecimal(
//                                   "dynamicBidding.phrase",
//                                   e
//                                 )
//                               }
//                             />
//                             <span
//                               className="input-group-text input-btn-close"
//                               id="basic-addon2"
//                               onClick={(e) =>
//                                 this.matchTypeDelete("dynamicBidding.phrase", e)
//                               }
//                             >
//                               x
//                             </span>
//                           </div>
//                         </div>
//                       </Col>
//                       <Col md={6}>
//                         <div>
//                           <div className="form-check">
//                             <input
//                               className="form-check-input"
//                               type="checkbox"
//                               value="Exact"
//                               id="flexCheckIndeterminate4"
//                               checked={this.state.matchTypeCheckedlist.includes(
//                                 "Exact"
//                               )}
//                               onChange={this.matchTypeCheckBoxClick}
//                             />
//                             <label
//                               className="form-check-label"
//                               htmlFor="flexCheckIndeterminate4"
//                             >
//                               Exact
//                             </label>
//                           </div>
//                           <div className="input-group mb-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               aria-label="Recipient's username"
//                               aria-describedby="basic-addon2"
//                               placeholder="0.00"
//                               onChange={(e) =>
//                                 this.handleChangeDecimal(
//                                   "dynamicBidding.exact",
//                                   e
//                                 )
//                               }
//                             />
//                             <span
//                               className="input-group-text input-btn-close"
//                               id="basic-addon2"
//                               onClick={(e) =>
//                                 this.matchTypeDelete("dynamicBidding.exact", e)
//                               }
//                             >
//                               x
//                             </span>
//                           </div>
//                         </div>
//                       </Col>
//                     </Row>
//                   </Col>
//                 </Row>
//               </div>
//               <div className="mt-3">
//                 <Row>
//                   <Col md={4}>
//                     <div>
//                       <label>Set Level</label>
//                     </div>
//                     <div className="form-check form-check-inline">
//                       <input
//                         className="form-check-input"
//                         type="radio"
//                         name="level"
//                         id="inlineRadio1"
//                         value="Campaign level"
//                         onChange={(e) =>
//                           this.handleChange(
//                             "negativeKeywordTargeting.targetingType",
//                             e
//                           )
//                         }
//                       />
//                       <label
//                         className="form-check-label"
//                         htmlFor="inlineRadio1"
//                       >
//                         Campaign level
//                       </label>
//                     </div>
//                     <div className="form-check form-check-inline">
//                       <input
//                         className="form-check-input"
//                         type="radio"
//                         name="level"
//                         id="inlineRadio2"
//                         value="Ad group level"
//                         onChange={(e) =>
//                           this.handleChange(
//                             "negativeKeywordTargeting.targetingType",
//                             e
//                           )
//                         }
//                       />
//                       <label
//                         className="form-check-label"
//                         htmlFor="inlineRadio2"
//                       >
//                         Ad group level
//                       </label>
//                     </div>
//                   </Col>
//                   <Col md={4}>
//                     <div>
//                       <label>Negative Keyword Targeting</label>
//                     </div>
//                     <div className="form-check form-check-inline">
//                       <input
//                         className="form-check-input"
//                         type="radio"
//                         name="negativeKeyword"
//                         id="inlineRadio3"
//                         value="Negative exact"
//                         onChange={(e) => this.addKeyWordType(e)}
//                       />
//                       <label
//                         className="form-check-label"
//                         htmlFor="inlineRadio3"
//                       >
//                         Negative exact
//                       </label>
//                     </div>
//                     <div className="form-check form-check-inline">
//                       <input
//                         className="form-check-input"
//                         type="radio"
//                         name="negativeKeyword"
//                         id="inlineRadio4"
//                         value="Negative phrase"
//                         onChange={(e) => this.addKeyWordType(e)}
//                       />
//                       <label
//                         className="form-check-label"
//                         htmlFor="inlineRadio4"
//                       >
//                         Negative phrase
//                       </label>
//                     </div>
//                   </Col>
//                   <Col md={8}>
//                     <div className="add-keyword-container">
//                       <textarea
//                         name=""
//                         id="addKeywordsText"
//                         className="form-control mb-3"
//                         onChange={(e) => this.addTempAdKeywords(e)}
//                         value={this.state.adKeywordsTemp}
//                       ></textarea>
//                       <button
//                         type="button"
//                         className="btn btn-outline mb-2 add-keyword-btn"
//                         onClick={(e) => this.adkeyWordSubmit()}
//                         disabled={!this.state.adKeywordType}
//                       >
//                         Add Keyword
//                       </button>
//                     </div>
//                   </Col>
//                   <Col md={8}>
//                     <div className="table-responsive">
//                       <table className="table">
//                         <thead>
//                           <tr>
//                             <th>Keyword</th>
//                             <th>Match Type</th>
//                             <th></th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {this.state.adKeywords.map((adKeyword: any, i) => (
//                             <tr>
//                               <td>{adKeyword.keyword}</td>
//                               <td>{adKeyword.type}</td>
//                               <td className="text-center more-action">
//                                 <i onClick={() => this.removeFormFields(i)}>
//                                   <img src={DeleteIcon} alt="" />
//                                 </i>
//                               </td>
//                             </tr>
//                           ))}
//                         </tbody>
//                       </table>
//                     </div>
//                     <Row>
//                       <Col>
//                         <p>{this.state.adKeywords.length} keyword added</p>
//                       </Col>
//                       <Col className="text-end remove-all-link">
//                         <span onClick={this.removeAllAdkeyWords}>
//                           Remove All
//                         </span>
//                       </Col>
//                     </Row>
//                   </Col>
//                 </Row>
//               </div>
//               <div>
//                 <Row>
//                   <Col md={10}>
//                     <label>Settings</label>
//                   </Col>
//                   <Col md={4}>
//                     <div>
//                       <label>Campaign Bidding Strategy</label>
//                     </div>
//                     <div className="form-check">
//                       <input
//                         className="form-check-input"
//                         type="radio"
//                         name="flexRadioDefault"
//                         id="flexRadioDefault1"
//                         onChange={(e) =>
//                           this.handleChange("campaignBiddingStrategy", e)
//                         }
//                         value={"Down only"}
//                       />
//                       <label
//                         className="form-check-label"
//                         htmlFor="flexRadioDefault1"
//                       >
//                         Down only
//                       </label>
//                     </div>
//                     <div className="form-check">
//                       <input
//                         className="form-check-input"
//                         type="radio"
//                         name="flexRadioDefault"
//                         id="flexRadioDefault2"
//                         onChange={(e) =>
//                           this.handleChange("campaignBiddingStrategy", e)
//                         }
//                         value={"Up & Down"}
//                       />
//                       <label
//                         className="form-check-label"
//                         htmlFor="flexRadioDefault2"
//                       >
//                         Up & Down
//                       </label>
//                     </div>
//                     <div className="form-check">
//                       <input
//                         className="form-check-input"
//                         type="radio"
//                         name="flexRadioDefault"
//                         id="flexRadioDefault2"
//                         onChange={(e) =>
//                           this.handleChange("campaignBiddingStrategy", e)
//                         }
//                         value={"Fixed"}
//                       />
//                       <label
//                         className="form-check-label"
//                         htmlFor="flexRadioDefault2"
//                       >
//                         Fixed
//                       </label>
//                     </div>
//                   </Col>
//                   <Col md={4}>
//                     <div>
//                       <label htmlFor="basic-url" className="form-label">
//                         Daily Budget
//                       </label>
//                       <div className="input-group mb-3">
//                         <span className="input-group-text" id="basic-addon1">
//                           $
//                         </span>
//                         <input
//                           type="text"
//                           className="form-control"
//                           defaultValue={""}
//                           aria-label="Username"
//                           placeholder="0.00"
//                           aria-describedby="basic-addon1"
//                           onChange={(e) =>
//                             this.handleChangeDecimal("settings.dailyBudget", e)
//                           }
//                         />
//                       </div>
//                     </div>
//                     <div>
//                       <label htmlFor="basic-url" className="form-label">
//                         Portfolio
//                       </label>
//                       <select
//                         className="form-select"
//                         aria-label="Default select example"
//                         onChange={(e) =>
//                           this.handleChange("settings.portfolio", e)
//                         }
//                         defaultValue={""}
//                       >
//                         <option value="">Choose Portfolio</option>
//                         {this.state.portfolioData.map((list: any, i) => (
//                           <option value={list.name}>{list.name}</option>
//                         ))}
//                       </select>
//                     </div>
//                   </Col>
//                 </Row>
//               </div>
//             </form>
//           </div>
//           <hr />
//           <div className="form-button-group">
//             <Link to={"/ads/ads-creation"} className="btn btn-outline-primary">
//               Back
//             </Link>
//             <button
//               className="btn btn-primary"
//               type="submit"
//               onClick={this.submitCreateCampaing}
//             >
//               Save Changes
//             </button>
//           </div>
//         </div>
//       </DashboardLayout>
//     );
//   }
// }

// // export default CreateManualCampaign;

/* eslint-disable jsx-a11y/anchor-is-valid */
// import React, { Component } from "react";
// import { Routes, Route, useParams } from "react-router-dom";
// import DashboardLayout from "../../../../layouts/DashboardLayout";
// import { Row, Col, Container, Form } from "react-bootstrap";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
// import { faPlus } from "@fortawesome/free-solid-svg-icons";
// import "../index.css";
// import LinkIcon from "../../../../assets/images/icons/link-icon.svg";
// import BackArrow from "../../../../assets/images/icons/back-arrow-icon.svg";
// import ThreeDots from "../../../../assets/images/icons/more-action-icon.svg";
// import DeleteIcon from "../../../../assets/images/icons/trash-icon.svg";
// import Moment from "moment";
// import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
// import InfoIcon from "../../../../assets/images/icons/info-circle-icon.svg";
// import same from "../../../../assets/images/icons/same.png";
// import individual from "../../../../assets/images/icons/Individual.png";
// import individualcampaign from "../../../../assets/images/icons/individualcampaign.png";
// import { getWidth } from "rsuite/esm/DOMHelper";
// import { wrap } from "module";
// import Tabs from "react-bootstrap/Tabs";
// import Tab from "react-bootstrap/Tab";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// class CreateManualCampaign extends Component {
//   state = {
//     isError: {
//       campaignBiddingStrategy: true,
//       portfolio: true,
//       dailyBudget: true,
//       targetingBid: true,
//       defaultBid: true,
//       marketPlaces: true,
//     },
//     isTargetType: "KeywordsTargeting",
//     imgstate: 1,
//     addKeyWordTypeManualList: "product",
//     matchTypesList: [
//       { id: 1, nameid: "broad", title: "Broad", isChecked: true, name: "" },
//       { id: 2, nameid: "phrase", title: "Phrase", isChecked: true, name: "" },
//       {
//         id: 3,
//         nameid: "exact",
//         title: "Exact",
//         isChecked: true,
//         name: "",
//       },
//     ],
//     list: "List",
//     startDate: new Date(),
//     EndDate: new Date(),
//     isSelectedMarketPlace: [{ close: "" }],
//     isSelectedKeyword: [],
//     isSelected: null,
//     isSelectedCampaign: 1,
//     marketPlacesListSelected: [],
//     isopen: false,
//     portfolioData: [],
//     marketPlacesList: [{ countryCode: "" }],
//     CreateFormData: {
//       campaignType: "ManualCampaign",
//       campaignName: "",
//       asins: [],
//       matchType: "SingleCampaignWithSingleAdgroup",
//       placement: {
//         topOfSearch: undefined,
//         productDetailPages: undefined,
//         restOfSearch: undefined,
//       },
//       settings: {
//         portfolio: "",
//         dailyBudget: "",
//         startDate: "",
//         endDate: "",
//         campaignBiddingStrategy: "",
//       },
//       marketPlaces: [],
//       negativeKeywordTargeting: null,
//       dynamicBidding: {
//         defaultBid: "",
//         targetingBid: "",
//       },
//       targets: [],
//       postToAmazon: true,
//     },

//     createCampaignFormData: {
//       asin: [],
//       campaignName: "",
//       matchTypeOption: "individualCampaign",
//       adGroups: [
//         {
//           name: "",
//         },
//       ],
//       targetingStrategy: "Auto Campaign",
//       dynamicBidding: {
//         defaultBid: 0,
//         targetingBid: 0,
//         close: 0,
//         loose: 0,
//         substitutes: 0,
//         complements: 0,
//         broad: 0,
//         phrase: 0,
//         exact: 0,
//         product: 0,
//       },
//       negativeKeywordTargeting: null,
//       campaignBiddingStrategy: "",
//       settings: {
//         portfolio: "",
//         dailyBudget: "",
//       },
//       targets: null,
//       postToAmazon: true,
//       marketPlaces: ["US"],
//     },
//     adGroupsTemp: "",
//     adGroups: [],
//     adKeywordType: [],
//     adKeywordsTemp: "",
//     adKeywords: [],
//     negativeKeywordTargeting: {},
//     matchTypeSelect: false,
//     matchTypeCheckedlist: [""],
//     editTab: false,
//     lastUpdatedDate: localStorage.getItem("lastUpdatedDate") || null,
//     selectedTab: "auto",
//   };
//   componentDidMount() {
//     const queryParams = window.location.pathname;
//     let queryParamsArr = queryParams.split("/");
//     const asin = queryParamsArr[4];
//     const comapaignRuleId = queryParamsArr[5];
//     if (comapaignRuleId) {
//       let newFormEditObj: any = this.state.CreateFormData;
//       newFormEditObj["id"] = queryParamsArr[5];
//       console.log("Edit rule obj : ", newFormEditObj);
//       this.setState({ CreateFormData: newFormEditObj });
//       this.setState({ editTab: true });
//       this.getCampainById(comapaignRuleId);
//       this.getPortfolioData();
//     } else {
//       this.setState({ editTab: false });
//       console.log("createCamapaing Asin ID: ", asin);
//       let newCreateCampaignFormData: any = this.state.CreateFormData;
//       if (newCreateCampaignFormData.asins.length < 1) {
//         newCreateCampaignFormData.asins.push(asin);
//         this.setState({ newCreateCampaignFormData: newCreateCampaignFormData });
//       }
//       this.getPortfolioData();
//       this.getMarketplaceData();
//     }
//   }

//   handleChangeDate = (type, date) => {
//     if (type === "start") {
//       this.setState({ startDate: date });
//       this.setState((prevState: any) => {
//         const currentDate = date;
//         const year = currentDate.getFullYear();
//         const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
//         const day = currentDate.getDate().toString().padStart(2, "0");
//         return {
//           CreateFormData: {
//             ...prevState.CreateFormData,
//             settings: {
//               ...prevState.CreateFormData.settings,
//               startDate: `${year}-${month}-${day}`,
//             },
//           },
//         };
//       });
//     } else {
//       this.setState({ EndDate: date });
//       this.setState((prevState: any) => {
//         const currentDate = date;
//         const year = currentDate.getFullYear();
//         const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
//         const day = currentDate.getDate().toString().padStart(2, "0");
//         return {
//           CreateFormData: {
//             ...prevState.CreateFormData,
//             settings: {
//               ...prevState.CreateFormData.settings,
//               endDate: `${year}-${month}-${day}`,
//             },
//           },
//         };
//       });
//     }
//   };
//   getCampainById = async (id: any) => {
//     console.log(id);

//     let userToken = localStorage.getItem("userToken");
//     let AuthToken = "Bearer " + userToken;
//     let url = "https://api.aimosa.io/Campaign/" + id;
//     const response = await fetch(url, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: AuthToken,
//       },
//     });

//     try {
//       const responceData = await response.json();
//       this.setState({
//         adKeywords: responceData?.result?.negativeKeywordTargeting?.keywords,
//       });
//       // console.log("data"+responceData.result.adGroups[0].name);
//       let newCreateCampaignFormData: any = this.state.createCampaignFormData;
//       newCreateCampaignFormData.id = responceData.result.id;
//       newCreateCampaignFormData.asin = responceData.result.asins;
//       newCreateCampaignFormData.campaignName =
//         responceData.result.campaignName.name;
//       newCreateCampaignFormData.adGroups = responceData.result.adGroups;
//       newCreateCampaignFormData.marketPlaces = responceData.result.marketPlaces;
//       newCreateCampaignFormData.targetingStrategy =
//         responceData.result.targetingStrategy;
//       newCreateCampaignFormData.campaignBiddingStrategy =
//         responceData.result.campaignBiddingStrategy;
//       newCreateCampaignFormData.dynamicBidding.defaultBid =
//         responceData.result.dynamicBidding.defaultBid;
//       newCreateCampaignFormData.dynamicBidding.targetingBid =
//         responceData.result.dynamicBidding.targetingBid;
//       newCreateCampaignFormData.dynamicBidding.close =
//         responceData.result.dynamicBidding.close;
//       newCreateCampaignFormData.dynamicBidding.loose =
//         responceData.result.dynamicBidding.loose;
//       newCreateCampaignFormData.dynamicBidding.substitutes =
//         responceData.result.dynamicBidding.substitutes;
//       newCreateCampaignFormData.dynamicBidding.complements =
//         responceData.result.dynamicBidding.complements;
//       newCreateCampaignFormData.dynamicBidding.broad =
//         responceData.result.dynamicBidding.broad;
//       newCreateCampaignFormData.dynamicBidding.phrase =
//         responceData.result.dynamicBidding.phrase;
//       newCreateCampaignFormData.dynamicBidding.exact =
//         responceData.result.dynamicBidding.exact;
//       newCreateCampaignFormData.dynamicBidding.product =
//         responceData.result.dynamicBidding.product;
//       newCreateCampaignFormData.settings.dailyBudget =
//         responceData.result.settings.dailyBudget;
//       newCreateCampaignFormData.settings.portfolio =
//         responceData.result.settings.portfolio;
//       newCreateCampaignFormData.negativeKeywordTargeting.keywords =
//         responceData?.result?.negativeKeywordTargeting?.keywords;
//       // newCreateCampaignFormData.negativeKeywordTargeting.keywords.keyword=responceData.result.negativeKeywordTargeting.keywords[0].keyword;
//       newCreateCampaignFormData.negativeKeywordTargeting.targetingType =
//         responceData.result.negativeKeywordTargeting.targetingType;

//       this.setState({ newCreateCampaignFormData: newCreateCampaignFormData });
//       // this.setState({ portfolioData: responceData.result });
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };
//   handleChange = (name, e) => {
//     let newFormValues = this.state.CreateFormData;
//     let genKey = name.split(".");
//     if (genKey.length === 1) {
//       newFormValues[genKey[0]] = e.target.value;
//     }
//     if (genKey.length === 2) {
//       newFormValues[genKey[0]][genKey[1]] = e.target.value;
//     }
//     this.setState({ CreateFormData: newFormValues });
//   };
//   handleChangeDecimal = (name, e) => {
//     let newFormValues = this.state.createCampaignFormData;
//     let genKey = name.split(".");
//     if (genKey.length === 1) {
//       newFormValues[genKey[0]] = parseFloat(e.target.value);
//     }
//     if (genKey.length === 2) {
//       newFormValues[genKey[0]][genKey[1]] = parseFloat(e.target.value);
//     }
//     console.log("New Form Data: ", newFormValues);
//     this.setState({ createCampaignFormData: newFormValues });
//   };
//   addTempAdgroup = (e) => {
//     this.setState({ adGroupsTemp: e.target.value });
//   };
//   adGroupsData = () => {
//     this.setState({ isopen: !this.state.isopen });
//     let newAdGroups: any = this.state.adGroups;
//     let val = { name: this.state.adGroupsTemp };
//     if (this.state.adGroupsTemp) {
//       newAdGroups.push(val);
//     }
//     this.setState({ adGroups: newAdGroups });
//     let newCreateCampaignFormData: any = this.state.createCampaignFormData;
//     newCreateCampaignFormData.adGroups = newAdGroups;
//     this.setState({ newCreateCampaignFormData: newCreateCampaignFormData });
//     this.setState({ adGroupsTemp: "" });
//   };
//   addKeyWordType = (e) => {
//     let keywordList: any[] = [...this.state.adKeywordType];
//     console.log(keywordList);
//     if (keywordList.includes(e.target.value)) {
//       const updatedList = keywordList.filter(
//         (itemList) => itemList !== e.target.value
//       );
//       this.setState({ adKeywordType: updatedList });
//     } else {
//       keywordList.push(e.target.value);
//       this.setState({ adKeywordType: keywordList });
//     }
//   };

//   addTempAdKeywords = (e) => {
//     this.setState({ adKeywordsTemp: e.target.value });
//   };
//   removeFormFields = (i) => {
//     if (this.state.editTab == false) {
//       let newFormValues = this.state.adKeywords;
//       newFormValues.splice(i, 1);
//       this.setState({ adKeywords: newFormValues });
//       let newCreateCampaignFormData: any = this.state.CreateFormData;
//       newCreateCampaignFormData.targets = newFormValues;
//       this.setState({ newCreateCampaignFormData: newCreateCampaignFormData });
//     } else {
//       let newFormValues = this.state.adKeywords;
//       newFormValues.splice(i, 1);
//       this.setState({ adKeywords: newFormValues });
//       let newCreateCampaignFormData: any = this.state.CreateFormData;
//       newCreateCampaignFormData.targets = newFormValues;
//       this.setState({ newCreateCampaignFormData: newCreateCampaignFormData });
//     }
//   };
//   adkeyWordSubmit = () => {
//     if (this.state.adKeywordsTemp) {
//       let adKeywordsListStr = this.state.adKeywordsTemp;
//       let newAdKeywords: any = this.state.adKeywords;
//       let adKeywordsListArr = adKeywordsListStr.split(",");
//       for (let i = 0; i < adKeywordsListArr.length; i++) {
//         let val: any = {
//           targetType: this.state.isTargetType,
//           matchtype:
//             this.state.isTargetType === "ProductTargeting"
//               ? [this.state.addKeyWordTypeManualList]
//               : this.state.adKeywordType,
//           targetText: adKeywordsListArr[i],
//         };
//         if (this.state.adKeywordsTemp) {
//           newAdKeywords.push(val);
//         }

//         this.setState({ adGroups: newAdKeywords });
//       }
//       console.log(this.state.adGroups);
//       console.log(newAdKeywords);

//       this.setState((prevState: any) => {
//         return {
//           CreateFormData: {
//             ...prevState.CreateFormData,
//             targets: newAdKeywords,
//           },
//         };
//       });

//       this.setState({ adKeywordsTemp: "" });
//     }
//   };
//   removeAllAdkeyWords = () => {
//     let newFormValues = this.setState({ adKeywords: [] });
//     let newCreateCampaignFormData: any = this.state.CreateFormData;
//     newCreateCampaignFormData.targets = newFormValues;
//     this.setState({ newCreateCampaignFormData: newCreateCampaignFormData });
//   };
//   getPortfolioData = async () => {
//     let userToken = localStorage.getItem("userToken");
//     let AuthToken = "Bearer " + userToken;
//     let url = "https://api.aimosa.io/AdsManager/Portfolios";
//     const response = await fetch(url, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: AuthToken,
//       },
//     });

//     try {
//       const responceData = await response.json();
//       this.setState({ portfolioData: responceData?.result?.data });
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };
//   getMarketplaceData = async () => {
//     let userToken = localStorage.getItem("userToken");
//     let AuthToken = "Bearer " + userToken;
//     let url = "https://api.aimosa.io/AdsManager/Marketplaces";
//     const requestOptions = {
//       method: "get",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: AuthToken,
//       },
//       // body: JSON.stringify(this.state.createCampaignFormData),
//     };

//     try {
//       const response = await fetch(url, requestOptions);
//       const responceData = await response.json();
//       if (responceData.success) {
//         let result = responceData.result;
//         this.setState({ marketPlacesList: result });
//         console.error(result);
//       } else {
//         // toast("Unable to create");
//       }
//       // setRedirect("/onboarding");
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       // toast("Error On Creating");
//     }
//   };
//   submitCreateCampaing = async () => {
//     if (this.state.editTab == false) {
//       let userToken = localStorage.getItem("userToken");
//       let AuthToken = "Bearer " + userToken;
//       let url = "https://api.aimosa.io/Campaign/Auto";
//       const requestOptions = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: AuthToken,
//         },
//         body: JSON.stringify(this.state.createCampaignFormData),
//       };

//       try {
//         const response = await fetch(url, requestOptions);
//         const responceData = await response.json();
//         if (responceData.success) {
//           let result = responceData.result;
//           console.error(result);
//           toast("Successfully created");
//         } else {
//           toast("Unable to create");
//         }
//         // setRedirect("/onboarding");
//       } catch (error) {
//         console.error("Error fetching data:", error);
//         toast("Error On Creating");
//       }
//     } else {
//       let userToken = localStorage.getItem("userToken");
//       let AuthToken = "Bearer " + userToken;
//       let url = "https://api.aimosa.io/Campaign/Auto";
//       const requestOptions = {
//         method: "PUT",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: AuthToken,
//         },
//         body: JSON.stringify(this.state.createCampaignFormData),
//       };

//       try {
//         const response = await fetch(url, requestOptions);
//         const responceData = await response.json();
//         console.log(responceData);

//         if (responceData.success) {
//           let result = responceData.result;
//           console.error(result);
//           toast("Successfully Updated");
//         } else {
//           toast("Unable to update");
//         }
//         // setRedirect("/onboarding");
//       } catch (error) {
//         console.error("Error fetching data:", error);
//         toast("Error On Creating");
//       }
//     }
//   };
//   matchTypeSelectAllClick = (check) => {
//     this.setState({ matchTypeSelect: check });
//     let matchTypeChecklistArr = [
//       "Close",
//       "Loose",
//       "Substitutes",
//       "Complements",
//     ];
//     if (check) {
//       this.setState({ matchTypeCheckedlist: matchTypeChecklistArr });
//     } else {
//       this.setState({ matchTypeCheckedlist: [] });
//     }
//   };
//   matchTypeCheckBoxClick = (e) => {
//     const ischecked = e.target.checked;
//     const newSelectedList = this.state.matchTypeCheckedlist;
//     if (ischecked === false) {
//       const index = newSelectedList.indexOf(e.target.value);
//       if (index > -1) {
//         newSelectedList.splice(index, 1);
//       }
//       this.setState({ matchTypeCheckedlist: newSelectedList });
//     } else {
//       const newArray = this.state.matchTypeCheckedlist;
//       newArray.push(e.target.value);
//       this.setState({ matchTypeCheckedlist: newArray });
//     }
//   };
//   matchTypeDelete = (name, e) => {
//     e.target.parentElement.firstChild.value = "";
//     let newFormValues = this.state.createCampaignFormData;
//     let genKey = name.split(".");
//     if (genKey.length === 1) {
//       newFormValues[genKey[0]] = "";
//     }
//     if (genKey.length === 2) {
//       newFormValues[genKey[0]][genKey[1]] = "";
//     }
//     console.log("Remove type Form Data: ", newFormValues);
//     this.setState({ createCampaignFormData: newFormValues });
//   };
//   handleTabClick = (tab: string) => {
//     this.setState({ selectedTab: tab });
//   };
//   handleTabHover = (tab) => {
//     this.setState({ selectedTab: tab });
//   };

//   handleChanges = (type, condition) => {
//     if (type === 1) {
//       this.setState({
//         isSelectedCampaign: type,
//         // imgstate: 1,
//       });
//     } else {
//       this.setState({
//         isSelectedCampaign: type,
//         // imgstate: null,
//       });
//       this.setState((prev: any) => ({
//         CreateFormData: {
//           ...prev.CreateFormData,
//           matchType: "MultipleCampaigns",
//         },
//       }));
//     }
//   };
//   placementChange = (type, e) => {
//     const stringWithQuotes = e.target.value;
//     const parsedNumber = parseFloat(stringWithQuotes.replace(/"/g, "").trim());
//     this.setState((prevState: any) => ({
//       CreateFormData: {
//         ...prevState.CreateFormData,
//         placement: {
//           ...prevState.CreateFormData.placement,
//           [e.target.name]: parsedNumber,
//         },
//       },
//     }));
//   };
//   settingChange = (e) => {
//     this.setState((prevState: any) => {
//       let updatedSettings = { ...prevState.CreateFormData.settings };
//       if (e.target.name === "portfolio") {
//         updatedSettings = {
//           ...updatedSettings,
//           portfolio: e.target.value,
//         };
//       } else {
//         const stringWithQuotes = e.target.value;
//         const parsedNumber = parseFloat(
//           stringWithQuotes.replace(/"/g, "").trim()
//         );
//         updatedSettings = {
//           ...updatedSettings,
//           dailyBudget: parsedNumber,
//         };
//       }
//       return {
//         CreateFormData: {
//           ...prevState.CreateFormData,
//           settings: updatedSettings,
//         },
//       };
//     });
//   };

//   postDataCampaign = async () => {
//     let userToken = localStorage.getItem("userToken");
//     let AuthToken = "Bearer " + userToken;
//     let url = "https://api.aimosa.io/AdsManager/Ads";
//     // this.keywordChangedynamic();

//     const campaigns = [this.state.CreateFormData];

//     // const campaigns = [
//     //   {
//     //     campaignType: "AutoCampaign",
//     //     campaignName: "book",
//     //     asins: ["B009UKPVIW"],
//     //     matchType: "SingleCampaignWithSingleAdgroup",
//     //     placement: {
//     //       topOfSearch: 10,
//     //       productDetailPages: 10,
//     //       restOfSearch: 10,
//     //     },
//     //     settings: {
//     //       portfolio: "string",
//     //       dailyBudget: 10,
//     //       startDate: "1999/12/8",
//     //       endDate: "1999/12/10",
//     //       campaignBiddingStrategy: "string",
//     //     },
//     //     marketPlaces: ["US"],
//     //     negativeKeywordTargeting: {
//     //       targetingLevel: "string",
//     //       targetingType: "string",
//     //       keywords: [
//     //         {
//     //           type: "string",
//     //           keyword: "string",
//     //         },
//     //       ],
//     //     },
//     //     dynamicBidding: {
//     //       defaultBid: 0,
//     //       targetingBid: 0,
//     //       close: 0,
//     //       loose: 0,
//     //       substitutes: 0,
//     //       complements: 0,
//     //       broad: 0,
//     //       phrase: 0,
//     //       exact: 0,
//     //       product: 0,
//     //     },
//     //     targets: null,
//     //     // [
//     //     //   {
//     //     //     targetType: "string",
//     //     //     matchtype: ["string"],
//     //     //     targetText: "string",
//     //     //   },
//     //     // ],
//     //     postToAmazon: false,
//     //   },
//     // ];
//     const requestOptions = {
//       method: "POST",
//       body: JSON.stringify({ campaigns }),
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: AuthToken,
//       },
//     };
//     try {
//       const response = await fetch(url, requestOptions);
//       const responceData = await response.json();
//       if (response.ok) {
//         toast("Created Sucessfully");
//       } else {
//         toast("Error On Creating");
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       toast("Error On Creating");
//     }
//   };
//   addKeyWordTypeManual = (e) => {
//     console.log(e.target.value);

//     this.setState({ addKeyWordTypeManualList: e.target.value });
//   };
//   render() {
//     const { marketPlaces } = this.state.CreateFormData;
//     const marketplacesList = (item: any) => {
//       let list: any[] = [...marketPlaces];
//       if (list.includes(item)) {
//         const updatedList = list.filter((itemList) => itemList !== item);
//         this.setState((prevState: any) => ({
//           CreateFormData: {
//             ...prevState.CreateFormData,
//             marketPlaces: updatedList,
//           },
//         }));
//         // this.setState({ isSelectedMarketPlace: updatedList });
//       } else {
//         list.push(item);
//         this.setState((prevState: any) => ({
//           CreateFormData: {
//             ...prevState.CreateFormData,
//             marketPlaces: list,
//           },
//         }));
//         // this.setState({ isSelectedMarketPlace: list });
//       }
//       // const marketplacesList = (item: any) => {
//       //   let listing = marketPlaces.push(item);
//       //   this.setState((prevState: any) => ({
//       //     CreateFormData: {
//       //       ...prevState.CreateFormData,
//       //       marketPlaces: [listing],
//       //     },
//       //   }));
//       // this.setState({ isSelectedMarketPlace: item });
//       // console.log(this.state.CreateFormData.marketPlaces.includes(item));

//       // if(this.state.CreateFormData.marketPlaces.includes(item)){

//       // }
//       // if (itemList.includes(id:any)) {
//       // }
//       // if()
//       // this.setState({ isSelectedMarketPlace: item });
//       // this.setState((prevState: any) => ({
//       //   CreateFormData: {
//       //     ...prevState.CreateFormData,
//       //     marketPlaces: [item],
//       //   },
//       // }));
//       // console.log(item);
//     };

//     const marketplaces = this.state.marketPlacesList.map((item: any, index) => (
//       <div
//         key={index}
//         style={{ cursor: "pointer" }}
//         className={`marketplace-item ${
//           this.state.CreateFormData.marketPlaces.some(
//             (marketItem: any) => marketItem === item.countryCode
//           )
//             ? "marketplace-selected"
//             : null
//         }`}
//         onClick={() => marketplacesList(item.countryCode)}
//       >
//         {item.countryCode === "US" ? (
//           <>
//             <div className="d-flex align-items-center">
//               <img
//                 src="https://cdn-icons-png.flaticon.com/512/323/323310.png"
//                 alt="US Flag"
//                 className="flag-icons"
//               />
//               &nbsp;
//               {item.countryCode}
//             </div>
//           </>
//         ) : item.countryCode === "CA" ? (
//           <>
//             <div className="d-flex align-items-center">
//               <img
//                 src="https://cdn-icons-png.flaticon.com/512/197/197430.png"
//                 alt="US Flag"
//                 className="flag-icons"
//               />
//               &nbsp;
//               {item.countryCode}
//             </div>
//           </>
//         ) : item.countryCode === "ES" ? (
//           <>
//             <div className="d-flex align-items-center">
//               <img
//                 src="https://cdn-icons-png.flaticon.com/512/197/197593.png"
//                 alt="US Flag"
//                 className="flag-icons"
//               />
//               &nbsp;
//               {item.countryCode}
//             </div>
//           </>
//         ) : item.countryCode === "FR" ? (
//           <>
//             <div className="d-flex align-items-center">
//               <img
//                 src="https://cdn-icons-png.flaticon.com/512/197/197560.png"
//                 alt="US Flag"
//                 className="flag-icons"
//               />
//               &nbsp;
//               {item.countryCode}
//             </div>
//           </>
//         ) : item.countryCode === "DE" ? (
//           <>
//             <div className="d-flex align-items-center">
//               <img
//                 src="https://cdn-icons-png.flaticon.com/512/197/197571.png"
//                 alt="US Flag"
//                 className="flag-icons"
//               />
//               &nbsp;
//               {item.countryCode}
//             </div>
//           </>
//         ) : item.countryCode === "IT" ? (
//           <>
//             <div className="d-flex align-items-center">
//               <img
//                 src="https://cdn-icons-png.flaticon.com/512/323/323325.png"
//                 alt="US Flag"
//                 className="flag-icons"
//               />
//               &nbsp;
//               {item.countryCode}
//             </div>
//           </>
//         ) : item.countryCode === "AU" ? (
//           <>
//             <div className="d-flex align-items-center">
//               <img
//                 src="https://cdn-icons-png.flaticon.com/512/323/323367.png"
//                 alt="US Flag"
//                 className="flag-icons"
//               />
//               &nbsp;
//               {item.countryCode}
//             </div>
//           </>
//         ) : (
//           <> {item.countryCode}</>
//         )}
//       </div>
//     ));
//     const handleOptionClick = (type, condition) => {
//       this.setState((prevState: any) => ({
//         CreateFormData: {
//           ...prevState.CreateFormData,
//           settings: {
//             ...prevState.CreateFormData.settings,
//             campaignBiddingStrategy: type,
//           },
//         },
//         isSelected: type,
//       }));
//     };
//     console.log(this.state.CreateFormData);
//     const handleTabSelect = (title) => {
//       this.setState({ isTargetType: title });
//       // this.setState((prevState: any) => ({
//       //   CreateFormData: {
//       //     ...prevState.CreateFormData,
//       //     negativeKeywordTargeting: {
//       //       ...prevState.CreateFormData.negativeKeywordTargeting,
//       //       targetingType: title,
//       //     },
//       //   },
//       // }));
//     };
//     const keywordChangedynamic = (e: any) => {
//       const { name, value } = e.target;

//       const updatedMatchTypesList = this.state.matchTypesList.map((item) => {
//         if (item.nameid === name) {
//           return { ...item, name: value };
//         }
//         return item;
//       });
//       this.setState({ matchTypesList: updatedMatchTypesList });
//     };
//     const keywordChange = (e: any) => {
//       const stringWithQuotes = e.target.value;
//       const parsedNumber = parseFloat(
//         stringWithQuotes.replace(/"/g, "").trim()
//       );
//       this.setState((prevState: any) => ({
//         CreateFormData: {
//           ...prevState.CreateFormData,
//           dynamicBidding: {
//             ...prevState.CreateFormData.dynamicBidding,
//             [e.target.name]: parsedNumber,
//           },
//         },
//       }));
//     };
//     const keywordChangeHandler = (item, e) => {
//       this.setState((prevState: any) => {
//         const updatedList = prevState.matchTypesList.map((listItem) => {
//           if (listItem.id === item.id && listItem.name === "") {
//             return { ...listItem, isChecked: !listItem.isChecked };
//           }
//           return listItem;
//         });

//         return {
//           matchTypesList: updatedList,
//         };
//       });
//     };
//     const selectedAllAdkeyWords = (e) => {
//       this.setState((prevState: any) => {
//         const allChecked = prevState.matchTypesList.every(
//           (item) => item.isChecked
//         );
//         const updatedList = prevState.matchTypesList.map((listItem) => ({
//           ...listItem,
//           isChecked: !allChecked,
//         }));
//         return {
//           matchTypesList: updatedList,
//         };
//       });
//     };
//     const removematchtypeHandler = (item: any, e) => {
//       this.setState((prevState: any) => {
//         const updatedList = prevState.matchTypesList.map((listItem) => {
//           if (listItem.id === item.id) {
//             return { ...listItem, name: "" };
//           }
//           return listItem;
//         });
//         console.log(updatedList);

//         this.setState({ matchTypesList: updatedList });
//       });
//     };
//     // const checkHandler = () => {
//     //   const matchTypesListGroup = this.state.matchTypesList.filter(
//     //     (item) => item.isChecked === true
//     //   );
//     //   this.setState((prevState: any) => {
//     //     const updatedDynamicBidding = {
//     //       ...prevState.CreateFormData.dynamicBidding,
//     //     };
//     //     matchTypesListGroup.map((item) => {
//     //       updatedDynamicBidding[item.nameid] = parseInt(item.name);
//     //     });
//     //     this.setState(
//     //       (prevState: any) => ({
//     //         CreateFormData: {
//     //           ...prevState.CreateFormData,
//     //           dynamicBidding: updatedDynamicBidding,
//     //         },
//     //       }),
//     //       async () => {
//     //         await this.postDataCampaign();
//     //       }
//     //     );
//     //   });
//     // };
//     const resetObject = async () => {
//       const defaultValues = {
//         campaignType: "ManualCampaign",
//         campaignName: "",
//         asins: this.state.CreateFormData.asins,
//         matchType: "SingleCampaignWithSingleAdgroup",
//         placement: {
//           topOfSearch: undefined,
//           productDetailPages: undefined,
//           restOfSearch: undefined,
//         },
//         settings: {
//           portfolio: "",
//           dailyBudget: "",
//           startDate: "",
//           endDate: "",
//           campaignBiddingStrategy: "",
//         },
//         marketPlaces: [],
//         negativeKeywordTargeting: null,
//         dynamicBidding: {
//           defaultBid: "",
//           targetingBid: "",
//         },
//         targets: [],
//         postToAmazon: true,
//       };
//       const matchTypesListReset = [
//         { id: 1, nameid: "broad", title: "Broad", isChecked: true, name: "" },
//         { id: 2, nameid: "phrase", title: "Phrase", isChecked: true, name: "" },
//         {
//           id: 3,
//           nameid: "exact",
//           title: "Exact",
//           isChecked: true,
//           name: "",
//         },
//       ];
//       await this.setState({ CreateFormData: defaultValues });
//       await this.setState({ matchTypesList: matchTypesListReset });
//       await this.setState({ isTitle: null });
//       await this.setState({ isSelected: null });
//       await this.setState({ adKeywords: [] });
//       await this.setState({ adKeywordType: [] });
//     };

//     const checkHandler = async () => {
//       const { CreateFormData } = this.state;
//       console.log(CreateFormData);
//       const validationCheck = {
//         portfolio: !!CreateFormData?.settings?.portfolio,
//         dailyBudget: !!CreateFormData?.settings?.dailyBudget,
//         targetingBid: !!CreateFormData?.dynamicBidding?.targetingBid,
//         defaultBid: !!CreateFormData?.dynamicBidding?.defaultBid,
//         campaignBiddingStrategy:
//           !!CreateFormData?.settings?.campaignBiddingStrategy,
//         marketPlaces: CreateFormData?.marketPlaces?.length > 0,
//       };
//       this.setState({ isError: validationCheck });

//       // const matchTypesListGroup = this.state.matchTypesList.filter(
//       //   (item) => item.isChecked === true
//       // );
//       // const updatedDynamicBidding = {
//       //   ...this.state.CreateFormData.dynamicBidding,
//       // };
//       // matchTypesListGroup.forEach((item) => {
//       //   updatedDynamicBidding[item.nameid] = parseFloat(
//       //     item.name.replace(/"/g, "").trim()
//       //   );
//       // });
//       // await this.setState((prevState: any) => ({
//       //   CreateFormData: {
//       //     ...prevState.CreateFormData,
//       //     dynamicBidding: updatedDynamicBidding,
//       //   },
//       // }));
//       // await this.setState((prevState: any) => {
//       //   const conditionMet = this.state.CreateFormData.targets;
//       //   if (conditionMet.length === 0) {
//       //     return {
//       //       CreateFormData: {
//       //         ...prevState.CreateFormData,
//       //         targets: null,
//       //       },
//       //     };
//       //   }
//       // });
//       // await this.postDataCampaign();
//       // this.setState((prevState: any) => {
//       //   return {
//       //     CreateFormData: {
//       //       ...prevState.CreateFormData,
//       //       targets: [],
//       //     },
//       //   };
//       // });
//     };
//     console.log(this.state.isError);

//     const imgChange = (item) => {
//       this.setState({ imgstate: item });
//       if (item === 2) {
//         this.setState((prev: any) => ({
//           CreateFormData: {
//             ...prev.CreateFormData,
//             matchType: "SingleCampaignWithMultipleAdgroup",
//           },
//         }));
//       } else {
//         this.setState((prev: any) => ({
//           CreateFormData: {
//             ...prev.CreateFormData,
//             matchType: "SingleCampaignWithSingleAdgroup",
//           },
//         }));
//       }
//     };
//     const submitHandler = async (e) => {
//       e.preventDefault();
//       const allTrue = Object.values(this.state.isError).every(
//         (value) => value === true
//       );
//       if (allTrue) {
//         const matchTypesListGroup = this.state.matchTypesList.filter(
//           (item) => item.isChecked === true
//         );

//         const updatedDynamicBidding = {
//           ...this.state.CreateFormData.dynamicBidding,
//         };
//         matchTypesListGroup.forEach((item) => {
//           updatedDynamicBidding[item.nameid] = parseFloat(
//             item.name.replace(/"/g, "").trim()
//           );
//         });
//         await this.setState((prevState: any) => ({
//           CreateFormData: {
//             ...prevState.CreateFormData,
//             dynamicBidding: updatedDynamicBidding,
//           },
//         }));
//         await this.setState((prevState: any) => {
//           const conditionMet = this.state.CreateFormData.targets;
//           if (conditionMet.length === 0) {
//             return {
//               CreateFormData: {
//                 ...prevState.CreateFormData,
//                 targets: null,
//               },
//             };
//           }
//         });
//         await this.postDataCampaign();
//         await resetObject();
//       }
//     };
//     // const submitHandler = (e) => {
//     //   e.preventDefault();
//     // };
//     return (
//       // <Container fluid>
//       //     <Tabs defaultActiveKey="AutoCampaign" className="ms-1">
//       //       <Tab eventKey="manualCampaign" title="Manual Campaign">

//       <>
//         <DashboardLayout>
//           <div className="main-content-container">
//             <hr />
//             <Tabs defaultActiveKey="ManualCampaign" className="ms-1">
//               <Tab eventKey="AutoCampaign" title="Auto Campaign" disabled>
//                 <div></div>
//               </Tab>
//               <Tab eventKey="ManualCampaign" title="Manual Campaign">
//                 <Form onSubmit={submitHandler}>
//                   <Container fluid>
//                     <Row>
//                       <Col md={8}>
//                         <p className="set-dynamic">
//                           Set dynamic campaign names. You can check the
//                           alternative placeholders{" "}
//                           <Tooltip
//                             title={
//                               <div className="popup-custom-box">
//                                 <p>Alternative Placeholders:</p>
//                                 <ul>
//                                   <li>&#10100;ASIN&#10101;</li>
//                                   <li>&#10100;Layer&#10101;</li>
//                                   <li>&#10100;Match Type&#10101;</li>
//                                   <li>&#10100;Tag&#10101;</li>
//                                   <li>&#10100;Targeting Type&#10101;</li>
//                                 </ul>
//                               </div>
//                             }
//                             placement="bottom"
//                           >
//                             <span className="here-tooltip"> here</span>
//                           </Tooltip>
//                         </p>
//                         <div className="form-group">
//                           <h6 className="campaign-name-text">Campaign Name</h6>
//                           <div className="placeholder-input-wrapper">
//                             <input
//                               className="custom-input-main"
//                               name="createCampaignFormData.campaignName"
//                               onChange={(e) =>
//                                 this.handleChange("campaignName", e)
//                               }
//                               defaultValue={
//                                 this.state.CreateFormData.campaignName
//                               }
//                             />
//                           </div>
//                         </div>
//                         <h6 className="my-3 campaign-name-text">
//                           MatchType Options <img src={InfoIcon} alt="" />
//                         </h6>
//                         <div className="d-flex flex-wrap">
//                           <div
//                             style={{ marginRight: "20px" }}
//                             className="d-flex align-items-baseline"
//                           >
//                             <label>
//                               <input
//                                 type="radio"
//                                 className="form-check-input mx-1"
//                                 // name="matchTypeOption"
//                                 value="1"
//                                 checked={this.state.isSelectedCampaign === 1}
//                                 onChange={(e) => this.handleChanges(1, "auto")}
//                               />
//                             </label>
//                             <p className="set-dynamic">
//                               Create each matchtype in the same campaign
//                             </p>
//                           </div>
//                           <div className="d-flex align-items-baseline">
//                             <label>
//                               <input
//                                 type="radio"
//                                 className="form-check-input mx-1"
//                                 // name="matchTypeOption"
//                                 value="0"
//                                 checked={this.state.isSelectedCampaign === 0}
//                                 onChange={(e) => this.handleChanges(0, "auto")}
//                               />
//                             </label>
//                             <p className="set-dynamic">
//                               {" "}
//                               Create each matchtype in individual campaigns
//                             </p>
//                           </div>
//                         </div>
//                         <h6 className="campaign-name-text">
//                           {" "}
//                           {this.state.isSelectedCampaign === 1
//                             ? "Select 1 of these options below :"
//                             : null}
//                         </h6>
//                         {this.state.isSelectedCampaign === 1 ? (
//                           <>
//                             {this.state.imgstate === 1 ? (
//                               <div className="d-flex" style={{ gap: "20px" }}>
//                                 <div>
//                                   <img
//                                     src={require("../../../../assets/images/man-2-1.jpg")}
//                                     alt="alt-same"
//                                     style={{
//                                       maxHeight: "100%",
//                                       maxWidth: "100%",
//                                       cursor: "pointer",
//                                     }}
//                                     onClick={() => imgChange(1)}
//                                   />
//                                 </div>
//                                 <div>
//                                   <img
//                                     src={require("../../../../assets/images/man-1-1.jpg")}
//                                     alt="alt-individual"
//                                     style={{
//                                       maxHeight: "100%",
//                                       maxWidth: "100%",
//                                       cursor: "pointer",
//                                     }}
//                                     onClick={() => imgChange(2)}
//                                   />
//                                 </div>
//                               </div>
//                             ) : (
//                               <div className="d-flex" style={{ gap: "20px" }}>
//                                 <div>
//                                   <img
//                                     src={require("../../../../assets/images/man-2-2.jpg")}
//                                     alt="alt-same"
//                                     style={{
//                                       maxHeight: "100%",
//                                       maxWidth: "100%",
//                                       cursor: "pointer",
//                                     }}
//                                     onClick={() => imgChange(1)}
//                                   />
//                                 </div>
//                                 <div>
//                                   <img
//                                     src={require("../../../../assets/images/man-1-2.jpg")}
//                                     alt="alt-same"
//                                     style={{
//                                       maxHeight: "100%",
//                                       maxWidth: "100%",
//                                       cursor: "pointer",
//                                     }}
//                                     onClick={() => imgChange(1)}
//                                   />
//                                 </div>
//                               </div>
//                             )}
//                           </>
//                         ) : (
//                           <div>
//                             <img
//                               src={require("../../../../assets/images/man-1.jpg")}
//                               alt="alt-individualcampaign"
//                               style={{ maxWidth: "100%", maxHeight: "100%" }}
//                             />
//                           </div>
//                         )}
//                       </Col>
//                     </Row>
//                     <Row>
//                       <Col md={4}>
//                         {/* <div className="img-layout">
//                       <div>
//                         <div className="auto-img-border">
//                           <div className="auto-border">Auto Campaign</div>
//                           <div className="auto-border-line"></div>
//                           <div className="auto-border">Ad Group</div>
//                         </div>
//                         <div style={{ position: "relative" }}>
//                           <div className="horizontal-line"></div>
//                           <div className="d-flex justify-content-between">
//                             <div style={{ position: "relative" }}>
//                               <div className="auto-border-item">Close</div>
//                               <div className="auto-border-doubleline"></div>
//                             </div>
//                             <div style={{ position: "relative" }}>
//                               <div className="auto-border-item">Loose</div>
//                               <div className="auto-border-doubleline"></div>
//                             </div>
//                             <div style={{ position: "relative" }}>
//                               <div className="auto-border-item">Substitute</div>
//                               <div className="auto-border-doubleline"></div>
//                             </div>
//                             <div style={{ position: "relative" }}>
//                               <div className="auto-border-item">
//                                 Complements
//                               </div>
//                               <div className="auto-border-doubleline"></div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div> */}
//                       </Col>
//                     </Row>
//                     <Row>
//                       <Col md={8}>
//                         <hr
//                           style={{
//                             border: "1px solid #E8EAED",
//                             borderWidth: "3px 0",
//                           }}
//                         />
//                       </Col>
//                     </Row>
//                     <Row>
//                       <Col md={8}>
//                         <div>
//                           <p className="placement-icon">Placement</p>
//                         </div>
//                         <div
//                           className="d-flex justify-content-between flex-wrap"
//                           style={{ gap: "20px" }}
//                         >
//                           <div style={{ flexGrow: "1" }}>
//                             <label htmlFor="basic-url" className="form-label">
//                               <span className="placement-text">
//                                 Top of search(firstPage)
//                               </span>
//                             </label>
//                             <div className="d-flex">
//                               <input
//                                 type="number"
//                                 className="custom-input"
//                                 // aria-label="Username"
//                                 placeholder="0"
//                                 name="topOfSearch"
//                                 aria-describedby="basic-addon1"
//                                 value={
//                                   this.state.CreateFormData.placement
//                                     .topOfSearch
//                                 }
//                                 onChange={(e) =>
//                                   this.placementChange("top.placement", e)
//                                 }
//                               />
//                               <span className="input-span" id="basic-addon1">
//                                 %
//                               </span>
//                             </div>
//                           </div>
//                           <div style={{ flexGrow: "1" }}>
//                             <label htmlFor="basic-url" className="form-label">
//                               <span className="placement-text">
//                                 Product detail Pages
//                               </span>
//                             </label>
//                             <div className="mb-3 d-flex">
//                               <input
//                                 type="number"
//                                 className="custom-input"
//                                 // aria-label="Username"
//                                 name="productDetailPages"
//                                 placeholder="0"
//                                 aria-describedby="basic-addon1"
//                                 value={
//                                   this.state.CreateFormData.placement
//                                     .productDetailPages
//                                 }
//                                 onChange={(e) =>
//                                   this.placementChange("product.placement", e)
//                                 }
//                               />
//                               <span className="input-span" id="basic-addon1">
//                                 %
//                               </span>
//                             </div>
//                           </div>
//                           <div style={{ flexGrow: "1" }}>
//                             <label htmlFor="basic-url" className="form-label">
//                               <span className="placement-text">
//                                 Rest of Search
//                               </span>
//                             </label>
//                             <div className="mb-3 d-flex">
//                               <input
//                                 type="number"
//                                 className="custom-input"
//                                 defaultValue={""}
//                                 // aria-label="Username"
//                                 placeholder="0"
//                                 aria-describedby="basic-addon1"
//                                 name="restOfSearch"
//                                 value={
//                                   this.state.CreateFormData.placement
//                                     .restOfSearch
//                                 }
//                                 onChange={(e) =>
//                                   this.placementChange("rest.placement", e)
//                                 }
//                               />
//                               <span className="input-span" id="basic-addon1">
//                                 %
//                               </span>
//                             </div>
//                           </div>
//                         </div>
//                       </Col>
//                     </Row>
//                     <Row>
//                       <Col md={8}>
//                         <hr
//                           style={{
//                             border: "1px solid #E8EAED",
//                             borderWidth: "3px 0",
//                           }}
//                         />
//                       </Col>
//                     </Row>
//                     <Row>
//                       <div>
//                         <p className="Setting-icon">Settings</p>
//                       </div>

//                       <Col md={8} lg={4}>
//                         <span className="placement-text">
//                           Campaign Bidding Strategy
//                         </span>
//                         <div className="setting-button flex-wrap flex-sm-nowrap">
//                           <div
//                             className={`${
//                               this.state.isSelected === "Down only"
//                                 ? "selected"
//                                 : ""
//                             }`}
//                             onClick={() =>
//                               handleOptionClick("Down only", "auto")
//                             }
//                           >
//                             Down only
//                           </div>
//                           <div
//                             className={`${
//                               this.state.isSelected === "Up & Down"
//                                 ? "selected"
//                                 : ""
//                             }`}
//                             onClick={() =>
//                               handleOptionClick("Up & Down", "auto")
//                             }
//                           >
//                             Up & Down
//                           </div>

//                           <div
//                             className={`${
//                               this.state.isSelected === "Fixed"
//                                 ? "selected"
//                                 : ""
//                             }`}
//                             onClick={() => handleOptionClick("Fixed", "auto")}
//                           >
//                             Fixed
//                           </div>
//                         </div>
//                         {!this.state.isError.campaignBiddingStrategy && (
//                           <span className="text-danger">*requried</span>
//                         )}
//                         <div className="mt-3">
//                           <label htmlFor="basic-url" className="form-label">
//                             <span className="placement-text">Portfolio</span>
//                           </label>
//                           <select
//                             className="form-select"
//                             name="portfolio"
//                             // aria-label="Default select example"
//                             // style={{ maxWidth: "330px" }}
//                             onChange={this.settingChange}
//                             value={this.state.CreateFormData.settings.portfolio}
//                           >
//                             <option value="">Choose Portfolio</option>
//                             {this.state.portfolioData?.map((list: any, i) => (
//                               <option value={list.portfolioId} key={i}>
//                                 {list.portfolioName}
//                               </option>
//                             ))}
//                           </select>
//                         </div>
//                         {!this.state.isError.portfolio && (
//                           <span className="text-danger">*requried</span>
//                         )}
//                       </Col>
//                       <Col md={8} lg={4}>
//                         <label htmlFor="basic-url" className="form-label">
//                           <span className="placement-text">Daily Budget</span>
//                         </label>
//                         <div className="daily-budget">
//                           <span className="input-span-daily" id="basic-addon1">
//                             $
//                           </span>
//                           <input
//                             type="number"
//                             required
//                             name="dailyBudget"
//                             className="custom-input-daily"
//                             placeholder="0"
//                             aria-describedby="basic-addon1"
//                             value={
//                               this.state.CreateFormData.settings.dailyBudget
//                             }
//                             onChange={this.settingChange}
//                           />
//                         </div>
//                         {!this.state.isError.dailyBudget && (
//                           <span className="text-danger">*requried</span>
//                         )}
//                         <div className="Daily-Budget-dates mt-3">
//                           <div>
//                             <label htmlFor="basic-url" className="form-label">
//                               <span className="placement-text"> Start</span>
//                             </label>
//                             <br />
//                             <DatePicker
//                               className="date-picker-autocampign"
//                               selected={this.state.startDate}
//                               onChange={(e) => {
//                                 this.handleChangeDate("start", e);
//                               }}
//                             />
//                           </div>
//                           <div>
//                             <label htmlFor="basic-url" className="form-label">
//                               <span className="placement-text">End</span>
//                             </label>
//                             <br />
//                             <DatePicker
//                               className="date-picker-autocampign"
//                               selected={this.state.EndDate}
//                               onChange={(e) => {
//                                 this.handleChangeDate("end", e);
//                               }}
//                             />
//                           </div>
//                         </div>
//                       </Col>
//                     </Row>
//                     <Row>
//                       <Col md={8}>
//                         <hr
//                           style={{
//                             border: "1px solid #E8EAED",
//                             borderWidth: "3px 0",
//                           }}
//                         />
//                       </Col>
//                     </Row>
//                     <Row>
//                       <div>
//                         <p className="marketplace-icon">Select Marketplaces</p>
//                       </div>
//                       {!this.state.isError.marketPlaces && (
//                         <span className="text-danger">*requried</span>
//                       )}
//                       <Col md={8}>
//                         <div className="marketplaces-id">{marketplaces}</div>
//                       </Col>
//                     </Row>
//                     <Row>
//                       <Col md={8}>
//                         <hr
//                           style={{
//                             border: "1px solid #E8EAED",
//                             borderWidth: "3px 0",
//                           }}
//                         />
//                       </Col>
//                     </Row>
//                     <Row>
//                       <div>
//                         <p className="Targeting-icon">Targeting</p>
//                       </div>
//                       <Col md={8}>
//                         <Tabs
//                           defaultActiveKey="KeywordsTargeting"
//                           onSelect={handleTabSelect}
//                         >
//                           <Tab
//                             eventKey="KeywordsTargeting"
//                             title="Keywords Targeting"
//                           >
//                             <Container>
//                               <Row>
//                                 <Col md={12}>
//                                   <div className="my-3">
//                                     <div className="form-check form-check-inline">
//                                       <input
//                                         className="form-check-input"
//                                         type="checkbox"
//                                         placeholder="Add Keyword"
//                                         name="Broad"
//                                         id="inlineRadio3"
//                                         value="Broad"
//                                         onChange={(e) => this.addKeyWordType(e)}
//                                         checked={this.state.adKeywordType.some(
//                                           (item) => item === "Broad"
//                                         )}
//                                       />
//                                       <label
//                                         className="form-check-label"
//                                         htmlFor="inlineRadio3"
//                                       >
//                                         Broad
//                                       </label>
//                                     </div>
//                                     <div className="form-check form-check-inline">
//                                       <input
//                                         className="form-check-input"
//                                         type="checkbox"
//                                         name="Phrase"
//                                         id="inlineRadio4"
//                                         value="Phrase"
//                                         checked={this.state.adKeywordType.some(
//                                           (item) => item === "Phrase"
//                                         )}
//                                         onChange={(e) => this.addKeyWordType(e)}
//                                       />
//                                       <label
//                                         className="form-check-label"
//                                         htmlFor="inlineRadio4"
//                                       >
//                                         Phrase
//                                       </label>
//                                     </div>
//                                     <div className="form-check form-check-inline">
//                                       <input
//                                         className="form-check-input"
//                                         type="checkbox"
//                                         name="Exact"
//                                         id="inlineRadio4"
//                                         value="Exact"
//                                         checked={this.state.adKeywordType.some(
//                                           (item) => item === "Exact"
//                                         )}
//                                         onChange={(e) => this.addKeyWordType(e)}
//                                       />
//                                       <label
//                                         className="form-check-label"
//                                         htmlFor="inlineRadio4"
//                                       >
//                                         Exact
//                                       </label>
//                                     </div>
//                                   </div>
//                                   <div className="add-keyword-container">
//                                     <textarea
//                                       name=""
//                                       placeholder="Enter keywords separated by new line."
//                                       id="addKeywordsText"
//                                       className="form-control mb-3 input-box-control-auto"
//                                       onChange={(e) =>
//                                         this.addTempAdKeywords(e)
//                                       }
//                                       style={{ width: "100%" }}
//                                       value={this.state.adKeywordsTemp}
//                                     ></textarea>
//                                     <button
//                                       type="button"
//                                       className="btn btn-outline mb-2 add-keyword-btn"
//                                       onClick={(e) => this.adkeyWordSubmit()}
//                                       disabled={!this.state.adKeywordType}
//                                       style={{
//                                         background: "#00FF00",
//                                       }}
//                                     >
//                                       Add Keyword
//                                     </button>
//                                   </div>
//                                 </Col>
//                               </Row>
//                               <Row>
//                                 <Col md={12}>
//                                   <div
//                                     className="create-campaing-form table-responsive border"
//                                     style={{ width: "100%" }}
//                                   >
//                                     <table className="table">
//                                       <thead>
//                                         <tr>
//                                           <th>Keyword</th>
//                                           <th>Match Type</th>
//                                           <th></th>
//                                         </tr>
//                                       </thead>
//                                       <tbody>
//                                         {this.state.adKeywords?.map(
//                                           (adKeyword: any, i) => (
//                                             <tr>
//                                               <td>{adKeyword.targetText}</td>
//                                               <td>
//                                                 {this.state.isTargetType ===
//                                                 "KeywordsTargeting"
//                                                   ? adKeyword.matchtype?.join(
//                                                       ","
//                                                     )
//                                                   : adKeyword.matchtype}
//                                               </td>
//                                               <td className="text-center more-action">
//                                                 <i
//                                                   onClick={() =>
//                                                     this.removeFormFields(i)
//                                                   }
//                                                 >
//                                                   <img
//                                                     src={DeleteIcon}
//                                                     alt=""
//                                                   />
//                                                 </i>
//                                               </td>
//                                             </tr>
//                                           )
//                                         )}
//                                       </tbody>
//                                     </table>
//                                   </div>
//                                 </Col>
//                               </Row>
//                               <Row>
//                                 <Col
//                                   md={12}
//                                   className="d-flex justify-content-between my-4 0 keyword-added"
//                                 >
//                                   <p>
//                                     {this.state.adKeywords?.length} keyword
//                                     added
//                                   </p>
//                                   <span
//                                     className="text-end remove-all-link"
//                                     onClick={this.removeAllAdkeyWords}
//                                   >
//                                     Remove All
//                                   </span>
//                                 </Col>
//                               </Row>
//                             </Container>
//                           </Tab>
//                           <Tab
//                             eventKey="ProductTargeting"
//                             title="Product Targeting"
//                           >
//                             <Container>
//                               <Row>
//                                 <Col md={12}>
//                                   <div className="mt-2">
//                                     <h6 className="target-types">
//                                       Targeting Types
//                                     </h6>
//                                   </div>
//                                   <div className="my-3">
//                                     <div className="form-check form-check-inline">
//                                       <input
//                                         className="form-check-input"
//                                         type="radio"
//                                         placeholder="Add Keyword"
//                                         name="negativeKeyword"
//                                         id="inlineRadio3"
//                                         value="product"
//                                         onChange={(e) =>
//                                           this.addKeyWordTypeManual(e)
//                                         }
//                                       />
//                                       <label
//                                         className="form-check-label"
//                                         htmlFor="inlineRadio3"
//                                       >
//                                         Product
//                                       </label>
//                                     </div>
//                                     <div className="form-check form-check-inline">
//                                       <input
//                                         className="form-check-input"
//                                         type="radio"
//                                         name="negativeKeyword"
//                                         id="inlineRadio4"
//                                         value="category"
//                                         onChange={(e) =>
//                                           this.addKeyWordTypeManual(e)
//                                         }
//                                       />
//                                       <label
//                                         className="form-check-label"
//                                         htmlFor="inlineRadio4"
//                                       >
//                                         Category
//                                       </label>
//                                     </div>
//                                   </div>
//                                   <div className="add-keyword-container">
//                                     <textarea
//                                       name=""
//                                       id="addKeywordsText"
//                                       placeholder="Enter ASINs separated by a comma, space, or new line."
//                                       className="form-control mb-3 input-box-control-auto"
//                                       onChange={(e) =>
//                                         this.addTempAdKeywords(e)
//                                       }
//                                       style={{ width: "100%" }}
//                                       value={this.state.adKeywordsTemp}
//                                     ></textarea>
//                                     <button
//                                       type="button"
//                                       className="btn btn-outline mb-2 add-keyword-btn"
//                                       onClick={(e) => this.adkeyWordSubmit()}
//                                       disabled={!this.state.adKeywordType}
//                                       style={{
//                                         background: "#00FF00",
//                                       }}
//                                     >
//                                       Add Keyword
//                                     </button>
//                                   </div>
//                                 </Col>
//                               </Row>
//                               <Row>
//                                 <Col md={12}>
//                                   <div
//                                     className="create-campaing-form table-responsive border"
//                                     style={{ width: "100%" }}
//                                   >
//                                     <table className="table">
//                                       <thead>
//                                         <tr>
//                                           <th>Keyword</th>
//                                           <th>Match Type</th>
//                                           <th></th>
//                                         </tr>
//                                       </thead>
//                                       <tbody>
//                                         {this.state.adKeywords?.map(
//                                           (adKeyword: any, i) => (
//                                             <tr>
//                                               <td>{adKeyword.targetText}</td>
//                                               <td>
//                                                 {this.state.isTargetType ===
//                                                 "KeywordsTargeting"
//                                                   ? adKeyword.matchtype?.join(
//                                                       ","
//                                                     )
//                                                   : adKeyword.matchtype}
//                                               </td>
//                                               <td className="text-center more-action">
//                                                 <i
//                                                   onClick={() =>
//                                                     this.removeFormFields(i)
//                                                   }
//                                                 >
//                                                   <img
//                                                     src={DeleteIcon}
//                                                     alt=""
//                                                   />
//                                                 </i>
//                                               </td>
//                                             </tr>
//                                           )
//                                         )}
//                                       </tbody>
//                                     </table>
//                                   </div>
//                                 </Col>
//                               </Row>
//                               <Row>
//                                 <Col
//                                   md={12}
//                                   className="d-flex justify-content-between my-4 0 keyword-added"
//                                 >
//                                   <p>
//                                     {this.state.adKeywords?.length} keyword
//                                     added
//                                   </p>
//                                   <span
//                                     className="text-end remove-all-link"
//                                     onClick={this.removeAllAdkeyWords}
//                                   >
//                                     Remove All
//                                   </span>
//                                 </Col>
//                               </Row>
//                             </Container>
//                           </Tab>
//                         </Tabs>
//                       </Col>
//                     </Row>
//                     <Row>
//                       <Col md={8}>
//                         <hr
//                           style={{
//                             border: "1px solid #E8EAED",
//                             borderWidth: "3px 0",
//                           }}
//                         />
//                       </Col>
//                     </Row>
//                     {/* <Row>
//                   <div>
//                     <h6>Add Group(s)</h6>
//                   </div>
//                   <Col md={8}>
//                     <form>
//                       <input
//                         type="text"
//                         className="form-control"
//                         defaultValue={""}
//                         aria-label="Username"
//                         placeholder="enter ad group name"
//                         aria-describedby="basic-addon1"
//                       />
//                     </form>
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col className="my-3">
//                     <div className="add-gropu-btn">Ad Group</div>
//                   </Col>
//                 </Row> */}
//                     <Row>
//                       <Col md={8}>
//                         <form>
//                           <div
//                             className="d-flex justify-content-between flex-wrap"
//                             style={{ columnGap: "10px" }}
//                           >
//                             <div style={{ flexGrow: "1" }}>
//                               <div>
//                                 <h6 className="custom-input-text">
//                                   Targeting Bid (X)
//                                 </h6>
//                               </div>
//                               <div>
//                                 <input
//                                   required
//                                   type="number"
//                                   name="targetingBid"
//                                   className="form-control"
//                                   value={
//                                     this.state.CreateFormData.dynamicBidding
//                                       .targetingBid
//                                   }
//                                   // aria-label="Username"
//                                   placeholder="0"
//                                   aria-describedby="basic-addon1"
//                                   onChange={keywordChange}
//                                 />
//                               </div>
//                               {!this.state.isError.targetingBid && (
//                                 <span className="text-danger">*requried</span>
//                               )}
//                             </div>
//                             <div style={{ flexGrow: "1" }}>
//                               <div>
//                                 <h6 className="custom-input-text">
//                                   Default Bid
//                                 </h6>
//                               </div>
//                               <div>
//                                 <input
//                                   required
//                                   name="defaultBid"
//                                   type="number"
//                                   className="form-control"
//                                   value={
//                                     this.state.CreateFormData.dynamicBidding
//                                       .defaultBid
//                                   }
//                                   // aria-label="Username"
//                                   placeholder="0"
//                                   aria-describedby="basic-addon1"
//                                   onChange={keywordChange}
//                                 />
//                               </div>
//                               {!this.state.isError.defaultBid && (
//                                 <span className="text-danger">*requried</span>
//                               )}
//                             </div>
//                           </div>
//                         </form>
//                       </Col>
//                     </Row>
//                     <Row className="my-3">
//                       <Col md={8}>
//                         <div className="d-flex justify-content-between align-items-center">
//                           <h6 className="custom-input-text">Match Types</h6>
//                           <span
//                             className="text-end remove-all-link"
//                             id="selectAll"
//                             // checked={this.state.matchTypesList.every(
//                             //   (item: any) => item.isChecked
//                             // )}
//                             onClick={(e) => {
//                               selectedAllAdkeyWords(e);
//                             }}
//                           >
//                             {this.state.matchTypesList.some(
//                               (item: any) => !item.isChecked
//                             )
//                               ? "Select All"
//                               : "Deselect All"}
//                           </span>
//                         </div>
//                       </Col>
//                       <Col md={8} className="my-3">
//                         <form>
//                           <div
//                             className="justify-content-between d-flex flex-wrap flex-sm-nowrap"
//                             style={{ gap: "10px" }}
//                           >
//                             {this.state.matchTypesList.map((item, index) => {
//                               return (
//                                 <div style={{ flexGrow: "1" }}>
//                                   <div className="form-check form-check-inline">
//                                     <input
//                                       className="form-check-input"
//                                       type="checkbox"
//                                       id={`checkbox-${item.id}`}
//                                       value="Negative exact"
//                                       checked={item.isChecked}
//                                       onChange={(e) => {
//                                         keywordChangeHandler(item, e);
//                                       }}
//                                     />
//                                     <label
//                                       className="form-check-label custom-input-text"
//                                       htmlFor="inlineRadio3"
//                                     >
//                                       {item.title}
//                                     </label>
//                                   </div>
//                                   <div className="d-flex">
//                                     <input
//                                       className="custom-input"
//                                       value={item.name}
//                                       // defaultValue={item.name ? item.name : 0}
//                                       name={item.nameid}
//                                       placeholder="0"
//                                       aria-describedby="basic-addon1"
//                                       onChange={keywordChangedynamic}
//                                     />
//                                     <span
//                                       className="input-span"
//                                       id="basic-addon1"
//                                       onClick={(e) =>
//                                         removematchtypeHandler(item, e)
//                                       }
//                                     >
//                                       X
//                                     </span>
//                                   </div>
//                                 </div>
//                               );
//                             })}

//                             {/* <div style={{ flexGrow: "1" }}>
//                           <div className="form-check form-check-inline">
//                             <input
//                               className="form-check-input"
//                               type="checkbox"
//                               id="inlineRadio3"
//                               value="Negative exact"
//                             />
//                             <label
//                               className="form-check-label custom-input-text"
//                               htmlFor="inlineRadio3"
//                             >
//                               Loose
//                             </label>
//                           </div>
//                           <div className="d-flex">
//                             <input
//                               type="text"
//                               className="custom-input"
//                               defaultValue={""}
//                               name="loose"
//                               placeholder="0"
//                               aria-describedby="basic-addon1"
//                               onChange={keywordChange}
//                             />
//                             <span className="input-span" id="basic-addon1">
//                               X
//                             </span>
//                           </div>
//                         </div>
//                         <div style={{ flexGrow: "1" }}>
//                           <div className="form-check form-check-inline">
//                             <input
//                               className="form-check-input"
//                               type="checkbox"
//                               id="inlineRadio3"
//                               value="Negative exact"
//                             />
//                             <label
//                               className="form-check-label custom-input-text"
//                               htmlFor="inlineRadio3"
//                             >
//                               Substitutes
//                             </label>
//                           </div>
//                           <div className="d-flex">
//                             <input
//                               type="text"
//                               className="custom-input"
//                               defaultValue={""}
//                               name="substitutes"
//                               placeholder="0"
//                               aria-describedby="basic-addon1"
//                               onChange={keywordChange}
//                             />
//                             <span className="input-span" id="basic-addon1">
//                               X
//                             </span>
//                           </div>
//                         </div>
//                         <div style={{ flexGrow: "1" }}>
//                           <div className="form-check form-check-inline">
//                             <input
//                               className="form-check-input"
//                               type="checkbox"
//                               id="inlineRadio3"
//                               value="Negative exact"
//                             />
//                             <label
//                               className="form-check-label custom-input-text"
//                               htmlFor="inlineRadio3"
//                             >
//                               Complements
//                             </label>
//                           </div>
//                           <div className="d-flex">
//                             <input
//                               type="text"
//                               className="custom-input"
//                               defaultValue={""}
//                               name="complements"
//                               placeholder="0"
//                               aria-describedby="basic-addon1"
//                               onChange={keywordChange}
//                             />
//                             <span className="input-span" id="basic-addon1">
//                               X
//                             </span>
//                           </div>
//                         </div> */}
//                           </div>
//                         </form>
//                       </Col>
//                     </Row>
//                   </Container>
//                   <div className="form-button-group">
//                     <Link
//                       to={"/ads/ads-creation"}
//                       className="btn btn-outline-primary"
//                     >
//                       Back
//                     </Link>
//                     <button className="btn btn-primary" onClick={checkHandler}>
//                       Next
//                     </button>
//                   </div>
//                 </Form>
//               </Tab>
//             </Tabs>
//           </div>
//         </DashboardLayout>
//       </>
//     );
//   }
// }

// export default CreateManualCampaign;
import React, { Component } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import { Col, Row, Container } from "react-bootstrap";
import ManualCampaign from "./campaigns/manualCampaign";
import LastAppSync from "../lastAppSync";

class CreateManualCampaign extends Component {
  render() {
    return (
      <DashboardLayout>
        <LastAppSync />
        <div className="main-content-container">
          <hr />
          <Container fluid>
            <Row>
              <Col>
                <Tabs defaultActiveKey="ManualCampaign" className="ms-1">
                  <Tab eventKey="AutoCampaign" title="Auto Campaign" disabled>
                    AutoCampaign
                  </Tab>
                  <Tab eventKey="ManualCampaign" title="Manual Campaign">
                    <ManualCampaign />
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </div>
      </DashboardLayout>
    );
  }
}

export default CreateManualCampaign;
