/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import "../index.css";

import { Link } from "react-router-dom";
import { event } from "jquery";

function AddRules(props) {
  let loadPanel = (panelName, event) => {
    props.parentCallback(panelName);
    event.preventDefault();
  }
  return (
    <>
      <div className="add-rule-container">
        <Row>
          <Col>
            <h4>Add Rule</h4>
            <p>You can choose rule from our template below or you can also create your custom rule.</p>
          </Col>
        </Row>
        <Row>
          <h5>Our template</h5>
          <Col md={4}>
            <div className="rule-box">
              <i className="radio-icon"></i>
              <div className="box-header"><i className="fa-solid fa-ellipsis-vertical"></i> Increase Visibility</div>
              <div className="box-body">
                <form>
                <select className="form-select" defaultValue={""} aria-label="Default select example">
                  <option value="">--select--</option>
                  <option value="Gentle approach">Gentle approach</option>
                </select>
                </form>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="rule-box">
              <i className="radio-icon"></i>
              <div className="box-header"><i className="fa-solid fa-ellipsis-vertical"></i> Increase ROI</div>
              <div className="box-body">
                <form>
                <select className="form-select" defaultValue={""} aria-label="Default select example">
                  <option value="">--select--</option>
                  <option value="Gentle approach">Gentle approach</option>
                </select>
                </form>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="rule-box">
              <i className="radio-icon"></i>
              <div className="box-header"><i className="fa-solid fa-ellipsis-vertical"></i> Negative Targets</div>
              <div className="box-body">
                <form>
                <select className="form-select" defaultValue={""} aria-label="Default select example">
                  <option value="">--select--</option>
                  <option value="Gentle approach">Gentle approach</option>
                </select>
                </form>
              </div>
            </div>
          </Col>
          <h5 className="mt-3">Or you can create your own rule</h5>
          <Col md={4}>
            <div className="rule-box" onClick={(eve) => loadPanel('AddNewRules', eve)}>
              <i className="radio-icon"></i>
              <div className="box-header">Create Your Own Rule</div>
              <div className="box-body p-0">
                <p>
                  You can create your own rules according to what you want.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddRules;