import React, { useState, useEffect } from 'react';
function ColumnFilter(props) {
  const [List, setList] = useState<any>([]);
  const [searchKeyValue, setSearchKeyValue] = useState<any[]>([]);
  const [selectAllchecked, setSelectAllChecked] = useState(true);

  useEffect(() => {
    console.log("column dropdown columnList: ", props.columnList);
    setList(props.columnList);
    const listData = props.columnList;
    const colList = listData.map((item:any) => item.keyName);
    setSearchKeyValue(colList);
  },[props.columnList]);

  useEffect(() => {
    props.hideColumn(searchKeyValue);
  },[searchKeyValue]);

  // Select/ UnSelect Table rows
  function onMasterCheck(e) {
    setSelectAllChecked(e.target.checked);
      let checkAll = List.map((item) => ({
      ...item,
      checked: e.target.checked,
    }))
    //Update State
    setList(checkAll);
    if (e.target.checked) {
      const allKeys = checkAll.map((item) => item.keyName);
      setSearchKeyValue(allKeys);
    } else {
        setSearchKeyValue([]);
    }
    
  }

  // Update List Item's state and Master Checkbox State
  function onItemCheck(i, e) {
    let tempList = List;
    tempList[i].checked = e.target.checked;
    setList(tempList);

    if (e.target.checked === true) {
      setSearchKeyValue((current) => [...current, e.target.name]);
    }

    if (e.target.checked === false) {
        setSelectAllChecked(e.target.checked);
        setSearchKeyValue((current) =>
            current.filter((item) => item !== e.target.name)
        );
    }
  }

  return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectAllchecked}
                  id="mastercheck"
                  onChange={(e) => onMasterCheck(e)}
                />
              </th>
              <th scope="col">Column Name</th>
            </tr>
          </thead>
          <tbody>
            {List.map((columnFilter:any, i) => (
              <tr key={columnFilter.id} className={columnFilter.selected ? "selected" : ""}>
                <th scope="row">
                  <input
                      type="checkbox"
                      checked={columnFilter.checked}
                      className="form-check-input Selected-filter"
                      onChange={(e) =>
                          onItemCheck(i, e)
                      }
                      name={columnFilter.keyName}
                      defaultChecked={true}
                  />
                </th>
                <td>{columnFilter.displayName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
}
export default ColumnFilter;