import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import "./bestSeller.css";
import LogoIcon from "../../../assets/brand/logo-icon.svg";
import Image1 from "../../../assets/images/Image-1.png";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import InfoButton from "../../../components/Common/InfoButton";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import DashboardService from "../../../services/DashboardService";

function BestSeller({ filters }) {
    const [bestSellerData, setBestSellerData] = useState([]);
    const [apiLoading, setApiLoading] = useState(false);
    const [lastPage, setLastPage] = useState(1);
    const [currPage ,setcurrPage] = useState(1);

    useEffect(() => {
        getBestSellerData();
    }, [filters]);

    const getBestSellerData = async () => {
        setApiLoading(true);
        let request = {
            globalfilter: filters,
            pageNumber: 1,
            PageSize: 4
        };
        const responseData = await DashboardService.getBestSellerData(request);
        setBestSellerData(responseData);
        setApiLoading(false);
    };

    const handleChange = (event, value) => {
        // setcurrPage(value);
    };

    return  (
        <div className="widget-container px-2">
            <Row>
                <Col>
                    <div className="top-seller-title">
                        Top Bestseller Book
                        <div className="dropdown">
                            <select
                                className="dropdown-content"
                                defaultValue={"DEFAULT"}
                            >
                                <option
                                    value="DEFAULT"
                                    style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                        whiteSpace: "nowrap", // Ensure the span stays on the same line
                                    }}
                                >
                                    Royalties
                                    <span className="gray-text-m"> [Total Net] </span>
                                </option>
                            </select>
                            <div className="info icon">
                                <InfoButton infoText="Click through rate" />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <div>
                {!apiLoading ? (
                    <>
                        {
                            bestSellerData &&
                            <Row>
                                {bestSellerData.map((list: any, i) => (
                                    <Col>
                                        <div className="top-seller-widget mt-2" key={i}>
                                            <Row>
                                                <Col>
                                                    {list.bookCover == null ? (
                                                        <img src={Image1} alt="image1" />
                                                    ) : (
                                                        <img src={list.bookCover} alt="image1" />
                                                    )}
                                                </Col>
                                                <Col className="p-0">
                                                    <span className="tag-info">#{i + 1} Bestseller</span>

                                                    <Tooltip
                                                        placement="bottom-start"
                                                        title={
                                                            <React.Fragment>
                                                                <p>{list.bookName}</p>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <div>

                                                            <div className="bestproduct-column-titles">
                                                                {list.bookName}
                                                            </div>
                                                        </div>
                                                    </Tooltip>



                                                    {list.grossRoyalties == null ? (
                                                        <p>Gross Royalties:$0</p>
                                                    ) : (
                                                        <p>Gross Royalties:${list.grossRoyalties}</p>
                                                    )}
                                                    {list.spending == null ? (
                                                        <p> Spending:$0</p>
                                                    ) : (
                                                        <p> Spending:${list.spending}</p>
                                                    )}
                                                    {list.netRoyalties == null ? (
                                                        <p>Net Royalties:$0</p>
                                                    ) : (
                                                        <p>Net Royalties:${list.netRoyalties}</p>
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                ))}
                                <div className="custom-table-footer">
                                    <Row>
                                        <Col md={12}>
                                            <div className="table-footer-right">
                                                <Stack spacing={2}>
                                                    <Pagination
                                                        count={lastPage}
                                                        variant="outlined"
                                                        shape="rounded"
                                                        onChange={handleChange}
                                                    />
                                                </Stack>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Row>

                        }
                    </>
                ) : (
                    <div className="loading-container bestSellerLoading mb-2">
                        <div className="loading-text">
                            <span className="logicon">
                                <img src={LogoIcon} alt="logo-small"></img>
                            </span>
                            <span>L</span>
                            <span>O</span>
                            <span>A</span>
                            <span>D</span>
                            <span>I</span>
                            <span>N</span>
                            <span>G</span>
                        </div>
                    </div>
                )}
            </div>
        </div>

    );
}

export default BestSeller;