import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import InfoButton from "../Common/InfoButton";
import MoreActionIcon from "../../assets/images/icons/more-action-icon.svg";
import DashboardService from "../../services/DashboardService";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import Chart from "react-apexcharts";
import DynamicWidthDropdown from "../Common/DynamicWidthDropdown/DynamicWidthDropdown";
import NumberFormatter from "../Common/NumberFormatter";
import MasterDataService from "../../services/MasterDataService";

const Metrics = ({
  defaultData,
  defaultMetric,
  useTemplate,
  canSwitch,
  filters,
  defaultChange,
}) => {
  const [loading, setLoading] = useState(true);
  const currencyCode = localStorage.getItem("currencyCode");
  const spinnerMarginTopValue =
    useTemplate === "Plain_ValueGrowth" ? "12px" : "70px";

  function setChartDataAndOptions(metricData) {
    let series = [];
    let categories = [];
    console.log(metricData.subTitle);
    console.log(metricData);
    if (metricData && metricData.data) {
      const title =
        metricData.title +
        (metricData.subTitle ? `[${metricData.subTitle}]` : "");
      if (metricData.data.length > 0) {
        const sortedData = [...metricData.data].sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        categories = sortedData.map((item) => {
          const dateObject = new Date(item.date);
          const formattedDate = dateObject.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
          });
          return formattedDate;
        });
        // categories = sortedData.map((item) => item.date);
        series.push({
          name: title,
          type: "area",
          data: sortedData.map((item) => {
            var value = item.value.toFixed(2);
         return value;
        }),
        });
      } else {
        series.push({ name: title, type: "area", data: [] });
      }
    } else {
      series.push({ name: selectedMetric, type: "area", data: [] });
    }

    const seriesColors = [
      "#00D26E",
      "#374151",
      "#008FFB", // Blue
      "#00E396", // Green
      "#FEB019", // Yellow
      "#FF4560", // Red
      "#775DD0", // Purple
      "#546E7A", // Grey
      "#26a69a", // Teal
      "#D10CE8", // Pink
      "#6610F2", // Indigo
      "#FFD700", // Gold
    ];

    let result = {
      options: {
        chart: {
          id: "area_chart",
          toolbar: { show: false },
        },
        xaxis: {
          categories: categories,
          tickAmount: 8,
          tickPlacement: "on",
          labels: { show: false },
          legend: { show: false },
        },
        yaxis: {
          show: false, // Set this to false to hide the y-axis legend
        },
        //yaxis: yaxis,
        stroke: {
          curve: "straight",
          width: 1,
        },
        dataLabels: { enabled: false },
        colors: seriesColors,
      },
      series: series,
    };
    setChartSeries(series);
    setChartOptions(result.options);
    //return result;
  }

  const [metaMetricList, setMetaMetricList] = useState([]);
  const [metricsList, setMetricsList] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState(defaultMetric);
  const [isvalue, setvalue] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [defaultMetricData, setDefaultMetricData] = useState({});
  const [selectedMetricData, setSelectedMetricData] = useState({
    name: "",
    unit: "",
    description: "",
    value: 0.0,
    average: 0.0,
    sum: 0.0,
    min: 0.0,
    max: 0.0,
    growthRate: 0.0,
    data: [],
  });

  function getMetricByName(metricsArray, name) {
    return metricsArray.find((metric) => metric.name === name);
  }

  const fetchMetaData = async () => {
    try {
      const metaData = await MasterDataService.getMetaData();
      if (metaData.Metric) {
        setMetaMetricList(metaData.Metric);
        if (!canSwitch) {
          const metricData = getMetricByName(metaData.Metric, defaultMetric);
          setDefaultMetricData(metricData);
        }
      }
    } catch (error) {
      console.error("Error fetching metrics list:", error);
    } finally {
    }
  };
  // Simulate fetching the metrics list
  // Replace this with your actual API call logic
  const fetchMetricsList = async () => {
    try {
      var request = {
        globalFilters: filters,
        metricName: "",
      };
      const fetchedMetricsList = await DashboardService.getMetricsData(request);
      if (fetchedMetricsList) {
        setMetricsList(fetchedMetricsList);
      }
    } catch (error) {
      console.error("Error fetching metrics list:", error);
    } finally {
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchMetaData();
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchMetricsList();
  }, [metaMetricList, filters]);

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [selectedMetric, metricsList]);

  // Simulate fetching data based on the selected metric
  // Replace this with your actual API call logic
  const fetchData = async () => {
    try {
      // Simulate an API response with metric data
      const metricData = getMetricByName(metricsList, selectedMetric);
      if (metricData) {
        setSelectedMetricData(metricData);
        if (useTemplate === "Line_ValueGrowth") {
          setChartDataAndOptions(metricData);
        }
      }
    } catch (error) {
      console.error("Error fetching metric data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleMetricChange = (event, options, i) => {
    defaultChange(event.target.value, defaultData);
    setSelectedMetric(event.target.value);
  };

  console.log(isvalue);
  return (
    <>
      <div
        className={
          useTemplate === "Plain_ValueGrowth"
            ? "widget-container height-110"
            : "widget-container height-220"
        }
      >
        <div className="widget-header">
          <Row>
            <Col md={10} className="widget-select-container">
              <div className="input-group filter d-flex align-items-baseline">
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    display: "inline-flex",
                  }}
                >
                  {!canSwitch && defaultMetricData && (
                    <>
                      <div
                        style={{
                          color: "#111827",
                          fontSize: "14px",
                          fontFamily: "Plus Jakarta Sans",
                          fontWeight: "600",
                          lineHeight: "21px",
                          wordWrap: "break-word",
                          paddingRight: "10px",
                        }}
                      >
                        {defaultMetricData.title}
                      </div>
                      {defaultMetricData.subTitle && (
                        <span className="gray-text-m">
                          [{defaultMetricData.subTitle}]
                        </span>
                      )}
                      <InfoButton infoText={defaultMetricData.description} />
                    </>
                  )}
                  {metaMetricList.length > 0 && canSwitch && (
                    <DynamicWidthDropdown
                      options={metaMetricList}
                      selectedItem={selectedMetric}
                      defaultMetric={defaultMetric}
                      handleSelectChange={handleMetricChange}
                    ></DynamicWidthDropdown>
                  )}
                </div>
              </div>
            </Col>
            <Col md={2} className="p-0">
              <div className="more-action-icon">
                <img src={MoreActionIcon} alt="info circle icon" />
              </div>
            </Col>
          </Row>
        </div>
        {loading ? (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              height: "100%",
              marginTop: spinnerMarginTopValue,
            }}
            className="horizontalBarChart"
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <br></br>
            <div className="loading ms-4">Loading...</div>
          </div>
        ) : (
          <div>
            {useTemplate === "Plain_ValueGrowth" && (
              <div className="widget-footer">
                <Row>
                  <Col md={7}>
                    <div className="widget-footer-title">
                      {selectedMetricData &&
                        (!isNaN(parseFloat(selectedMetricData.value)) ? (
                          <p>
                            {selectedMetricData.unit === "money" ? (
                              <>
                                <NumberFormatter
                                  currencyType={currencyCode}
                                  value={parseFloat(
                                    selectedMetricData.value
                                  ).toFixed(2)}
                                ></NumberFormatter>
                              </>
                            ) : selectedMetricData.unit === "percentage" ? (
                              `${parseFloat(selectedMetricData.value).toFixed(
                                1
                              )}%`
                            ) : (
                              <NumberFormatter
                                value={parseInt(selectedMetricData.value)}
                              ></NumberFormatter>
                            )}
                          </p>
                        ) : (
                          <p className="noData">no data</p>
                        ))}
                      {!selectedMetricData && <p>0.0%</p>}
                    </div>
                  </Col>
                  <Col md={5} className="align-end">
                    <div
                      className={
                        selectedMetricData &&
                        parseFloat(selectedMetricData.growthRate) >= 0.0
                          ? "widget-status-container up"
                          : "widget-status-container down"
                      }
                    >
                      {selectedMetricData &&
                        (!isNaN(parseFloat(selectedMetricData.growthRate)) ? (
                          <p>
                            {Math.abs(
                              parseFloat(selectedMetricData.growthRate)
                            ).toFixed(1)}
                            %
                          </p>
                        ) : (
                          <p className="noData">no data</p>
                        ))}
                      {!selectedMetricData && <p>0.0%</p>}
                    </div>
                  </Col>
                </Row>
              </div>
            )}
            {useTemplate === "Donut_ValueMinMaxGrowth" && (
              <div>
                <div
                  className="widget-body"
                  style={{
                    marginTop: "20px",
                    marginBottom: "10px",
                    minHeight: "90px",
                  }}
                >
                  <div>
                    {selectedMetricData && (
                      <div>
                        <SemiCircleProgressBar
                          percentage={selectedMetricData.average}
                          showPercentValue
                          diameter={115}
                          stroke={"#00D26E"}
                          strokeWidth={8}
                        />
                        <div>
                          <p className="widget-p-sub-text">
                            {selectedMetricData.max}/{selectedMetricData.min}
                          </p>
                        </div>
                      </div>
                    )}
                    {!selectedMetricData && (
                      <div>
                        <SemiCircleProgressBar
                          percentage="0"
                          showPercentValue
                          diameter={115}
                          stroke={"#00D26E"}
                          strokeWidth={8}
                        />
                        <div>
                          <p className="widget-p-sub-text">0/0</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="widget-footer">
                  <Row>
                    <Col md={12}>
                      <div
                        className={
                          selectedMetricData &&
                          parseFloat(selectedMetricData.growthRate) < 0.0
                            ? "widget-status-container down"
                            : "widget-status-container up"
                        }
                      >
                        {selectedMetricData &&
                          (!isNaN(parseFloat(selectedMetricData.growthRate)) ? (
                            <p>
                              {Math.abs(
                                parseFloat(selectedMetricData.growthRate)
                              )}
                              %
                            </p>
                          ) : (
                            <p className="noData">no data</p>
                          ))}
                        {!selectedMetricData && <p>0.0%</p>}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
            {useTemplate === "Line_ValueGrowth" && (
              <div>
                <div className="widget-body">
                  {chartSeries && chartSeries.length > 0 ? (
                    <Chart
                      id="multi_series_area_chart"
                      options={chartOptions}
                      series={chartSeries}
                      type="area"
                      width="100%"
                      height="140px"
                    />
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        height: "100%",
                        marginTop: "14px",
                      }}
                    >
                      No Data
                    </div>
                  )}
                </div>
                <div className="widget-footer">
                  <Row>
                    <Col md={7}>
                      <div className="widget-footer-title">
                        {selectedMetricData &&
                          (!isNaN(parseFloat(selectedMetricData.value)) ? (
                            <p>
                              {selectedMetricData.unit === "money" ? (
                                <>
                                  <NumberFormatter
                                    currencyType={currencyCode}
                                    value={parseFloat(
                                      selectedMetricData.value
                                    ).toFixed(2)}
                                  ></NumberFormatter>
                                </>
                              ) : selectedMetricData.unit === "percentage" ? (
                                `${parseFloat(selectedMetricData.value).toFixed(
                                  1
                                )}%`
                              ) : (
                                <NumberFormatter
                                  value={parseInt(selectedMetricData.value)}
                                ></NumberFormatter>
                              )}
                            </p>
                          ) : (
                            <p className="noData">no data</p>
                          ))}
                        {!selectedMetricData && <p>0.0%</p>}
                      </div>
                    </Col>
                    <Col md={5} className="align-end">
                      <div
                        className={
                          selectedMetricData &&
                          parseFloat(selectedMetricData.growthRate) >= 0.0
                            ? "widget-status-container up"
                            : "widget-status-container down"
                        }
                      >
                        {selectedMetricData &&
                          (!isNaN(parseFloat(selectedMetricData.growthRate)) ? (
                            <p>
                              {Math.abs(
                                parseFloat(selectedMetricData.growthRate)
                              ).toFixed(1)}
                              %
                            </p>
                          ) : (
                            <p className="noData">no data</p>
                          ))}
                        {!selectedMetricData && <p>0.0%</p>}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Metrics;
