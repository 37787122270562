/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import LogoIcon from "../../../../../assets/brand/logo-icon.svg";
import DuplicateIcon from "../../../../../assets/images/icons/duplicate-icon.svg";
import RemoveIcon from "../../../../../assets/images/icons/trash-icon.svg";
import MarketSelectData from "./MarketSelectData";
import Select, { components } from "react-select";
const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" defaultChecked={isSelected} />
      {children}
    </components.Option>
  );
};
function AddRulesApplyto(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [ruleDataLoading, setRuleDataLoading] = useState(true);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState();
  const [newSelectedOptions, setNewSelectedOptions] = useState();

  const [autoCampaignSelectAllStatus, setAutoCampaignSelectAllStatus] =
    useState(false);
  const [manualCampaignSelectAllStatus, setManualCampaignSelectAllStatus] =
    useState(false);
  const [autoCampaignCheckedAllData, setAutoCampaignCheckeAllData] =
    useState(false);
  const [manualCampaignCheckedAllData, setManualCampaignCheckedAllData] =
    useState(false);

  const [matchTypeCheckedlist, setMatchTypeCheckedlist] = useState([""]);

  const [matchTypeData, setMatchTypeData] = useState(false);

  const [metaData, setMetaData] = useState([]);
  const [rulesMetaData, setRulesMetaData] = useState({});
  const [actionCriteriaDetails, setActionCriteriaDetails] = useState([]);
  const [actionUpToCriteriaDetails, setActionUpToCriteriaDetails] = useState(
    []
  );
  const [applyTo, setApplyTo] = useState([]);
  const [marketPlaces, setMarketPlaces] = useState([]);
  const [marketPlacesDropdown, setMarketPlacesDropdown] = useState([]);
  const [matchTypes, setMatchTypes] = useState([]);
  const [matchTypesSelected, setMatchTypesSelected] = useState({
    autoCampaign: [],
    manualCampaign: { keywordMatch: [], productMatch: [] },
  });
  const [reportDuriationDetails, setReportDuriationDetails] = useState([]);
  const [targetCriteriaDetails, setTargetCriteriaDetails] = useState([]);
  const [pageData, setPageData] = useState([]);

  const [formValues, setFormValues] = useState([
    { conditionFilter: "", conditionName: "", conditionValue: "" },
  ]);

  useEffect(() => {
    if (props.rulesMetaData) {
      let applyToDetails = props.rulesMetaData.applyToDetails;
      if (applyToDetails !== undefined) {
        setRulesMetaData(props.rulesMetaData);
        setMatchTypesSelected(props.wizardData.applyToWizard.matchType);
        setActionCriteriaDetails(props.rulesMetaData.actionCriteriaDetails);
        setActionUpToCriteriaDetails(
          props.rulesMetaData.actionUpToCriteriaDetails
        );
        setApplyTo(applyToDetails.applyTo);
        console.log(applyToDetails.marketPlaces);

        let marketplaceList = applyToDetails.marketPlaces;
        let newMarketplaceList = [];
        console.log(marketplaceList);
        if (props.wizardData.applyToWizard.marketPlace.length === 0) {
          if (marketplaceList.length > 0) {
            for (let i = 0; i < marketplaceList.length; i++) {
              let obj = {
                value: marketplaceList[i].country,
                label: marketplaceList[i].country,
              };

              if (0 < props.connectedMarketplace?.length) {
                if (
                  props.connectedMarketplace?.includes(
                    marketplaceList[i].countryCode
                  )
                ) {
                  newMarketplaceList.push(obj);
                }
              }
              setMarketPlacesDropdown(newMarketplaceList);
            }
          }
        } else {
          let selected = [];
          let applyToOptionDataMap = props.wizardData.applyToWizard.marketPlace;
          let applyToOptionData = applyToDetails.marketPlaces;
          console.log(applyToOptionDataMap);
          console.log(applyToOptionData);
          if (applyToOptionData) {
            for (let i = 0; i < applyToOptionData.length; i++) {
              let obj = {
                value: applyToOptionData[i].country,
                label: applyToOptionData[i].country,
                isChecked: applyToOptionDataMap?.includes(
                  applyToOptionData[i].country
                ),
              };
              if (0 < props.connectedMarketplace?.length) {
                if (
                  props.connectedMarketplace?.includes(
                    marketplaceList[i].countryCode
                  )
                ) {
                  selected.push(obj);
                }
              }

              setMarketPlacesDropdown(selected);
            }
          }
        }

        // console.log();
        setMatchTypes(applyToDetails.matchTypes);
        setReportDuriationDetails(props.rulesMetaData.reportDurationDetails);
        setTargetCriteriaDetails(props.rulesMetaData.targetCriteriaDetails);
        setRuleDataLoading(false);
      }
    }
    setMetaData(props.metaData);
  }, [props.rulesMetaData, rulesMetaData, props.metaData, metaData]);
  console.log(marketPlacesDropdown);

  useEffect(() => {
    if (props.metaData && props.rulesMetaData.applyToDetails) {
      if (props.metaData.text) {
        buildApplyToDropdownData(
          props.rulesMetaData.applyToDetails.applyTo,
          props.metaData
        );
        setMetaDataLoading(false);
      }
    }
  }, [props.rulesMetaData, props.metaData]);

  useEffect(() => {
    // if (props.wizardData.applyToWizard.marketPlace) {
    //   let selected = [];
    //   let applyToOptionData = props.wizardData.applyToWizard.marketPlace;
    //   if (applyToOptionData) {
    //     for (let i = 0; i < applyToOptionData.length; i++) {
    //       let obj = {
    //         value: applyToOptionData[i],
    //         label: applyToOptionData[i],
    //         isChecked: true,
    //       };
    //       selected.push(obj);
    //     }
    //     setMarketPlacesDropdown(selected);
    //   }
    // }
    if (props.wizardData.applyToWizard.applyTo.length > 0) {
      let applyToList = [];
      let applyToData = props.wizardData.applyToWizard.applyTo;
      if (applyToData) {
        for (let i = 0; i < applyToData.length; i++) {
          if (applyToData[i].expressions.operand) {
            let obj = {
              conditionFilter: applyToData[i].expressions.operand,
              conditionName: applyToData[i].expressions.operator,
              conditionValue:
                applyToData[i].expressions.value[0].inputExpression.input,
            };
            applyToList.push(obj);
          }
        }
      }
      setFormValues(applyToList);
      if (applyToList.length < 1) {
        setFormValues([
          ...formValues,
          { conditionFilter: "", conditionName: "", conditionValue: "" },
        ]);
      }
    } else {
      if (formValues.length < 1) {
        setFormValues([
          ...formValues,
          { conditionFilter: "", conditionName: "", conditionValue: "" },
        ]);
      }
    }
  }, []);

  const selectAllHandler = (e) => {
    const value = e.target.checked;
    setMarketPlacesDropdown((prev) => {
      const updateArray = prev.map((item) => ({ ...item, isChecked: value }));
      updateTag(updateArray);
      return updateArray;
    });
  };
  /* Apply to wizard */
  const handleSelect = (data, e) => {
    setMarketPlacesDropdown((prev) => {
      let updatedState;
      updatedState = prev.map((item) =>
        item.value === data ? { ...item, isChecked: !item.isChecked } : item
      );
      updateTag(updatedState);
      return updatedState;
    });

    // const removeMarketPlace=()

    // if (e.toString() === "true") {
    //   setSelectedOptions((prev) => {
    //     return marketPlacesDropdown.filter(
    //       (option) => option.label !== "All MarketPlace"
    //     );
    //   });
    //   setMarketPlacesDropdown((prev) => {
    //     const updateArray = prev.map((item) =>
    //       item.label === "All MarketPlace" ? { ...item, value: false } : item
    //     );
    //     return updateArray;
    //   });
    // } else if (e.toString() === "false") {
    //   setSelectedOptions([]);
    //   setMarketPlacesDropdown((prev) => {
    //     const updateArray = prev.map((item) =>
    //       item.label === "All MarketPlace" ? { ...item, value: true } : item
    //     );
    //     return updateArray;
    //   });
    // } else {
    //   setSelectedOptions(data);
    // }
  };
  const updateTag = (updatedState) => {
    const selectedTags = updatedState
      .filter((item) => item.isChecked)
      .map((item) => item.label);
    setSelectedOptions(selectedTags);
    wizardDataUpdate(
      "marketplace",
      selectedTags,
      formValues,
      matchTypesSelected
    );
  };
  // selected & unslected
  const autoCampaignSelectAllClick = (check) => {
    let updatedMatchTypes = { ...matchTypesSelected };
    setMatchTypesSelected((prev) => {
      const updated = {
        ...prev,
        autoCampaign: check ? [...matchTypes.autoCampaign] : [],
      };
      updatedMatchTypes = updated;
      wizardDataUpdate("", selectedOptions, formValues, updatedMatchTypes);
      return updated;
    });
  };

  // selected & unslected
  const manualCampaignSelectAllClick = (check) => {
    let updatedMatchTypes = { ...matchTypesSelected };
    setMatchTypesSelected((prev) => {
      const updated = {
        ...prev,
        manualCampaign: {
          ...prev.manualCampaign,
          keywordMatch: check
            ? [...matchTypes?.manualCampaign?.keywordMatch]
            : [],
          productMatch: check
            ? [...matchTypes?.manualCampaign?.productMatch]
            : [],
        },
      };
      updatedMatchTypes = updated;
      wizardDataUpdate("", selectedOptions, formValues, updatedMatchTypes);
      return updatedMatchTypes;
    });
  };

  console.log(matchTypesSelected);
  const matchTypesSelect = (matchType, e) => {
    setMatchTypesSelected((prev) => {
      let updatedMatchTypes = { ...prev };

      if (matchType === "autoCampaign") {
        // Check if prev.autoCampaign is an array
        if (Array.isArray(prev.autoCampaign)) {
          updatedMatchTypes.autoCampaign = prev.autoCampaign.includes(e.target.value)
            ? prev.autoCampaign.filter((val) => val !== e.target.value)
            : [...prev.autoCampaign, e.target.value];
        } else {
          // If prev.autoCampaign is not an array, initialize it as an array with e.target.value
          updatedMatchTypes.autoCampaign = [e.target.value];
        }
      } else if (matchType === "keywordMatch") {
        // Check if prev.manualCampaign.keywordMatch is an array
        if (Array.isArray(prev.manualCampaign?.keywordMatch)) {
          updatedMatchTypes.manualCampaign = {
            ...prev.manualCampaign,
            keywordMatch: prev.manualCampaign.keywordMatch.includes(e.target.value)
              ? prev.manualCampaign.keywordMatch.filter((val) => val !== e.target.value)
              : [...prev.manualCampaign.keywordMatch, e.target.value],
            productMatch: prev.manualCampaign?.productMatch ? [...prev.manualCampaign.productMatch] : [],
          };
        } else {
          // If prev.manualCampaign.keywordMatch is not an array, initialize it as an array with e.target.value
          updatedMatchTypes.manualCampaign = {
            ...prev.manualCampaign,
            keywordMatch: [e.target.value],
            productMatch: prev.manualCampaign?.productMatch ? [...prev.manualCampaign.productMatch] : [],
          };
        }
      }else if (matchType === "productMatch") {
        if (Array.isArray(prev.manualCampaign?.productMatch)) {
          updatedMatchTypes.manualCampaign = {
            ...prev.manualCampaign,
            productMatch: prev.manualCampaign.productMatch.includes(e.target.value)
              ? prev.manualCampaign.productMatch.filter((val) => val !== e.target.value)
              : [...prev.manualCampaign.productMatch, e.target.value],
            keywordMatch: prev.manualCampaign?.keywordMatch ? [...prev.manualCampaign.keywordMatch] : [],
          };
        } else {
          updatedMatchTypes.manualCampaign = {
            ...prev.manualCampaign,
            productMatch: [e.target.value],
            keywordMatch: prev.manualCampaign?.keywordMatch ? [...prev.manualCampaign.keywordMatch] : [],
          };
        }
      }

      wizardDataUpdate("", selectedOptions, formValues, updatedMatchTypes);
      return updatedMatchTypes;
    });
  };

  function wizardDataUpdate(type, data, newFormValues, updatedMatchTypes) {
    console.log(data);
    console.log(type);
    let applyTo = [];
    if (newFormValues) {
      for (let i = 0; i < newFormValues.length; i++) {
        let obj = {
          logicalAND: true,
          expressions: {
            operand: newFormValues[i].conditionFilter,
            operator: newFormValues[i].conditionName,
            value: [
              {
                inputType: "List",
                inputExpression: {
                  operand: null,
                  input: newFormValues[i].conditionValue,
                },
              },
            ],
            unit: null,
          },
        };
        applyTo.push(obj);
      }
    }
    let marketPlace;
    if (selectedOptions !== undefined) {
      marketPlace = selectedOptions
        ?.filter((item) => item)
        ?.map((item) => item.label);
    }

    let matchType = updatedMatchTypes;
    let formData = {
      type: "applyto",
      marketPlace: data,
      applyTo: applyTo,
      matchType: matchType,
    };
    props.parentCallback(formData);
    props.wizardDataCallback(formData);
  }

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
    wizardDataUpdate("", "", newFormValues, matchTypesSelected);
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { conditionFilter: "", conditionName: "", conditionValue: "" },
    ]);
  };
  let duplicateFormFields = (i) => {
    let newFormValues = [...formValues];
    let duplicateRow = {
      conditionFilter: newFormValues[i].conditionFilter,
      conditionName: newFormValues[i].conditionName,
      conditionValue: newFormValues[i].conditionValue,
    };
    newFormValues.push(duplicateRow);
    setFormValues(newFormValues);
    wizardDataUpdate("", "", newFormValues);
  };
  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    wizardDataUpdate("", "", newFormValues);
  };

  let handleSubmit = (event) => {};

  function buildApplyToDropdownData(listData, metaDataList) {
    let result = metaDataList;
    if (listData && result) {
      for (let i = 0; i < listData.length; i++) {
        listData[i]["id"] = i + 1;
        listData[i]["selected"] = false;
        let slectedConditionType = listData[i]["filterConditionType"];
        let conditionTypeList = result[slectedConditionType];
        listData[i]["conditionTypes"] = conditionTypeList;
      }
      setApplyTo(listData);
    }
  }
  const SubSelectOptions = (props) => {
    let { index, eleData } = props;
    if (eleData.conditionFilter) {
      let findIndex = applyTo.findIndex(
        (obj) => obj.name == eleData.conditionFilter
      );
      let conditionData = applyTo[findIndex];
      let optionData = conditionData.conditionTypes;
      if (optionData.length > 0) {
        return optionData.map((options, i) => (
          <option key={i} value={options.value}>
            {options.name}
          </option>
        ));
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="wizard-step">
        {!metaDataLoading && !ruleDataLoading ? (
          <>
            <div className="card">
              <Row>
                <Col md={12}>
                  <h4 className="title">Marketplace</h4>
                </Col>
                <Col md={4}>
                  <form>
                    <div className="filter-checkbox checkbox-adjust">
                      <MarketSelectData
                        option={marketPlacesDropdown}
                        handleSelect={handleSelect}
                        selectAllHandler={selectAllHandler}
                      />
                      {/* <Select
                        className="focus-border-green"
                        key={selectedOptions.length}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        onChange={(selectedOptions) =>
                          handleSelect(
                            selectedOptions,
                            selectedOptions
                              .filter(
                                (item, index) =>
                                  item.label === "All MarketPlace"
                              )
                              .map((item, index) => item.value)
                          )
                        }
                        options={marketPlacesDropdown}
                        components={{
                          Option: InputOption,
                        }}
                        value={selectedOptions}
                        menuIsOpen={isMenuOpen}
                        onMenuOpen={() => setIsMenuOpen(true)}
                        onMenuClose={() => setIsMenuOpen(false)}
                        placeholder="All marketplace"
                      /> */}
                    </div>
                  </form>
                </Col>
              </Row>
            </div>
            <div className="card">
              <Row>
                <Col md={12}>
                  <h4 className="title">Apply to</h4>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <form>
                    {formValues.map((element, index) => (
                      <div className="form-row mb-3" key={index}>
                        <div className="form-field-group">
                          <div className="applyto-form-field width-100p">
                            <select
                              className="form-select focus-border-green width-100p"
                              name="conditionFilter"
                              value={element.conditionFilter || ""}
                              onChange={(e) => {
                                handleChange(index, e);
                              }}
                            >
                              <option value="">--Select--</option>
                              {applyTo.map((list, i) => (
                                <option value={list.name}>
                                  {list.displayName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-field width-100p ps-3">
                            <select
                              className="form-select  focus-border-green width-150p"
                              name="conditionName"
                              value={element.conditionName || ""}
                              onChange={(e) => handleChange(index, e)}
                            >
                              <option value="">--Select--</option>
                              <SubSelectOptions
                                index={index}
                                eleData={element}
                              />
                            </select>
                          </div>
                          <div className="form-field width-100p">
                            <input
                              className="form-control width-150p"
                              type="text"
                              placeholder="enter keyword"
                              name="conditionValue"
                              value={element.conditionValue || ""}
                              onChange={(e) => handleChange(index, e)}
                            />
                          </div>
                        </div>
                        <div className="form-action a-2">
                          <div
                            className="icon-box-white cursor"
                            onClick={() => duplicateFormFields(index)}
                          >
                            <img src={DuplicateIcon} alt="" />
                          </div>
                          {index !== 0 && (
                            <div
                              className="icon-box-gray cursor"
                              onClick={() => removeFormFields(index)}
                            >
                              <img src={RemoveIcon} alt="" />
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </form>
                  <div>
                    <button
                      type="button"
                      className="btn btn-link add-more-btn"
                      onClick={() => addFormFields()}
                    >
                      <i className="circle-add-icon"></i> Add Row
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
            {!ruleDataLoading && (
              <div className="card">
                <form>
                  <Row>
                    <Col md={12}>
                      <h4 className="title">Match Types</h4>
                    </Col>

                    <Col md={6}>
                      {matchTypes.autoCampaign.length > 0 && (
                        <div className="gray-box">
                          <div className="gray-box-header">
                            <div>
                              <h4 className="title">Auto Campaign:</h4>
                            </div>
                            <div className="text-end">
                              {!matchTypesSelected.autoCampaign?.some(
                                (item) => item.length
                              ) ? (
                                <span
                                  onClick={() =>
                                    autoCampaignSelectAllClick(true)
                                  }
                                  className="btn btn-link"
                                >
                                  Select All
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    autoCampaignSelectAllClick(false)
                                  }
                                  className="btn btn-link"
                                >
                                  Deselect All
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="option-list-container mt-2">
                            {matchTypes.autoCampaign.map((list, i) => {
                              return (
                                <div className="form-check">
                                  <input
                                    className="form-check-input selectedAll"
                                    type="checkbox"
                                    value={list}
                                    id={"autoCampain" + i}
                                    checked={matchTypesSelected?.autoCampaign?.includes(
                                      list
                                    )}
                                    onChange={(e) =>
                                      matchTypesSelect("autoCampaign", e)
                                    }
                                  />
                                  <label className="form-check-label">
                                    {list}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col md={6}>
                      <div className="gray-box">
                        <div className="gray-box-header">
                          <div>
                            <h4 className="title">Manual Campaign:</h4>
                          </div>
                          <div className="text-end">
                            {!matchTypesSelected?.manualCampaign?.keywordMatch?.some(
                              (item, index) =>
                                item.length !==
                                matchTypesSelected?.manualCampaign?.productMatch
                                  ?.length
                            ) ? (
                              <span
                                onClick={() =>
                                  manualCampaignSelectAllClick(true)
                                }
                                className="btn btn-link"
                              >
                                Select All
                              </span>
                            ) : (
                              <span
                                onClick={() =>
                                  manualCampaignSelectAllClick(false)
                                }
                                className="btn btn-link"
                              >
                                Deselect All
                              </span>
                            )}
                          </div>
                        </div>

                        <Row>
                          {matchTypes.manualCampaign.keywordMatch.length >
                            0 && (
                            <Col>
                              <div className="option-list-container mt-2">
                                <h6>Keyword Targeting</h6>
                                {matchTypes.manualCampaign.keywordMatch?.map(
                                  (list, i) => {
                                    return (
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={list}
                                          id={"keywordMatch" + i}
                                          checked={matchTypesSelected?.manualCampaign?.keywordMatch?.includes(
                                            list
                                          )}
                                          onChange={(e) =>
                                            matchTypesSelect("keywordMatch", e)
                                          }
                                        />
                                        <label className="form-check-label">
                                          {list}
                                        </label>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </Col>
                          )}
                          {matchTypes?.manualCampaign?.productMatch?.length >
                            0 && (
                            <Col>
                              <div className="option-list-container mt-2">
                                <h6>Product Targeting</h6>
                                {matchTypes?.manualCampaign?.productMatch.map(
                                  (list, i) => {
                                    return (
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={list}
                                          id={"productMath" + i}
                                          checked={matchTypesSelected?.manualCampaign?.productMatch?.includes(
                                            list
                                          )}
                                          onChange={(e) =>
                                            matchTypesSelect("productMatch", e)
                                          }
                                        />
                                        <label className="form-check-label">
                                          {list}
                                        </label>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            )}
          </>
        ) : (
          <div className="loading-container">
            <div className="loading-text">
              <span className="logicon">
                <img src={LogoIcon} alt="logo-small"></img>
              </span>
              <span>L</span>
              <span>O</span>
              <span>A</span>
              <span>D</span>
              <span>I</span>
              <span>N</span>
              <span>G</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AddRulesApplyto;
