import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import InfoButton from "../Common/InfoButton";
import MoreActionIcon from "../../assets/images/icons/more-action-icon.svg";
import DashboardService from "../../services/DashboardService";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import DynamicWidthDropdown from "../Common/DynamicWidthDropdown/DynamicWidthDropdown";
import NumberFormatter from "../Common/NumberFormatter";

const DonutPie = ({
  defaultMetric,
  canSwitch,
  filters,
  defaultChange,
  defaultData,
}) => {
  const [loading, setLoading] = useState(true);
  const [metricsList, setMetricsList] = useState([]);
  const [filteredMetricsList, setFilteredMetricsList] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState(defaultMetric);
  const [numerator, setNumerator] = useState({
    name: "",
    unit: "",
    description: "",
    value: 0.0,
    average: 0.0,
    sum: 0.0,
    min: 0.0,
    max: 0.0,
    growthRate: 0.0,
    data: [],
  });
  const [denominator, setDenominator] = useState({
    name: "",
    unit: "",
    description: "",
    value: 0.0,
    average: 0.0,
    sum: 0.0,
    min: 0.0,
    max: 0.0,
    growthRate: 0.0,
    data: [],
  });
  const [selectedMetricData, setSelectedMetricData] = useState({
    name: "",
    unit: "",
    description: "",
    value: 0.0,
    average: 0.0,
    sum: 0.0,
    min: 0.0,
    max: 0.0,
    growthRate: 0.0,
    data: [],
  });

  const [percentageValue, setPercentageValue] = useState(0.0);

  const metricNumerator = {
    ACOS: "Spend",
    CPC: "Spend",
    CTR: "Clicks",
    CR: "Orders",
    ROAS: "Sales",
    ROI: "Royalties_Net",
  };
  const metricDenominator = {
    ACOS: "Sales",
    CPC: "Clicks",
    CTR: "Impressions",
    CR: "Clicks",
    ROAS: "Spend",
    ROI: "Spend",
  };

  const metricUnit = {
    Sales: "money",
    Order: "number",
    CPC: "money",
    Budget: "money",
    CTR: "percentage",
    Clicks: "number",
    ACOS: "percentage",
    ROI: "percentage",
    Cost: "money",
    Impressions: "number",
    Spend: "money",
    PagesRead_Ads: "number",
    Royalties_Gross: "money",
    Royalties_Projected: "money",
    ROAS: "number",
    CR: "percentage",
  };

  const metricTitles = {
    Sales: { title: "Sales", subTitle: "", description: "Total sales" },
    Orders: { title: "Order", subTitle: "", description: "Total orders" },
    CPC: { title: "CPC", subTitle: "", description: "Cost Per Click" },
    Budget: { title: "Budget", subTitle: "", description: "Total budget" },
    CTR: { title: "CTR", subTitle: "", description: "Click Through Rate" },
    Clicks: { title: "Clicks", subTitle: "", description: "Total clicks" },
    ACOS: {
      title: "ACOS",
      subTitle: "",
      description: "Advertising Cost of Sales",
    },
    ROI: { title: "ROI", subTitle: "", description: "Return on Investment" },
    Cost: { title: "Cost", subTitle: "", description: "Total cost" },
    Impressions: {
      title: "Impressions",
      subTitle: "",
      description: "Total impressions",
    },
    Spend: { title: "Spend", subTitle: "", description: "Total spend" },
    PagesRead_Ads: {
      title: "PagesRead_Ads",
      subTitle: "",
      description: "Pages read from ads",
    },
    Royalties_Gross: {
      title: "Royalties",
      subTitle: "Gross",
      description: "Royalties Gross",
    },
    Royalties_Projected: {
      title: "Royalties",
      subTitle: "Projected",
      description: "Royalties Projected",
    },
    ROAS: { title: "ROAS", subTitle: "", description: "Return on Ad Spend" },
    CR: { title: "CR", subTitle: "", description: "Conversion Rate" },
  };

  function getMetricByName(metricsArray, name) {
    return metricsArray.find((metric) => metric.name === name);
  }

  useEffect(() => {
    fetchMetricsList();
  }, [filters]);
  // Simulate fetching the metrics list
  // Replace this with your actual API call logic
  const fetchMetricsList = async () => {
    setLoading(true);
    try {
      var request = {
        globalFilters: filters,
        metricName: "",
      };
      const fetchedMetricsList = await DashboardService.getMetricsData(request);
      if (fetchedMetricsList) {
        // Update the state with the fetched metrics list
        setMetricsList(fetchedMetricsList);
        const metricNamesToFilter = ["ACOS", "CPC", "CTR", "CR", "ROAS", "ROI"]; // Replace with your list of names
        const filteredMetricsList = fetchedMetricsList.filter((metric) =>
          metricNamesToFilter.includes(metric.name)
        );
        setFilteredMetricsList(filteredMetricsList);
      }
    } catch (error) {
      console.error("Error fetching metrics list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedMetric, filteredMetricsList]);

  // Simulate fetching data based on the selected metric
  // Replace this with your actual API call logic
  const fetchData = async () => {
    try {
      // Simulate an API response with metric data
      setSelectedMetricData(getMetricByName(metricsList, selectedMetric));
      const selectedNumerator = getMetricByName(
        metricsList,
        metricNumerator[selectedMetric]
      );
      const selectedDenominator = getMetricByName(
        metricsList,
        metricDenominator[selectedMetric]
      );
      setNumerator(selectedNumerator);
      setDenominator(selectedDenominator);

      let percentageVal = 0;
      if (selectedNumerator && selectedDenominator) {
        if (!selectedNumerator.sum) {
          selectedNumerator.sum = 0;
        }
        if (!selectedDenominator.sum) {
          selectedDenominator.sum = 0;
        }
        if (selectedDenominator.sum !== 0) {
          percentageVal =
            (selectedNumerator.sum * 100) / selectedDenominator.sum;
        }
      }
      setPercentageValue(percentageVal.toFixed(1));
    } catch (error) {
      console.error("Error fetching metric data:", error);
    } finally {
    }
  };

  const handleMetricChange = (event) => {
    defaultChange(event.target.value, defaultData);
    setSelectedMetric(event.target.value);
  };

  return (
    <div className="widget-container height-220 semi-circle-widget">
      <div className="widget-header">
        <Row>
          <Col md={10} className="widget-select-container">
            <div className="input-group filter d-flex align-items-baseline">
              <div
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  display: "inline-flex",
                }}
              >
                {(filteredMetricsList.length < 1 || !canSwitch) && (
                  <>
                    {metricTitles[defaultMetric] && (
                      <>
                        <span
                          style={{
                            color: "#111827",
                            fontSize: "14px",
                            fontFamily: "Plus Jakarta Sans",
                            fontWeight: "600",
                            lineHeight: "21px",
                            wordWrap: "break-word",
                            paddingRight: "10px",
                          }}
                        >
                          {metricTitles[defaultMetric].title}
                        </span>
                        <span
                          style={{
                            color: "#9CA3AF",
                            fontSize: "14px",
                            fontFamily: "Plus Jakarta Sans",
                            fontWeight: "500",
                            lineHeight: "21px",
                            wordWrap: "break-word",
                          }}
                        >
                          {metricTitles[defaultMetric].subTitle && (
                            <span>
                              [{metricTitles[defaultMetric].subTitle}]
                            </span>
                          )}
                        </span>
                        <InfoButton
                          infoText={metricTitles[defaultMetric].description}
                        />
                      </>
                    )}
                  </>
                )}
                {filteredMetricsList &&
                  filteredMetricsList.length > 0 &&
                  canSwitch && (
                    <DynamicWidthDropdown
                      options={filteredMetricsList}
                      selectedItem={selectedMetric}
                      handleSelectChange={handleMetricChange}
                    ></DynamicWidthDropdown>
                  )}
              </div>
            </div>
          </Col>
          <Col md={2} className="p-0">
            <div className="more-action-icon">
              <img src={MoreActionIcon} alt="info circle icon" />
            </div>
          </Col>
        </Row>
      </div>
      {loading ? (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            height: "100%",
            marginTop: "70px",
          }}
          className="horizontalBarChart"
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <br></br>
          <div className="loading ms-4">Loading...</div>
        </div>
      ) : (
        <div>
          <div
            className="widget-body"
            style={{
              marginTop: "20px",
              minHeight: "90px",
            }}
          >
            <div>
              {selectedMetricData && (
                <div>
                  <SemiCircleProgressBar
                    percentage={percentageValue}
                    showPercentValue
                    diameter={135}
                    stroke={"#00D26E"}
                    strokeWidth={8}
                    background={"#E5E7EB"}
                  />
                  <div>
                    <p className="widget-p-sub-text">
                      {numerator && (
                        <span title={numerator.name}>
                          <NumberFormatter
                            value={numerator.sum.toFixed(2)}
                          ></NumberFormatter>
                        </span>
                      )}
                      {!numerator && <span>0.00</span>}/
                      {denominator && (
                        <span title={denominator.name}>
                          <NumberFormatter
                            value={denominator.sum.toFixed(2)}
                          ></NumberFormatter>
                        </span>
                      )}
                      {!denominator && <span>0.00</span>}
                    </p>
                  </div>
                </div>
              )}
              {!selectedMetricData && (
                <div>
                  <SemiCircleProgressBar
                    percentage={0}
                    showPercentValue
                    diameter={115}
                    stroke={"#00D26E"}
                    strokeWidth={8}
                  />
                  <div>
                    <p className="widget-p-sub-text">0.00/0.00</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="widget-footer">
            <Row>
              <Col md={12}>
                <div
                  className={
                    selectedMetricData &&
                    parseFloat(selectedMetricData.growthRate) >= 0.0
                      ? "widget-status-container up"
                      : "widget-status-container down"
                  }
                >
                  {selectedMetricData &&
                    (!isNaN(parseFloat(selectedMetricData.growthRate)) ? (
                      <p>
                        {Math.abs(
                          parseFloat(selectedMetricData.growthRate)
                        ).toFixed(1)}
                        %
                      </p>
                    ) : (
                      <p className="noData">no data</p>
                    ))}
                  {!selectedMetricData && <p>0.0%</p>}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};

export default DonutPie;
