import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import "./CamapaignManagerBulkOperation.css";
import CampaignManagerService from "../../../services/CampaignManagerService";
import { toast } from "react-toastify";

const CamapaignManagerBulkOperation = ({
  enabled,
  type,
  marketplace,
  rows,
  showStatus,
  showDailyBudget,
  showBiddingStrategy,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [model, setModel] = useState({
    selectedOption: "",
    data: {
      status: "",
      dailyBudget: "",
      bidValue: 0.0,
      unit: "",
      biddingStrategy: "",
    },
  });

  const closePopup = () => {
    setShowPopup(false);
  };
  const selectOption = (option) => {
    setModel({
      selectedOption: option,
      data: {
        status: "",
        dailyBudget: "",
        bidValue: "",
        unit: "",
        biddingStrategy: "",
      },
    });
    setShowPopup(true);
  };

  const handleApply = async () => {
    try {
      let value = "";
      //TODO: do client side valiations.
      if (model.selectedOption === "Status") {
        if(type == "Target"){
          value = JSON.stringify({ Status: model.data.status, Target: rows });
        }else{
          value = JSON.stringify({ Status: model.data.status});
        }
      } else if (model.selectedOption === "Daily Budget") {
        if(type == "Target"){
          value = JSON.stringify({
            DailyBudget: { Actiontype: model.data.dailyBudget ,
            BidValue: parseFloat(model.data.bidValue),
            Unit: model.data.unit,
            Target: rows
          }
          });
        }else{
          value = JSON.stringify({
            DailyBudget: { Actiontype: model.data.dailyBudget ,
            BidValue: parseFloat(model.data.bidValue),
            Unit: model.data.unit}
          });
        }
       
      } else if (model.selectedOption === "Bidding Strategy") {
        value = JSON.stringify({
          BiddingStrategy: model.data.biddingStrategy,
        });
      } else {
        toast("Selected bulk operation not supported");
      }

      const request = {
        type: type,
        operation: model.selectedOption,
        value: value,
        Identifiers: rows.map((item, index) => {
          return {
            Identifier: item.identifiers,
            ProfileId: item.profileId,
          };
        }),
      };

      var result = await CampaignManagerService.BulkRequest(request);
      if (result) {
        // toast(result.message);
        toast("updated successfully");
      }
    } catch (error) {
      toast("Error updating bulk operation");
      console.error("Error updating bulk operation:", error);
    } finally {
      setShowPopup(false);
    }
  };

  return (
    <>
      {enabled && rows && rows.length > 0 && (
        <span className="CampaignManager-BuilkOperation">
          <div className="bulk-operation-container width-200 mx-2">
            <div className="dropdownContent width-200">
              <p
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Bulk Operation
                <i
                  className="fa fa-angle-down down-arrow-right"
                  aria-hidden="true"
                ></i>
                <i
                  className="fa fa-angle-up up-arrow-right"
                  aria-hidden="true"
                ></i>
              </p>
              <ul
                className="dropdown-menu shadow rounded"
                aria-labelledby="dropdownMenuButton1"
              >
                {showStatus && (
                  <li onClick={() => selectOption("Status")}>Status</li>
                )}

                {showDailyBudget && (
                  <li onClick={() => selectOption("Daily Budget")}>
                    Daily Budget
                  </li>
                )}

                {showBiddingStrategy && (
                  <li onClick={() => selectOption("Bidding Strategy")}>
                    Bidding Strategy
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div
            className={`${
              showPopup ? "modal createAdsModel open" : "modal createAdsModel"
            }`}
            aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel3"
          >
            <div className="modal-dialog">
              <div className="modal-content addTagModel">
                <Row className="addTagInputContainer">
                  <Col className="addTagModelContainers">
                    <h4>
                      {model.selectedOption.replaceAll("_", " ")}
                      <span
                        className="close-modal"
                        aria-label="Close"
                        onClick={() => closePopup()}
                      >
                        x
                      </span>
                    </h4>

                    {model.selectedOption === "Status" && (
                      <div>
                        <label></label>
                        <select
                          value={model.data.status}
                          onChange={(e) =>
                            setModel({
                              ...model,
                              data: { ...model.data, status: e.target.value },
                            })
                          }
                          className="form-select"
                          name="campaign-manager-bulkoperation-status"
                          placeholder="Select Status"
                        >
                          <option value="" disabled selected>
                            Select Status
                          </option>
                          <option value="Enabled">Enabled</option>
                          <option value="Paused">Paused</option>
                          <option value="Archived">Archived</option>
                        </select>
                      </div>
                    )}

                    {model.selectedOption === "Daily Budget" && (
                      <>
                        <div>
                          <label></label>
                          <select
                            value={model.data.dailyBudget}
                            onChange={(e) =>
                              setModel({
                                ...model,
                                data: {
                                  ...model.data,
                                  dailyBudget: e.target.value,
                                },
                              })
                            }
                            className="form-select"
                            name="campaign-manager-bulkoperation-daily-budget-option"
                            placeholder="Select Daily Budget"
                          >
                            <option value="" disabled selected>
                              Select Daily Budget
                            </option>
                            <option value="Increase daily budget by">
                              Increase daily budget by
                            </option>
                            <option value="Lower daily budget by">
                              Lower daily budget by
                            </option>
                            <option value="Set daily budget to">
                              Set daily budget to
                            </option>
                          </select>
                        </div>
                        <div>
                          <label></label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Bid Value"
                            value={model.data.bidValue}
                            style={{ color: "" }}
                            onChange={(e) =>
                              setModel({
                                ...model,
                                data: {
                                  ...model.data,
                                  bidValue: e.target.value,
                                },
                              })
                            }
                          />
                        </div>
                        <div>
                          <label></label>
                          <select
                            value={model.data.unit}
                            onChange={(e) =>
                              setModel({
                                ...model,
                                data: { ...model.data, unit: e.target.value },
                              })
                            }
                            className="form-select"
                            name="campaign-manager-bulkoperation-unit"
                            placeholder="Select Unit"
                          >
                            <option value="" disabled selected>
                              Select Unit
                            </option>
                            <option value="%">%</option>
                            <option value="$">$</option>
                          </select>
                        </div>
                      </>
                    )}

                    {model.selectedOption === "Bidding Strategy" && (
                      <>
                        <div>
                          <label></label>
                          <select
                            value={model.data.biddingStrategy}
                            onChange={(e) =>
                              setModel({
                                ...model,
                                data: {
                                  ...model.data,
                                  biddingStrategy: e.target.value,
                                },
                              })
                            }
                            className="form-select"
                            name="campaign-manager-bulkoperation-bidding-strategy"
                            placeholder="Select Bidding Strategy"
                          >
                            <option value="" disabled selected>
                              Select Bidding Strategy
                            </option>
                            <option value="Dynamic down Only">
                              Dynamic down Only
                            </option>
                            <option value="Dynamic up and down">
                              Dynamic up and down
                            </option>
                            <option value="Fixed">Fixed</option>
                          </select>
                        </div>
                      </>
                    )}

                    <div className="addTagBtnContainer">
                      <button
                        className="addTagCancell"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => closePopup()}
                      >
                        Cancel
                      </button>
                      <button
                        className="addTagSave"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => handleApply()}
                      >
                        Apply
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </span>
      )}
    </>
  );
};

export default CamapaignManagerBulkOperation;
