import React, { Component, useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import InfoButton from "../Common/InfoButton";
import MoreActionIcon from "../../assets/images/icons/more-action-icon.svg";
import DashboardService from "../../services/DashboardService";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import flagImages from "./flags.js";
import "./WorldMapChart.css";
import DynamicWidthDropdown from "../Common/DynamicWidthDropdown/DynamicWidthDropdown";
import NumberFormatter from "../Common/NumberFormatter";
import MasterDataService from "../../services/MasterDataService";

interface WorldMapChartProps {
  defaultMetric: string;
  canSwitch: boolean;
  filters: any;
  defaultChange: (string, string) => void;
}

const WorldMapChart: React.FC<WorldMapChartProps> = ({
  defaultMetric,
  canSwitch,
  filters,
  defaultChange,
}) => {
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [metaMetricList, setMetaMetricList] = useState([]);
  const [metricsList, setMetricsList] = useState([]);
  const countryDetails = {
    US: {
      CountryCode: "US",
      CountryName: "United States",
      Latitude: 37.0902,
      Longitude: -95.7129,
    },
    CA: {
      CountryCode: "CA",
      CountryName: "Canada",
      Latitude: 56.1304,
      Longitude: -106.3468,
    },
    MX: {
      CountryCode: "MX",
      CountryName: "Mexico",
      Latitude: 23.6345,
      Longitude: -102.5528,
    },
    GB: {
      CountryCode: "GB",
      CountryName: "United Kingdom",
      Latitude: 55.3781,
      Longitude: -3.436,
    },
    FR: {
      CountryCode: "FR",
      CountryName: "France",
      Latitude: 46.6031,
      Longitude: 1.8883,
    },
    DE: {
      CountryCode: "DE",
      CountryName: "Germany",
      Latitude: 51.1657,
      Longitude: 10.4515,
    },
    IT: {
      CountryCode: "IT",
      CountryName: "Italy",
      Latitude: 41.8719,
      Longitude: 12.5674,
    },
    ES: {
      CountryCode: "ES",
      CountryName: "Spain",
      Latitude: 40.4637,
      Longitude: -3.7492,
    },
    IN: {
      CountryCode: "IN",
      CountryName: "India",
      Latitude: 20.5937,
      Longitude: 78.9629,
    },
    JP: {
      CountryCode: "JP",
      CountryName: "Japan",
      Latitude: 36.2048,
      Longitude: 138.2529,
    },
    NL: {
      CountryCode: "NL",
      CountryName: "Netherlands",
      Latitude: 52.3676,
      Longitude: 4.9041,
    },
    TR: {
      CountryCode: "TR",
      CountryName: "Turkey",
      Latitude: 38.9637,
      Longitude: 35.2433,
    },
    SA: {
      CountryCode: "SA",
      CountryName: "Saudi Arabia",
      Latitude: 23.8859,
      Longitude: 45.0792,
    },
    AE: {
      CountryCode: "AE",
      CountryName: "United Arab Emirates",
      Latitude: 23.4241,
      Longitude: 53.8478,
    },
    AU: {
      CountryCode: "AU",
      CountryName: "Australia",
      Latitude: -25.2744,
      Longitude: 133.7751,
    },
    SG: {
      CountryCode: "SG",
      CountryName: "Singapore",
      Latitude: 1.3521,
      Longitude: 103.8198,
    },
    BR: {
      CountryCode: "BR",
      CountryName: "Brazil",
      Latitude: -14.235,
      Longitude: -51.9253,
    },
  };

  const formatNumber = (value) => {
    if (value >= 1e6) {
      return (value / 1e6).toFixed(1) + "M";
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(1) + "K";
    } else {
      return value.toString();
    }
  };
  function getMetricByName(metricsArray, name) {
    return metricsArray.find((metric) => metric.name === name);
  }

  const [selectedMetric, setSelectedMetric] = useState(defaultMetric);
  const [selectedMetricData, setSelectedMetricData] = useState({
    name: "",
    unit: "",
    description: "",
    value: 0.0,
    average: 0.0,
    sum: 0.0,
    min: 0.0,
    max: 0.0,
    growthRate: 0.0,
    data: [],
  });

  const metricTitles = {
    Sales: { title: "Sales", subtitle: "" },
    Orders: { title: "Order", subtitle: "" },
    CPC: { title: "CPC", subtitle: "" },
    Budget: { title: "Budget", subtitle: "" },
    CTR: { title: "CTR", subtitle: "" },
    Clicks: { title: "Clicks", subtitle: "" },
    ACOS: { title: "ACOS", subtitle: "" },
    ROI: { title: "ROI", subtitle: "" },
    Cost: { title: "Cost", subtitle: "" },
    Impressions: { title: "Impressions", subtitle: "" },
    Spend: { title: "Spend", subtitle: "" },
    PagesRead_Ads: { title: "PagesRead_Ads", subtitle: "" },
    Royalties_Gross: { title: "Royalties", subtitle: "Gross" },
    Royalties_Projected: { title: "Royalties", subtitle: "Projected" },
    ROAS: { title: "ROAS", subtitle: "" },
    CR: { title: "CR", subtitle: "" },
  };

  const metricDescription = {
    Sales: "Total sales",
    Order: "Total orders",
    CPC: "Cost Per Click",
    Budget: "Total budget",
    CTR: "Click Through Rate",
    Clicks: "Total clicks",
    ACOS: "Advertising Cost of Sales",
    ROI: "Return on Investment",
    Cost: "Total cost",
    Impressions: "Total impressions",
    Spend: "Total spend",
    PagesRead_Ads: "Pages read from ads",
    Royalties_Gross: "Royalties Gross",
    Royalties_Projected: "Royalties Projected",
    ROAS: "Return on Ad Spend",
    CR: "Conversion Rate",
  };

  const fetchMetaData = async () => {
    try {
      const metaData = await MasterDataService.getMetaData();
      if (metaData.WorldMap) {
        setMetaMetricList(metaData.WorldMap);
      }
    } catch (error) {
      console.error("Error fetching metrics list:", error);
    } finally {
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchMetaData();
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchMetricsList();
  }, [defaultMetric, canSwitch, filters]);

  // Simulate fetching the metrics list
  // Replace this with your actual API call logic
  const fetchMetricsList = async () => {
    try {
      var request = {
        globalFilters: filters,
        metricName: "",
      };
      const fetchedMetricsList =
        (await DashboardService.getMetricsData(request)) || [];

      // Update the state with the fetched metrics list
      setMetricsList(fetchedMetricsList);
      let metric = getMetricByName(fetchedMetricsList, selectedMetric);
      if (!metric) {
        metric =
          fetchedMetricsList && fetchedMetricsList.length > 0
            ? fetchedMetricsList[0]
            : {};
      }
      setSelectedMetricData(metric);
    } catch (error) {
      console.error("Error fetching metrics list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedMetric, metricsList, filters]);

  const fetchData = async () => {
    setLoading(true);
    try {
      var request = {
        globalFilters: filters,
        metrics: selectedMetric,
      };
      const worldMapChartData = await DashboardService.getWorldMapChartData(
        request
      );

      if (
        worldMapChartData &&
        worldMapChartData.data &&
        worldMapChartData.data.length > 0
      ) {
        const locations = worldMapChartData.data.map((item) => {
          const country = countryDetails[item.marketPlace];
          return {
            CountryName: country.CountryName,
            CountryCode: country.CountryCode,
            position: [country.Latitude, country.Longitude],
            value: item.metricValue,
          };
        });

        setLocations(locations);
      }
    } catch (error) {
      console.error("Error fetching metric data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleMetricChange = (event) => {
    defaultChange(event.target.value, "Map");
    setSelectedMetric(event.target.value);
    let metricData = getMetricByName(metricsList, selectedMetric);
    if (metricData) {
      setSelectedMetricData(metricData);
    }
  };

  const customIcon = (value, country) =>
    L.divIcon({
      className: "custom-marker",
      html: `<div class="marker-container">
                <span><img src="${flagImages[country]}" alt="${country}" class="marker-icon"/></span>
                <span class="metric-value">${value}</span></div>`,
      iconSize: [32, 32],
      iconAnchor: [16, 32],
    });

  const handleMarkerClick = (event) => {
    const marker = event.target;
    marker.openPopup();
  };
  return (
    <div className="WorldMapChart widget-container height-350px">
      <div className="widget-header">
        <Row>
          <Col md={10} className="widget-select-container dot-con">
            <div className="input-group filter d-flex align-items-center">
              <div className="metric-header">
                {!canSwitch && (
                  <>
                    <span className="title">
                      Top Marketplaces <span className="mx-3">By</span>{" "}
                      {metricTitles[selectedMetric].title}
                    </span>
                    <span className="subtitle">
                      {metricTitles[selectedMetric].subtitle && (
                        <span>[{metricTitles[selectedMetric].subtitle}]</span>
                      )}
                    </span>
                    <InfoButton infoText={metricDescription[selectedMetric]} />
                  </>
                )}
                {canSwitch && (
                  <>
                    <span className="title">
                      Top Marketplaces <span className="mx-3">By</span>
                    </span>
                    {metaMetricList && metaMetricList.length > 0 && (
                      <DynamicWidthDropdown
                        defaultText="Select a metric"
                        options={metaMetricList}
                        selectedItem={selectedMetric}
                        handleSelectChange={handleMetricChange}
                      ></DynamicWidthDropdown>
                    )}
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col md={2} className="p-0">
            <div className="more-action-icon">
              <img src={MoreActionIcon} alt="info circle icon" />
            </div>
          </Col>
        </Row>
      </div>
      {loading ? (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            height: "100%",
            marginTop: "140px",
          }}
          className="horizontalBarChart"
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <br></br>
          <div className="loading ms-4">Loading...</div>
        </div>
      ) : (
        <div className="app">
          <div className="row">
            <div className="mixed-chart">
              <MapContainer className="map-container" center={[0, 0]} zoom={1}>
                {/*zoomControl={false}*/}
                {/*scrollWheelZoom={false}*/}
                {/*<TileLayer*/}
                {/*    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"*/}

                {/*// attribution='&copy; <a href="https://www.openstreetmap.org/copyright"></a> contributors'*/}
                {/*/>*/}
                {/*<TileLayer*/}
                {/*    url={DottedWorldMap}*/}
                {/*    attribution='Your Attribution Here'*/}
                {/*/>*/}
                <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"></TileLayer>
                {locations.map((location, index) => (
                  <Marker
                    key={index}
                    position={location.position}
                    icon={customIcon(
                      formatNumber(location.value),
                      location.CountryCode
                    )}
                    eventHandlers={{ click: handleMarkerClick }}
                  >
                    <Popup>
                      <div className="popup-container">
                        <h3>{location.CountryName}</h3>
                        <p>
                          <strong
                            title={metricTitles[selectedMetric].description}
                          >
                            {" "}
                            {metricTitles[selectedMetric].title}{" "}
                            {metricTitles[selectedMetric].subtitle}:
                          </strong>
                          <span>{location.value}</span>
                        </p>
                      </div>
                    </Popup>
                  </Marker>
                ))}
              </MapContainer>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorldMapChart;
