/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Row, Col } from "react-bootstrap";
import "./index.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import AutomationRulesListTable from "../../components/CusDataTable/automation-rules-list-table";

import LinkIcon from "../../assets/images/icons/link-icon.svg";
import ResetIcon from "../../assets/images/icons/reset-icon.svg";
import Moment from "moment";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import MasterDataService from "../../services/MasterDataService";

class AutomationRules extends Component {
  filterData: any = [];
  state = {
    globalFilterFromData: [],
    searchKeyFilter: "",
    globalFilterDateRange: [],
    filterOptionData: [],
    paginationList: [],
    perPage: 50,
    total: 0,
    currPage: 1,
    lastPage: 0,
    nextPage: 0,
    prevPage: 0,
    dropdownDatas: [],
    getSelectedRules: [],
    pageReload: false,
    getRulesByStatus: "",
    lastUpdatedDate: localStorage.getItem("lastUpdatedDate") || null,
  };
  constructor(props) {
    super(props);
    this.onChangeDateRangeSubmitGlobal =
      this.onChangeDateRangeSubmitGlobal.bind(this);
  }

  componentDidMount() {
    this.getMetaData();
  }

  handleNvEnter = (event) => {
    console.log("Nv Enter:", event);
  };
  handleCallback = (childData) => {
    this.setState({ globalFilterFromData: childData });
    this.filterData = childData;
  };
  handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      this.setState({ searchKeyFilter: event.target.value });
      event.preventDefault();
    }
  };

  onChangeDateRangeSubmitGlobal(e) {
    console.log(Moment(e[0]).format("YYYY-MM-DD"));
    console.log(e[1]);
    let dateRange = e;
    if (dateRange.length > 0) {
      this.setState({ globalFilterDateRange: dateRange });
    }
  }

  applyDataLength = (e) => {
    console.log("dataSize: ", e.target.value);
    this.setState({ perPage: parseInt(e.target.value) });
  };

  handleCallbackTotalData = (childData) => {
    console.log("Child Bookshelf Table data: ", childData);
    this.setState({ total: childData.total });
    this.setState({ currPage: childData.currPage });
    this.setState({ lastPage: childData.lastPage });
    this.setState({ nextPage: childData.nextPage });
    this.setState({ perPage: childData.perPage });
    this.setState({ prevPage: childData.prevPage });
    this.setState({ dropdownDatas: childData.dropdownDatas });
  };

  applyPagination = (e, pageNo: any) => {
    console.log("pagination no: ", pageNo);
    this.setState({ currPage: pageNo });
  };
  handleChange = (event, value: number) => {
    this.setState({ currPage: value });
  };

  cancelDropdown = () => {
    const eleRm: any = document.getElementsByClassName("dropdown-menu");
    if (eleRm) {
      if (eleRm.length > 0) {
        eleRm[0].classList.remove("show");
        eleRm[1].classList.remove("show");
      }
    }
  };

  getMetaData = async () => {
    try {
      const metaData = await MasterDataService.getMetaData();
      this.setState({ metaData: metaData });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  handleSelectedRules = (childData) => {
    console.log("Selected Rules List: ", childData);
    this.setState({ getSelectedRules: childData });
  };
  filterRulesByStatus = (statusType) => {
    if (statusType) {
      this.setState({ getRulesByStatus: statusType });
    }
  };
  updateRulesStatus = (statusType) => {
    if (this.state.getSelectedRules.length > 0) {
      this.setState({ pageReload: false });
      console.log("Enable Rules list: ", this.state.getSelectedRules);
      this.ruleStatusUpdateAPI(statusType);
    }
  };
  ruleStatusUpdateAPI = async (statusType) => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    const response = await fetch("https://api.aimosa.io/Rule/Status", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        ruleId: this.state.getSelectedRules,
        status: statusType,
      }),
    });
    const responceData = await response.json();
    console.log("res=", responceData);
    this.setState({ pageReload: true });
    this.setState({ getSelectedRules: [] });
    toast("Rule(s) " + statusType + " Status updated successfully");
  };

  deleteRule = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    const response = await fetch("https://api.aimosa.io/Rule", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        id: this.state.getSelectedRules,
      }),
    });
    const responceData = await response.json();
    this.setState({ pageReload: true });
    this.setState({ getSelectedRules: [] });
    toast("Rule(s) deleted successfully");
  };
  resetCampaigns = async () => {
    // Disable the button to prevent multiple clicks
    this.setState({ resetButtonDisabled: true });

    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.aimosa.io/Rule/All";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
        body: JSON.stringify({
          pageNumber: 0,
          pageSize: 0,
          globalFilters: {
            searchText: "",
            advancedFilters: [],
          },
        }),
      });

      const data = await response.json();
      console.log("Response data:", data);

      this.setState({
        globalFilterFromData: data.rules,
        searchKeyFilter: "",
        globalFilterDateRange: [],
        pageReload: true,
      });
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    } finally {
      this.setState({ resetButtonDisabled: false });
    }
  };

  render() {
    return (
      <DashboardLayout>
        <div className="main-cont-header bookself-container">
          <Row className="page-header">
            <Col>
              <div className="main-con-page-title-container">
                <div className="title">
                  <h3 className="page-title">My Automation Rules</h3>
                </div>
              </div>
            </Col>
            {/* <Col className="text-end last-sync justify-content-end">
                            <span
                                className="last-sync"
                            >
                                <div>Last App Sync</div>
                                <div className="lastspan">
                                    {" "}
                                    {this.state.lastUpdatedDate || "Not available"}
                                </div>
                            </span>
                        </Col> */}
          </Row>
        </div>
        <div className="main-content-container">
          <hr />
          <div className="padding-lr-30">
            <Row>
              <Col md={7}>
                <div className="table-global-action-container">
                  <div className="custom-dropdown-container">
                    <label>Bulk Operation</label>
                    <div className="dropdownContent width-220">
                      <p
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Bulk Operation
                        <i
                          className="fa fa-angle-down down-arrow-right"
                          aria-hidden="true"
                        ></i>
                        <i
                          className="fa fa-angle-up up-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </p>
                      <ul
                        className="dropdown-menu shadow rounded width-220"
                        aria-labelledby="dropdownMenuButton1"
                        style={{ minWidth: "130px" }}
                      >
                        <li onClick={(e) => this.updateRulesStatus("Enabled")}>
                          Enable
                        </li>
                        <li onClick={(e) => this.updateRulesStatus("Paused")}>
                          Pause
                        </li>
                        <li onClick={(e) => this.deleteRule()}>Delete</li>
                      </ul>
                    </div>
                  </div>
                  <div className="custom-dropdown-container">
                    <label>Show</label>
                    <div className="dropdownContent width-160">
                      <p
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {this.state.getRulesByStatus
                          ? this.state.getRulesByStatus
                          : "All Status"}
                        <i
                          className="fa fa-angle-down down-arrow-right"
                          aria-hidden="true"
                        ></i>
                        <i
                          className="fa fa-angle-up up-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </p>
                      <ul
                        className="dropdown-menu shadow rounded width-160"
                        style={{ minWidth: "100px" }}
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li
                          onClick={(e) => this.filterRulesByStatus("Enabled")}
                        >
                          Enabled
                        </li>
                        <li onClick={(e) => this.filterRulesByStatus("Paused")}>
                          Paused
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="filter-container">
                  <Row>
                    <Col md={12} className="padding-lr-10">
                      <div>
                        <form>
                          <div className="search-filter-container">
                            <i className="fa fa-search"></i>
                            <input
                              type="text"
                              placeholder="Search"
                              id="globalSearch"
                              name="globalSearch"
                              onKeyDown={this.handleKeyDown}
                            />
                          </div>
                        </form>
                      </div>
                      <div className="filter-item export-link-container">
                        <p>
                          <i>
                            <img src={ResetIcon} alt="filter icon" />
                          </i>
                          <span onClick={this.resetCampaigns}>Reset</span>
                        </p>
                      </div>
                      <div>
                        <Link
                          to="/ads/create-automation-rules/0"
                          className="btn btn-primary add-rules-btn mt-1"
                        >
                          Add Rules
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dashboard-container padding-lr-30">
            <Row>
              <Col>
                <AutomationRulesListTable
                  checkBox={false}
                  tabName="Marketplaces"
                  filterData={this.state.globalFilterFromData}
                  searchKey={this.state.searchKeyFilter}
                  filterDateRange={this.state.globalFilterDateRange}
                  perPage={this.state.perPage}
                  currPage={this.state.currPage}
                  parentCallback={this.handleCallbackTotalData}
                  handleSelectedRules={this.handleSelectedRules}
                  fetchRulesByStatus={this.state.getRulesByStatus}
                  pageReload={this.state.pageReload}
                />
              </Col>
            </Row>
          </div>
          <div className="custom-table-footer mx-3">
            <Row>
              <Col md={5}>
                <form className="table-footer-left">
                  <span>Show </span>
                  <label>
                    <select
                      className="form-select"
                      defaultValue={this.state.perPage}
                      onChange={(event) => this.applyDataLength(event)}
                    >
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                    </select>
                  </label>
                  <span> of {this.state.total} total entries</span>
                </form>
              </Col>
              <Col md={7}>
                <div className="table-footer-right">
                  <Stack spacing={2}>
                    <Pagination
                      count={this.state.lastPage}
                      variant="outlined"
                      shape="rounded"
                      onChange={this.handleChange}
                    />
                  </Stack>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

export default AutomationRules;
