import BaseService from './BaseService';

class CampaignManagerService extends BaseService {
    static API_URL = '/Ads/CampaignManager';

    static cacheKeyPrefix_MarketPlaceWiseData = 'cm_marketplacewisedata_';
    static cacheKeyPrefix_PorfolioWiseData = 'cm_portfoliowisedata_';
    static cacheKeyPrefix_CampaignWiseData = 'cm_campaignwisedata_';
    static cacheKeyPrefix_AdGroupWiseData = 'cm_adgroupwisedata_';
    static cacheKeyPrefix_TargetWiseData = 'cm_targetwisedata_';
    static cacheKeyPrefix_ProductWiseData = 'cm_productwisedata_';
    static cacheKeyPrefix_SearchTermWiseData = 'cm_searchtermwisedata_';

    static async getMarketPlaceWiseData<T>(requestData = {}): Promise<any> {
        const mergedRequestData = {
            pageNumber: 1,
            pageSize: 10,
            globalFilters: {
                dateRanges: {
                    dateRange: 'Custom',
                    ...BaseService.calculateDateRange(),
                },
            },
            ...requestData
        };
        return await BaseService.handleRequest<T>(
            CampaignManagerService.cacheKeyPrefix_MarketPlaceWiseData,
            CampaignManagerService.API_URL,
            mergedRequestData,
            'Marketplaces',
            BaseService.post
        );
    }

    static async getPorfolioWiseData<T>(requestData = {}): Promise<any> {
        const mergedRequestData = {
            pageNumber: 1,
            pageSize: 10,
            globalFilters: {
                dateRanges: {
                    dateRange: 'Custom',
                    ...BaseService.calculateDateRange(),
                },
            },
            ...requestData
        };
        return await BaseService.handleRequest<T>(
            CampaignManagerService.cacheKeyPrefix_PorfolioWiseData,
            CampaignManagerService.API_URL,
            mergedRequestData,
            'Portfolios',
            BaseService.post
        );
    }

    static async getCampaignWiseData<T>(requestData = {}): Promise<any> {
        const mergedRequestData = {
            pageNumber: 1,
            pageSize: 10,
            globalFilters: {
                dateRanges: {
                    dateRange: 'Custom',
                    ...BaseService.calculateDateRange(),
                },
            },
            ...requestData
        };
        return await BaseService.handleRequest<T>(
            CampaignManagerService.cacheKeyPrefix_CampaignWiseData,
            CampaignManagerService.API_URL,
            mergedRequestData,
            'Campaigns',
            BaseService.post
        );
    }

    static async getAdGroupWiseData<T>(requestData = {}): Promise<any> {
        const mergedRequestData = {
            pageNumber: 1,
            pageSize: 10,
            globalFilters: {
                dateRanges: {
                    dateRange: 'Custom',
                    ...BaseService.calculateDateRange(),
                },
            },
            ...requestData
        };
        return await BaseService.handleRequest<T>(
            CampaignManagerService.cacheKeyPrefix_AdGroupWiseData,
            CampaignManagerService.API_URL,
            mergedRequestData,
            'AdGroups',
            BaseService.post
        );
    }

    static async getTargetWiseData<T>(requestData = {}): Promise<any> {
        const mergedRequestData = {
            pageNumber: 1,
            pageSize: 10,
            globalFilters: {
                dateRanges: {
                    dateRange: 'Custom',
                    ...BaseService.calculateDateRange(),
                },
            },
            ...requestData
        };
        return await BaseService.handleRequest<T>(
            CampaignManagerService.cacheKeyPrefix_TargetWiseData,
            CampaignManagerService.API_URL,
            mergedRequestData,
            'Targets',
            BaseService.post
        );
    }

    static async getProductWiseData<T>(requestData = {}): Promise<any> {
        const mergedRequestData = {
            pageNumber: 1,
            pageSize: 10,
            globalFilters: {
                dateRanges: {
                    dateRange: 'Custom',
                    ...BaseService.calculateDateRange(),
                },
            },
            ...requestData
        };
        return await BaseService.handleRequest<T>(
            CampaignManagerService.cacheKeyPrefix_ProductWiseData,
            CampaignManagerService.API_URL,
            mergedRequestData,
            'Products',
            BaseService.post
        );
    }

    static async getSearchTermWiseData<T>(requestData = {}): Promise<any> {
        const mergedRequestData = {
            pageNumber: 1,
            pageSize: 10,
            globalFilters: {
                dateRanges: {
                    dateRange: 'Custom',
                    ...BaseService.calculateDateRange(),
                },
            },
            ...requestData
        };
        return await BaseService.handleRequest<T>(
            CampaignManagerService.cacheKeyPrefix_SearchTermWiseData,
            CampaignManagerService.API_URL,
            mergedRequestData,
            'SearchTerms',
            BaseService.post
        );
    }

    static async BulkRequest<T>(requestData = {}): Promise<any> {
        return await BaseService.handleRequestNoCache<T>(
            CampaignManagerService.API_URL,
            requestData,
            'BulkRequest',
            BaseService.post
        );
    }
}

export default CampaignManagerService;


