/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../index.css";
import CreateRuleWizard from "../../../../components/Wizard/createRuleWizard";
import LogoIcon from "../../../../assets/brand/logo-icon.svg";

import { toast } from "react-toastify";

import MasterDataService from "../../../../services/MasterDataService";
import { useNavigate } from "react-router";

function AddNewRules(props) {
  const navigate = useNavigate();
  const [metaData, setMetaData] = useState<any>([]);
  const [rulesMetaData, setRulesMetaData] = useState<any>({});
  const [connectedMarketplace, setConnectedMarketplace] = useState<any>({});
  const [isLoading, setIsLoading] = useState<any>(false);
  const [name, setName] = useState<any>("");
  const [marketPlaces, setMarketPlaces] = useState<any>([]);
  const [applyTo, setApplyTo] = useState<any>([]);
  const [matchTypes, setMatchTypes] = useState<any>([]);
  const [targetCriteriaDetails, setTargetCriteriaDetails] = useState<any>([]);
  const [reportDuration, setReportDuration] = useState<any>("");
  const [actionCriteria, setActionCriteria] = useState<any>([]);
  const [actionUpToCriteria, setActionUpToCriteria] = useState<any>([]);
  const [validationErrors, setValidationErrors] = useState<any>([]);
  const [applyToData, setApplyToData] = useState<any>([]);
  const [schedule, setSchedule] = useState<any>({
    runContinuously: true,
    dateRange: null,
  });
  const [addRulesObj, setAddRulesObj] = useState<any>({});

  const [getRuleStatus, setGetRuleStatus] = useState<any>(false);
  const [showErrorList, setShowErrorList] = useState<any>([]);

  const [wizardData, setWizardData] = useState<any>({
    name: "",
    applyToWizard: {
      marketPlace: [],
      applyTo: [],
      matchType: [],
    },
    ifWizard: [],
    forWizard: "",
    thenWizard: [],
    untilWizard: [],
    timelineWizard: { runContinuously: true, dateRange: null },
  });
  console.log(props);
  useEffect(() => {
    if (props.id !== "0") {
      setGetRuleStatus(true);
      getRule();
    }
    getConnectedMarketplace();
  }, []);

  const wizardCallback = (childData) => {
    console.log("Received childData:", childData);
    if (childData) {
      let newWizardData = wizardData;

      if (childData.type === "applyto") {
        newWizardData["applyToWizard"]["marketPlace"] = childData.marketPlace;
        newWizardData["applyToWizard"]["applyTo"] = childData.applyTo;
        newWizardData["applyToWizard"]["matchType"] = childData.matchType;
      }
      console.log(childData.matchType);
      if (childData.type === "targetCriteria") {
        newWizardData["ifWizard"] = childData.targetCriteria;
      }
      console.log("Match Types:", childData.matchType);
      if (childData.type === "reportDuration") {
        newWizardData["forWizard"] = childData.reportDuration;
      }
      if (childData.type === "actionCriteria") {
        newWizardData["thenWizard"] = childData.actionCriteria;
      }
      if (childData.type === "actionUpToCriteria") {
        newWizardData["untilWizard"] = childData.actionUpToCriteria;
      }
      if (childData.type === "schedule") {
        newWizardData["timelineWizard"] = childData.schedule;
      }
      setWizardData(newWizardData);
      console.log(newWizardData);
    }
  };

  const handleCallback = (childData) => {
    console.log("Received childData:", childData);
    console.log("Previous applyTo:", applyTo);

    if (childData.type === "applyto") {
      setApplyToData(childData.applyTo);
      setMarketPlaces(childData.marketPlace);
      setMatchTypes(childData.matchType);
      setApplyTo(childData.applyTo);
    }
    console.log("New applyTo:", applyTo);
    console.log("Match Types:", childData.matchType);

    // Handle other state updates consistently
    if (childData.type === "targetCriteria") {
      setTargetCriteriaDetails(childData.targetCriteria);
    }
    if (childData.type === "reportDuration") {
      setReportDuration(childData.reportDuration);
    }
    if (childData.type === "actionCriteria") {
      setActionCriteria(childData.actionCriteria);
    }
    if (childData.type === "actionUpToCriteria") {
      setActionUpToCriteria(childData.actionUpToCriteria);
    }
    if (childData.type === "schedule") {
      setSchedule(childData.schedule);
    }
    if (childData.type === "submit") {
      if (props.id !== "0") {
        submitUpdateRule();
      } else {
        submitAddRule();
      }
    }
  };

  const submitAddRule = async () => {
    setIsLoading(true);
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.aimosa.io/Rule";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        name: name,
        marketPlaces: marketPlaces,
        applyTo: applyTo,
        matchTypes: matchTypes,
        targetCriteria: targetCriteriaDetails,
        reportDuration: reportDuration,
        actionCriteria: actionCriteria,
        actionUpToCriteria: actionUpToCriteria,
        schedule: schedule,
      }),
    };
    console.log("marketPlaces:", marketPlaces);
    console.log("applyTo:", applyTo);
    console.log("matchTypes:", matchTypes);

    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      if (responceData.success) {
        let result = responceData.result;
        console.error(result);
        setIsLoading(false);
        toast("Successfully Rule created");
        navigate("/ads/automation-rules");
      } else {
        // Show validation errors in one toast with a gap between them
        if (responceData.validationErrors) {
          const allErrorMessages = Object.entries(responceData.validationErrors)
            .map(
              ([field, errors]) =>
                `${field}: ${(errors as string[]).join(", ")}\n`
            )
            .join(""); // No need to add extra line break here

          toast(allErrorMessages ?? responceData.message, {
            bodyClassName: `text-left`,
          });
        } else if (responceData.message) {
          // If no validation errors, show a generic error message
          toast(responceData.message);
        } else {
          toast("Unable to Create");
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast("Error On Creating Rule");
      setIsLoading(false);
    }
  };

  const getRule = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.aimosa.io/Rule/" + props.id;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    };

    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      if (responceData.success) {
        let result = responceData.result;
        console.error("Get Rules: ", result);
        let objWizard = {
          id: result.id,
          name: result.ruleName,
          applyToWizard: {
            marketPlace: result.marketPlaces,
            applyTo: result.applyTo,
            matchType: result.matchTypes,
          },
          ifWizard: result.targetCriteria,
          forWizard: result.reportDuration,
          thenWizard: result.actionCriteria,
          untilWizard: result.actionUpToCriteria,
          timelineWizard: {
            runContinuously: result.schedule.runContinuously,
            dateRange: result.schedule.dateRange,
          },
        };
        setName(result.ruleName);
        setMarketPlaces(result.marketPlaces);
        setApplyTo(result.applyTo);
        setMatchTypes(result.matchTypes);
        setTargetCriteriaDetails(result.targetCriteria);
        setReportDuration(result.reportDuration);
        setActionCriteria(result.actionCriteria);
        setActionUpToCriteria(result.actionUpToCriteria);
        setSchedule(result.schedule);

        setWizardData(objWizard);
        setGetRuleStatus(false);
      } else {
        setGetRuleStatus(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast("Error On loading Rule");
      setGetRuleStatus(false);
    }
  };
  const submitUpdateRule = async () => {
    setIsLoading(true);
    try {
      const userToken = localStorage.getItem("userToken");
      const authToken = "Bearer " + userToken;
      const url = "https://api.aimosa.io/Rule";

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify({
          id: props.id,
          name: name,
          marketPlaces: marketPlaces,
          applyTo: applyTo,
          matchTypes: matchTypes,
          targetCriteria: targetCriteriaDetails,
          reportDuration: reportDuration,
          actionCriteria: actionCriteria,
          actionUpToCriteria: actionUpToCriteria,
          schedule: schedule,
        }),
      };
      console.log(matchTypes);
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();

      if (response.ok && responseData.success) {
        console.log(responseData.result);
        toast("Successfully Rule updated");
        navigate("/ads/automation-rules");
        setIsLoading(false);
      } else {
        // Handle error
        if (responseData.validationErrors) {
          setValidationErrors(responseData.validationErrors);
        } else if (responseData.message) {
          toast(responseData.message);
        } else {
          toast("Error updating Rule");
        }
        setIsLoading(false);
        console.error("Error in response:", responseData);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
      toast("Error updating Rule");
    }
  };

  let handleChange = (e) => {
    let newFormValues = addRulesObj;
    newFormValues["name"] = e.target.value;
    setName(e.target.value);
    let newWizardDataName = wizardData;
    newWizardDataName["name"] = e.target.value;
    setWizardData(newWizardDataName);
    console.log("Wizard Data: ", newWizardDataName);
  };

  useEffect(() => {
    if (rulesMetaData) {
      if (!rulesMetaData.applyToDetails) {
        getRulesMetaData();
      }
    }
    if (metaData) {
      if (metaData.length < 1) {
        getMetaData();
      }
    }
  }, [metaData, rulesMetaData]);

  const getMetaData = async () => {
    try {
      // const metaData = await MasterDataService.getMetaData();
      let userToken = localStorage.getItem("userToken");
      let AuthToken = "Bearer " + userToken;
      let url = "https://api.aimosa.io/MasterData/meta";
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
      });
      try {
        const responceData = await response.json();
        setMetaData(responceData.result.data);
        const matchTypesData = responceData.result.data.matchTypes;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getConnectedMarketplace = async () => {
    try {
      let userToken = localStorage.getItem("userToken");
      let AuthToken = "Bearer " + userToken;
      let url = "https://api.aimosa.io/AmazonConnection";
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        console.error(errorResponse);
        return;
      }

      const responceData = await response.json();
      console.log("Response Data:", responceData);

      let data = responceData.result;
      let trueValues = data.filter((x) => x.dataConnections === true);
      let connectedMarketplaces = trueValues.map((item) => item.marketplace);
      console.log("dp", connectedMarketplaces);
      setConnectedMarketplace(connectedMarketplaces);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getRulesMetaData = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.aimosa.io/MasterData/Rules_Creation";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    });

    try {
      const responceData = await response.json();
      setRulesMetaData(responceData.result.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const closeAlert = () => {
    setShowErrorList([]);
  };

  return (
    <>
      <div className="add-rule-container add-new-rule">
        <Row>
          <Col>
            <h4>Add New Rule</h4>
          </Col>
        </Row>
        {/* {showErrorList.length > 0 ||
        Object.keys(validationErrors).length > 0 ? (
          <Row>
            <Col>
              <div
                className="alert-container alert alert-danger d-flex align-items-center fade show"
                role="alert"
              >
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => closeAlert()}
                ></button>
                <div>
                  <ul>
                    {[...showErrorList, ...Object.values(validationErrors)].map(
                      (error, i) => (
                        <li key={i}>{error}</li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <></>
        )} */}
        {!getRuleStatus ? (
          <Row className="mt-3">
            <h5>Rule Name</h5>
            <Col md={12}>
              <Row>
                <Col md={3}>
                  <div className="mb-3">
                    <form>
                      <div className="new-rule-name">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="enter rule name or rule set"
                          id="globalSearch"
                          name="globalSearch"
                          onChange={(e) => handleChange(e)}
                          defaultValue={name}
                        />
                      </div>
                    </form>
                  </div>
                </Col>
              </Row>
            </Col>
            <Row>
              <Col className="steperDesign">
                {rulesMetaData && (
                  <CreateRuleWizard
                    isLoading={isLoading}
                    rulesMetaData={rulesMetaData}
                    metaData={metaData}
                    parentCallback={handleCallback}
                    wizardData={wizardData}
                    wizardCallback={wizardCallback}
                    marketplace={connectedMarketplace}
                  />
                )}
              </Col>
            </Row>
          </Row>
        ) : (
          <div className="loading-container">
            <div className="loading-text">
              <span className="logicon">
                <img src={LogoIcon} alt="logo-small"></img>
              </span>
              <span>L</span>
              <span>O</span>
              <span>A</span>
              <span>D</span>
              <span>I</span>
              <span>N</span>
              <span>G</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AddNewRules;
